import React from 'react';

/** This hook uses event capturing to make sure that
 the listener will receive the click first, before any others.
 */
export function useClickOutside(callback: (event: MouseEvent) => void) {
  const elementRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        elementRef.current &&
        !elementRef.current.contains(event.target as Node) &&
        // Mousedown and mouseup on different elements, FF interprets as a click on a body. Chrome does not trigger click.
        event.target !== document.body
      ) {
        callback(event);
      }
    }

    document.addEventListener('click', handleClickOutside, { capture: true });

    return () => {
      document.removeEventListener('click', handleClickOutside, { capture: true });
    };
  }, [callback, elementRef]);

  return elementRef;
}

import React from 'react';
import ReactDom from 'react-dom';
import { isNumber } from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { ResizeAction } from 'neptune-core-ui/components/table/actions/ResizeAction';

import { ResizeEvent } from '@neptune/shared/leaderboard-domain';
import { bemBlock, Card, CardProps, Layout } from '@neptune/shared/venus-ui';

import './ResizableCard.less';

const block = bemBlock('resizable-card');

type ResizeParams = {
  width?: number;
  height?: number;
};

type ResizableCardProps = {
  minHeight?: number;
  minWidth?: number;
  height?: number | string;
  width?: number | string;
  onResizeStart?: () => void;
  onResize?: (params: ResizeParams) => void;
  onResizeEnd?: (params: ResizeParams) => void;
  disableHorizontalResizing?: boolean;
} & CardProps;

export const ResizableCard: React.FC<ResizableCardProps> = ({
  className,
  children,
  minHeight = 0,
  minWidth = 0,
  height,
  width,
  onResizeStart,
  onResize,
  onResizeEnd,
  disableHorizontalResizing = false,
  ...rest
}) => {
  const [resizing, setResizing] = React.useState<'horizontal' | 'vertical' | 'both' | undefined>();
  const containerRef = React.useRef<HTMLDivElement | undefined>(undefined);

  const handleResize = React.useCallback(
    ({
      diffX,
      diffY,
      direction = 'both',
    }: {
      diffX: number;
      diffY: number;
      direction?: 'horizontal' | 'vertical' | 'both';
    }) => {
      let newHeight;
      let newWidth;

      if (isNumber(height) && containerRef.current) {
        newHeight = Math.max(height + diffY, minHeight);
        containerRef.current.style.height = `${newHeight}px`;
      }

      if (isNumber(width) && containerRef.current) {
        newWidth = Math.max(width + diffX, minWidth);
        containerRef.current.style.width = `${newWidth}px`;
      }

      setResizing(direction);
      onResize?.({ height: newHeight, width: newWidth });
    },
    [height, width, setResizing, minHeight, minWidth, onResize],
  );

  const handleResizeEnd = React.useCallback(
    ({ diffX, diffY }: { diffX: number; diffY: number }) => {
      let newHeight;
      let newWidth;

      if (isNumber(height)) {
        newHeight = Math.max(height + diffY, minHeight);
      }

      if (isNumber(width)) {
        newWidth = Math.max(width + diffX, minWidth);
      }

      setResizing(undefined);
      onResizeEnd?.({ height: newHeight, width: newWidth });
    },
    [setResizing, onResizeEnd, minHeight, height, minWidth, width],
  );

  const handleResizeHeight = React.useCallback(
    (e: ResizeEvent) => {
      handleResize({ diffX: 0, diffY: e.diffY, direction: 'horizontal' });
    },
    [handleResize],
  );

  const handleResizeWidth = React.useCallback(
    (e: ResizeEvent) => {
      handleResize({ diffX: e.diffX, diffY: 0, direction: 'vertical' });
    },
    [handleResize],
  );

  const handleResizeEndHeight = React.useCallback(
    (e: ResizeEvent) => {
      handleResizeEnd({ diffX: 0, diffY: e.diffY });
    },
    [handleResizeEnd],
  );

  const handleResizeEndWidth = React.useCallback(
    (e: ResizeEvent) => {
      handleResizeEnd({ diffX: e.diffX, diffY: 0 });
    },
    [handleResizeEnd],
  );

  return (
    <Card
      className={block({ extra: className })}
      height={height}
      width={width}
      overflow="hidden"
      withBoxShadow
      elementRef={containerRef}
      {...rest}
    >
      <Layout.Row span="shrink">
        <Layout.Column height={height} width={width}>
          {children}
        </Layout.Column>
        {!disableHorizontalResizing && isNumber(width) && (
          <ResizeAction
            data-role="resize-action-horizontal"
            orientation="horizontal"
            onResizeEnd={handleResizeEndWidth}
            onResize={handleResizeWidth}
            onResizeStart={onResizeStart}
            column={undefined}
            highlight={false}
          />
        )}
      </Layout.Row>
      <Layout.Row span="auto">
        {isNumber(height) && (
          <ResizeAction
            data-role="resize-action-vertical"
            orientation="vertical"
            onResizeEnd={handleResizeEndHeight}
            onResize={handleResizeHeight}
            onResizeStart={onResizeStart}
            column={undefined}
          />
        )}
        {!disableHorizontalResizing && isNumber(height) && isNumber(width) && (
          <ResizeAction
            data-role="resize-action-both"
            orientation="both"
            onResizeEnd={handleResizeEnd}
            onResize={handleResize}
            onResizeStart={onResizeStart}
            column={undefined}
          />
        )}
      </Layout.Row>
      {resizing &&
        ReactDom.createPortal(
          <div className={block({ element: 'dim', modifiers: { resizing } })} />,
          document.body,
        )}
    </Card>
  );
};

export const startOfMatchMark = '\u0001';
export const endOfMatchMark = '\u0002';

export const startOrEndMarkRe = new RegExp(`[${startOfMatchMark}${endOfMatchMark}]`, 'g');

export const markGroupsRe = new RegExp(
  `([^${startOfMatchMark}]*)(?:${startOfMatchMark}([^${endOfMatchMark}]*)${endOfMatchMark})?`,
  'g',
);
export const collapseSiblingRe = new RegExp(`${endOfMatchMark}${startOfMatchMark}`, 'g');

import { toString } from 'lodash';

import { getShortenedMetricsEnabled } from 'common/featureFlag';

import { endOfMatchMark, startOfMatchMark } from './start-end-match-markers';

const TOKEN_REGEX = /[^.=:\\\/-]+|./u;
const ELLIPSIS = '…';

export function tokenize(s: string): string[] {
  return [...s.matchAll(RegExp(TOKEN_REGEX, 'gu'))].map(([match]) => match);
}

/**
 * LCS of two lists of strings. Returns one possible LCS.
 */
export function lcs<T>(seq1: T[], seq2: T[]): T[] {
  const n1 = seq1.length;
  const n2 = seq2.length;
  const dp: number[][] = Array(n1 + 1)
    .fill(0)
    .map(() => Array(n2 + 1).fill(0));

  for (let i = 1; i <= n1; i++) {
    for (let j = 1; j <= n2; j++) {
      if (seq1[i - 1] === seq2[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1] + 1;
      } else {
        dp[i][j] = Math.max(dp[i - 1][j], dp[i][j - 1]);
      }
    }
  }

  // Backtrack
  const res: T[] = [];
  let i = n1;
  let j = n2;

  while (i > 0 && j > 0) {
    if (seq1[i - 1] === seq2[j - 1]) {
      res.push(seq1[i - 1]);
      i--;
      j--;
    } else {
      if (dp[i - 1][j] > dp[i][j - 1]) {
        i--;
      } else {
        j--;
      }
    }
  }

  return res.reverse();
}

/**
 * Iterative pairwise LCS across all sequences.
 */
export function multiLcs<T>(listOfSequences: T[][]): T[] {
  if (listOfSequences.length === 0) {
    return [];
  }

  let cur = listOfSequences[0];

  for (let i = 1; i < listOfSequences.length; i++) {
    cur = lcs(cur, listOfSequences[i]);

    if (cur.length === 0) {
      break;
    }
  }

  return cur;
}

function findCommonIndices(
  tokens: string[][],
  commonSubseq: string[],
): {
  commonIndices: number[][];
  gapHasDiff: boolean[];
} {
  const commonIndices: number[][] = tokens.map(() => new Array(commonSubseq.length).fill(0));
  const gapHasDiff: boolean[] = new Array(commonSubseq.length + 1).fill(false);

  tokens.forEach((singleTokens, tokensIdx) => {
    const indices = commonIndices[tokensIdx];
    let prevCommonIdx = -1;

    for (let i = 0; i < commonSubseq.length; ++i) {
      const commonIdx = singleTokens.indexOf(commonSubseq[i], prevCommonIdx + 1);
      indices[i] = commonIdx;
      gapHasDiff[i] ||= commonIdx !== prevCommonIdx + 1;

      prevCommonIdx = commonIdx;
    }

    gapHasDiff[commonSubseq.length] ||= singleTokens.length !== prevCommonIdx + 1;
  });

  return {
    commonIndices,
    gapHasDiff,
  };
}

export function highlightDifferences(
  tokens: string[],
  commonIndices: number[],
  gapHasDiff: boolean[],
  contextDisplay: 'full context' | 'shortened',
): string {
  const resultArr: string[] = [];
  let commonStreakEnd = 0;
  let commonStreakStart = 0;

  commonIndices.forEach((commonIdx, i) => {
    if (gapHasDiff[i]) {
      appendContext(
        resultArr,
        tokens,
        commonStreakStart,
        commonStreakEnd,
        { diffBefore: commonStreakStart !== 0, diffAfter: true },
        contextDisplay,
      );
      appendDiff(resultArr, tokens, commonStreakEnd, commonIdx);

      commonStreakStart = commonIdx;
    }

    commonStreakEnd = commonIdx + 1;
  });

  const diffAtTheEnd = gapHasDiff[commonIndices.length];

  appendContext(
    resultArr,
    tokens,
    commonStreakStart,
    commonStreakEnd,
    { diffBefore: commonStreakStart !== 0, diffAfter: diffAtTheEnd },
    contextDisplay,
  );

  if (diffAtTheEnd) {
    appendDiff(resultArr, tokens, commonStreakEnd, tokens.length);
  }

  return resultArr.join('');
}

function appendDiff(output: string[], tokens: string[], start: number, end: number) {
  output.push(startOfMatchMark);
  output.push(...tokens.slice(start, end));
  output.push(endOfMatchMark);
}

const CONTEXT_TOKENS_AFTER_DIFF = 0;
const CONTEXT_TOKENS_BEFORE_DIFF = 2;

function appendContext(
  output: string[],
  tokens: string[],
  start: number,
  end: number,
  { diffBefore, diffAfter }: { diffBefore: boolean; diffAfter: boolean },
  contextDisplay: 'full context' | 'shortened',
) {
  if (contextDisplay === 'full context') {
    output.push(...tokens.slice(start, end));
    return;
  }

  const contextTokensCount = end - start;
  const startTokensToShow = diffBefore
    ? Math.min(contextTokensCount, CONTEXT_TOKENS_AFTER_DIFF)
    : 0;
  const endTokensToShow = diffAfter ? Math.min(contextTokensCount, CONTEXT_TOKENS_BEFORE_DIFF) : 0;

  if (
    startTokensToShow + endTokensToShow >= contextTokensCount ||
    (startTokensToShow + endTokensToShow + 1 === contextTokensCount &&
      tokens[start + startTokensToShow].length === 1)
  ) {
    output.push(...tokens.slice(start, end));
  } else {
    output.push(...tokens.slice(start, start + startTokensToShow));
    output.push(ELLIPSIS);
    output.push(...tokens.slice(end - endTokensToShow, end));
  }
}

function preprocessWithLcs(inputStrings: string[]): {
  tokens: string[][];
  commonTokens: string[];
  commonIndices: number[][];
  gapHasDiff: boolean[];
} {
  const tokens = inputStrings.map((m) => tokenize(m));

  const commonTokens = multiLcs(tokens);

  const { commonIndices, gapHasDiff } = findCommonIndices(tokens, commonTokens);

  return { tokens, commonTokens, commonIndices, gapHasDiff };
}

function allStringEqual(strings: string[]) {
  return strings.every((s) => s === strings[0]);
}

export const DIFF_MARKER = Symbol();
export type DIFF_MARKER = typeof DIFF_MARKER;

/**
 * Compiles the extra outputs from highlightDiffs to get a single array representing the common parts
 * with diffs marked with a special value.
 */
export function getCommonWithDiffMarkers(
  common: string[],
  gapHasDiff: boolean[],
): Array<string | DIFF_MARKER> {
  let commonStreakStart = 0;
  const result: Array<string | DIFF_MARKER> = [];

  for (let i = 0; i <= common.length; ++i) {
    if (gapHasDiff[i]) {
      if (i !== 0) {
        result.push(common.slice(commonStreakStart, i).join(''));
      }

      result.push(DIFF_MARKER);

      commonStreakStart = i;
    }
  }

  if (commonStreakStart < common.length) {
    result.push(common.slice(commonStreakStart, common.length).join(''));
  }

  return result;
}

export function findCommonPartsAndDiffs(
  inputs: (string | null | undefined)[],
): Array<string | DIFF_MARKER> {
  if (!inputs.length) {
    return [];
  }

  const inputStrings = inputs.map((input) => input ?? '');

  if (allStringEqual(inputStrings)) {
    return [inputStrings[0]];
  }

  const { commonTokens, gapHasDiff } = preprocessWithLcs(inputStrings);

  return getCommonWithDiffMarkers(commonTokens, gapHasDiff);
}

/**
 * Main processing function for a list of metrics.
 * Returns various representations of the metrics with differences highlighted.
 */
export function highlightDiffs(inputs: (string | null | undefined)[]): {
  shortened: string[];
  full: string[];
  commonTokens: string[];
  gapHasDiff: boolean[];
} {
  if (!getShortenedMetricsEnabled()) {
    return {
      full: inputs.map(toString),
      shortened: inputs.map(toString),
      commonTokens: [],
      gapHasDiff: [],
    };
  }

  const inputStrings = inputs.map((input) => input ?? '');

  if (allStringEqual(inputStrings)) {
    return {
      shortened: inputStrings,
      full: inputStrings,
      commonTokens: [inputStrings[0]],
      gapHasDiff: [false],
    };
  }

  const { commonIndices, commonTokens, gapHasDiff, tokens } = preprocessWithLcs(inputStrings);

  return {
    shortened: tokens.map((tokens, i) =>
      highlightDifferences(tokens, commonIndices[i], gapHasDiff, 'shortened'),
    ),
    full: tokens.map((tokens, i) =>
      highlightDifferences(tokens, commonIndices[i], gapHasDiff, 'full context'),
    ),
    commonTokens,
    gapHasDiff,
  };
}

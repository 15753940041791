// Libs
import React from 'react';
import { Helmet } from 'react-helmet';

import { Link, Text } from '@neptune/shared/venus-ui';

// App
import ContactSupport from 'components/contact-support/ContactSupport';

// Module
import { ErrorPage } from './ErrorPage';

type HttpErrorPageProps = {
  isNotFoundOrGoneError: boolean;
};

export const HttpErrorPage: React.VFC<HttpErrorPageProps> = ({ isNotFoundOrGoneError }) => {
  if (isNotFoundOrGoneError) {
    return (
      <ErrorPage data-role="error-404">
        <Helmet>
          <title>Page not found</title>
        </Helmet>
        <ErrorPage.Title>404 - Page not found</ErrorPage.Title>
        <ErrorPage.Message>It was either removed or there is a typo in the URL.</ErrorPage.Message>
        <ErrorPage.Footer>
          Go back to the <Link href="/">dashboard</Link>.
        </ErrorPage.Footer>
      </ErrorPage>
    );
  }

  return (
    <ErrorPage>
      <ErrorPage.Title>Something went wrong :(</ErrorPage.Title>
      <ErrorPage.Message>
        We're fixing the problem so please
        <Text fontWeight="bold"> try again in a few minutes</Text>.
      </ErrorPage.Message>
      <ErrorPage.Footer>
        If the problem still occurs, <ContactSupport>contact us</ContactSupport>.
      </ErrorPage.Footer>
    </ErrorPage>
  );
};

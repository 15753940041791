import React from 'react';

import { LayoutFill } from '../layout-fill/LayoutFill';
import { LayoutSeparator } from '../layout-separator/LayoutSeparator';

import { Toolbar as LegacyToolbar } from './legacy/Toolbar';
import { ToolbarItem as LegacyToolbarItem } from './legacy/ToolbarItem';
import { Toolbar as DefaultToolbar } from './new/Toolbar';
import { ToolbarButton } from './new/ToolbarButton';

type LegacyToolbarPropsWithVariant = {
  variant: 'legacy';
} & React.ComponentProps<typeof LegacyToolbar>;

type DefaultToolbarPropsWithVariant = {
  variant: 'default';
} & React.ComponentProps<typeof DefaultToolbar>;

export type ToolbarProps = LegacyToolbarPropsWithVariant | DefaultToolbarPropsWithVariant;

const BaseToolbar = (props: ToolbarProps) => {
  if (props.variant === 'legacy') {
    return <LegacyToolbar {...props} />;
  }

  return <DefaultToolbar {...props} />;
};

export const Toolbar = Object.assign(BaseToolbar, {
  Button: ToolbarButton,
  /** @deprecated: this is only for variant="legacy" */
  Item: LegacyToolbarItem,
  Fill: LayoutFill,
  Separator: LayoutSeparator,
});

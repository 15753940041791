import React from 'react';

type SeriesLineStyleIndicator = React.ComponentPropsWithoutRef<'canvas'> & {
  color: string;
  width?: number;
  height?: number;
  lineWidth?: number;
  strokePattern?: number[];
  isMuted?: boolean;
  orientation?: Orientation;
};

const defaultStrokePattern = [1, 0];

type Orientation = 'horizontal' | 'vertical';

const drawVerticalLine = (
  ctx: CanvasRenderingContext2D,
  { width, height }: { width: number; height: number },
) => {
  ctx.beginPath();
  ctx.moveTo(width / 2, 0);
  ctx.lineTo(width / 2, height);
  ctx.stroke();
};

const drawHorizontalLine = (
  ctx: CanvasRenderingContext2D,
  { width, height }: { width: number; height: number },
) => {
  ctx.beginPath();
  ctx.moveTo(0, height / 2);
  ctx.lineTo(width, height / 2);
  ctx.stroke();
};

export const SeriesLineStyleIndicator = ({
  color,
  lineWidth = 4,
  strokePattern = defaultStrokePattern,
  width = 8,
  height = 15,
  isMuted,
  orientation = 'vertical',
  ...canvasProps
}: SeriesLineStyleIndicator) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas === null) {
      return;
    }

    const ctx = canvas.getContext('2d');

    if (ctx === null) {
      return;
    }

    const pixelRatio = window.devicePixelRatio || 1;

    canvas.width = width * pixelRatio;
    canvas.height = height * pixelRatio;

    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;

    ctx.scale(pixelRatio, pixelRatio);
  }, [width, height]);

  React.useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas === null) {
      return;
    }

    const ctx = canvas.getContext('2d');

    if (ctx === null) {
      return;
    }

    ctx.clearRect(0, 0, width, height);
    ctx.strokeStyle = color;
    ctx.globalAlpha = isMuted ? 0.2 : 1;
    ctx.lineWidth = lineWidth;
    ctx.setLineDash(strokePattern);

    if (orientation === 'horizontal') {
      drawHorizontalLine(ctx, { width, height });
    } else {
      drawVerticalLine(ctx, { width, height });
    }

    ctx.closePath();
  }, [color, height, lineWidth, strokePattern, width, isMuted, orientation]);

  return (
    <canvas
      data-role="stroke-line-style-indicator"
      data-testid="stroke-line-style-indicator"
      data-strokepattern={strokePattern}
      data-color={color}
      ref={canvasRef}
      {...canvasProps}
    />
  );
};

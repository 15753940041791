import React from 'react';

export type AliasesContextValue = {
  // Whether the alias can be edited
  isEditable?: boolean;
  isSaving?: boolean;
  getAlias: (params: {
    // The run group IDs are used to determine if the alias should be fetched from the experiment or run alias map
    // based on the evaluation of experimentsOnly flag. In charts, a run might be associated with multiple run groups
    runGroupIds: string[];
    projectId: string;
    experimentName: string;
    runId: string;
  }) => string;
  onAliasChange?: (params: {
    // The run group IDs are used to determine if the alias should be saved to the experiment or run alias map
    // based on the evaluation of experimentsOnly flag. In charts, a run might be associated with multiple run groups
    runGroupIds: string[];
    projectId: string;
    experimentName: string;
    runId: string;
    alias: string;
  }) => void;
};

export const AliasesContext = React.createContext<AliasesContextValue>({
  getAlias: () => '',
});

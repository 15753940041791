import React from 'react';
import { noop } from 'lodash';
import { Observable, of } from 'rxjs';

type UnderEditContext = {
  underEdit: boolean;
  underEdit$: Observable<boolean>;
  startEdit: () => void;
  stopEdit: () => void;
  startEditWindow: (windowCloseDelay: number) => void;
  stopEditWindow: () => void;
};

export const UnderEditContext = React.createContext<UnderEditContext>({
  underEdit: false,
  underEdit$: of(false),
  startEdit: noop,
  stopEdit: noop,
  startEditWindow: noop,
  stopEditWindow: noop,
});

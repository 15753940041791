/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NameAliasDTO } from './NameAliasDTO';
import {
    NameAliasDTOFromJSON,
    NameAliasDTOFromJSONTyped,
    NameAliasDTOToJSON,
} from './NameAliasDTO';

/**
 * 
 * @export
 * @interface ProjectAliasGroupDTO
 */
export interface ProjectAliasGroupDTO {
    /**
     * List of aliases in this project
     * @type {Array<NameAliasDTO>}
     * @memberof ProjectAliasGroupDTO
     */
    aliases: Array<NameAliasDTO>;
    /**
     * Project ID
     * @type {string}
     * @memberof ProjectAliasGroupDTO
     */
    projectId: string;
}

/**
 * Check if a given object implements the ProjectAliasGroupDTO interface.
 */
export function instanceOfProjectAliasGroupDTO(value: object): value is ProjectAliasGroupDTO {
    if (!('aliases' in value) || value['aliases'] === undefined) return false;
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    return true;
}

export function ProjectAliasGroupDTOFromJSON(json: any): ProjectAliasGroupDTO {
    return ProjectAliasGroupDTOFromJSONTyped(json, false);
}

export function ProjectAliasGroupDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectAliasGroupDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'aliases': ((json['aliases'] as Array<any>).map(NameAliasDTOFromJSON)),
        'projectId': json['projectId'],
    };
}

export function ProjectAliasGroupDTOToJSON(value?: ProjectAliasGroupDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'aliases': ((value['aliases'] as Array<any>).map(NameAliasDTOToJSON)),
        'projectId': value['projectId'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FloatSeriesAttributeConfigDTO } from './FloatSeriesAttributeConfigDTO';
import {
    FloatSeriesAttributeConfigDTOFromJSON,
    FloatSeriesAttributeConfigDTOFromJSONTyped,
    FloatSeriesAttributeConfigDTOToJSON,
} from './FloatSeriesAttributeConfigDTO';
import type { AttributeTypeDTO } from './AttributeTypeDTO';
import {
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './AttributeTypeDTO';

/**
 * 
 * @export
 * @interface FloatSeriesAttributeDTO
 */
export interface FloatSeriesAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof FloatSeriesAttributeDTO
     */
    attributeName: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof FloatSeriesAttributeDTO
     */
    attributeType: AttributeTypeDTO;
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesAttributeDTO
     */
    average?: number;
    /**
     * 
     * @type {FloatSeriesAttributeConfigDTO}
     * @memberof FloatSeriesAttributeDTO
     */
    config: FloatSeriesAttributeConfigDTO;
    /**
     * 
     * @type {boolean}
     * @memberof FloatSeriesAttributeDTO
     */
    hasPreview: boolean;
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesAttributeDTO
     */
    last?: number;
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesAttributeDTO
     */
    lastStep?: number;
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesAttributeDTO
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesAttributeDTO
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesAttributeDTO
     */
    variance?: number;
}



/**
 * Check if a given object implements the FloatSeriesAttributeDTO interface.
 */
export function instanceOfFloatSeriesAttributeDTO(value: object): value is FloatSeriesAttributeDTO {
    if (!('attributeName' in value) || value['attributeName'] === undefined) return false;
    if (!('attributeType' in value) || value['attributeType'] === undefined) return false;
    if (!('config' in value) || value['config'] === undefined) return false;
    if (!('hasPreview' in value) || value['hasPreview'] === undefined) return false;
    return true;
}

export function FloatSeriesAttributeDTOFromJSON(json: any): FloatSeriesAttributeDTO {
    return FloatSeriesAttributeDTOFromJSONTyped(json, false);
}

export function FloatSeriesAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloatSeriesAttributeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'attributeType': AttributeTypeDTOFromJSON(json['attributeType']),
        'average': json['average'] == null ? undefined : json['average'],
        'config': FloatSeriesAttributeConfigDTOFromJSON(json['config']),
        'hasPreview': json['hasPreview'],
        'last': json['last'] == null ? undefined : json['last'],
        'lastStep': json['lastStep'] == null ? undefined : json['lastStep'],
        'max': json['max'] == null ? undefined : json['max'],
        'min': json['min'] == null ? undefined : json['min'],
        'variance': json['variance'] == null ? undefined : json['variance'],
    };
}

export function FloatSeriesAttributeDTOToJSON(value?: FloatSeriesAttributeDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributeName': value['attributeName'],
        'attributeType': AttributeTypeDTOToJSON(value['attributeType']),
        'average': value['average'],
        'config': FloatSeriesAttributeConfigDTOToJSON(value['config']),
        'hasPreview': value['hasPreview'],
        'last': value['last'],
        'lastStep': value['lastStep'],
        'max': value['max'],
        'min': value['min'],
        'variance': value['variance'],
    };
}


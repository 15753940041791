import React from 'react';

import {
  ActionsMenu,
  bemBlock,
  ButtonWithForwardedRef,
  Icon,
  Layout,
  Text,
} from '@neptune/shared/venus-ui';

import './PaginationLimitSelector.less';

type PaginationLimitSelectorTriggerButtonProps = Pick<
  React.ComponentProps<typeof ButtonWithForwardedRef>,
  'size' | 'variant'
> & {
  limit: number;
  offset: number;
  total: number;
};

type PaginationLimitSelectorProps = PaginationLimitSelectorTriggerButtonProps & {
  onChange: (pageSize: number) => void;
  options: number[];
  selected: number;
};

const block = bemBlock('pagination-limit-selector');

export function PaginationLimitSelector({
  options,
  selected,
  onChange,
  total,
  ...props
}: PaginationLimitSelectorProps) {
  return (
    <ActionsMenu>
      <ActionsMenu.Trigger asChild>
        <PaginationLimitSelectorTriggerButton total={total} {...props} />
      </ActionsMenu.Trigger>
      <ActionsMenu.Portal>
        <ActionsMenu.Content className={block()} data-role="pagination-limit-menu">
          {options.map((option) => (
            <ActionsMenu.Item
              key={option}
              onClick={() => onChange(option)}
              trailingElement={option === selected ? <Icon glyph="check" /> : null}
            >
              {option}
            </ActionsMenu.Item>
          ))}
        </ActionsMenu.Content>
      </ActionsMenu.Portal>
    </ActionsMenu>
  );
}

const PaginationLimitSelectorTriggerButton = React.forwardRef<
  HTMLButtonElement,
  PaginationLimitSelectorTriggerButtonProps & Record<string, unknown>
>(({ limit, total, offset, ...props }, ref) => {
  return (
    <ButtonWithForwardedRef
      data-role="pagination-limit-toggle"
      className={block('trigger')}
      {...props}
      ref={ref}
    >
      <Layout.Row alignItems="center">
        <Layout.Row
          alignItems="center"
          spacedChildren="xs"
          withPadding={['none', 'sm', 'none', 'none']}
        >
          <Text color="text-secondary" size="xs">
            {offset + 1} - {offset + limit} of {total}
          </Text>
        </Layout.Row>
        <Layout.Row>
          <Icon glyph={props['aria-expanded'] ? 'chevron-up' : 'chevron-down'} />
        </Layout.Row>
      </Layout.Row>
    </ButtonWithForwardedRef>
  );
});

import React, { useEffect } from 'react';
import { fromEvent, Observable } from 'rxjs';
import { map, sample, switchMap, takeUntil, tap } from 'rxjs/operators';

import { bemBlock } from '@neptune/shared/venus-ui';

import './ResizeAction.less';

const block = bemBlock('n-action-resize');

export type ResizeEvent = {
  diffX: number;
  diffY: number;
  mouseX: number;
  mouseY: number;
};

type ResizeActionProps<T> = {
  column: T;
  handlerSize?: string;
  onDoubleClick?: (column: T) => void;
  onResizeStart?: () => void;
  onResize: (event: ResizeEvent, column: T) => void;
  onResizeEnd: (event: ResizeEvent, column: T) => void;
  orientation?: 'horizontal' | 'vertical' | 'both';
  highlight?: boolean;
  'data-role'?: string;
};

function ResizeActionImpl<T>({
  column,
  onResize,
  handlerSize,
  onResizeStart,
  onResizeEnd,
  onDoubleClick,
  orientation = 'horizontal',
  highlight = true,
  'data-role': dataRole = 'resize-action',
}: ResizeActionProps<T>) {
  const [isResizing, setIsResizing] = React.useState(false);

  const handlerRef = React.useRef<HTMLDivElement | null>(null);
  const mouseMoveRef$ = React.useRef<Observable<MouseEvent> | null>(null);
  const mouseUpRef$ = React.useRef<Observable<MouseEvent> | null>(null);

  if (!mouseUpRef$.current) {
    mouseUpRef$.current = fromEvent<MouseEvent>(document, 'mouseup');
  }

  if (!mouseMoveRef$.current) {
    mouseMoveRef$.current = fromEvent<MouseEvent>(document, 'mousemove');
  }

  useEffect(() => {
    if (!handlerRef.current || !mouseMoveRef$.current || !mouseUpRef$.current) {
      return;
    }

    const mouseDown$ = fromEvent<MouseEvent>(handlerRef.current, 'mousedown');

    const drag$ = mouseDown$.pipe(
      tap((startEvent) => startEvent.preventDefault()),
      switchMap((startEvent) => {
        if (!mouseMoveRef$.current || !mouseUpRef$.current) {
          return new Observable<ResizeEvent>();
        }

        setIsResizing(true);
        onResizeStart?.();

        const startX = startEvent.clientX;
        const startY = startEvent.clientY;

        return mouseMoveRef$.current.pipe(
          tap((startEvent) => startEvent.preventDefault()),
          map((moveEvent) => ({
            diffX: moveEvent.clientX - startX,
            diffY: moveEvent.clientY - startY,
            mouseX: moveEvent.clientX,
            mouseY: moveEvent.clientY,
          })),
          takeUntil(mouseUpRef$.current),
        );
      }),
    );

    const dragEnd$ = drag$.pipe(sample(mouseUpRef$.current));

    const dragSubscription = drag$.subscribe((event) => {
      onResize(event, column);
    });

    const dragEndSubscription = dragEnd$.subscribe((event) => {
      onResizeEnd(event, column);
    });

    const mouseUpSubscription = mouseUpRef$.current.subscribe(() => {
      setIsResizing(false);
    });

    return () => {
      dragSubscription.unsubscribe();
      dragEndSubscription.unsubscribe();
      mouseUpSubscription.unsubscribe();
    };
  }, [column, onResizeStart, onResize, onResizeEnd]);

  const doubleClickHandler = React.useCallback(() => {
    onDoubleClick?.(column);
  }, [column, onDoubleClick]);

  const cssClasses = block({
    extra: 'n-table-cell-action',
    modifiers: {
      'is-resizing': isResizing,
      handler: handlerSize || 'sm',
      orientation,
      highlight,
    },
  });

  return (
    <div
      ref={handlerRef}
      className={cssClasses}
      data-role={dataRole}
      onDoubleClick={doubleClickHandler}
    ></div>
  );
}

export const ResizeAction = React.memo(ResizeActionImpl) as typeof ResizeActionImpl;

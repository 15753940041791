import React from 'react';
import { PlacementType } from 'react-laag/dist/PlacementType';
import { uniqueId } from 'lodash';

import { DropdownTetherContext } from '../dropdown-tether/DropdownTether';

import { UITooltip } from './UITooltip';
import { useTooltip, UseTooltipProps } from './useTooltip';

type SimpleTooltipProps = Omit<UseTooltipProps, 'auto'> & {
  content?: React.ReactNode;
  noPointerEvents?: boolean;
  children?: React.ReactNode;
  placement?: PlacementType;
  strictPlacement?: boolean;
  dataRole?: string;
  triggerClassName?: string;
};

export const SimpleTooltip: React.FC<SimpleTooltipProps> = ({
  content,
  noPointerEvents,
  children,
  placement = 'top-center',
  strictPlacement = false,
  dataRole,
  triggerClassName,
  ...passProps
}) => {
  const [id] = React.useState(uniqueId('tooltip-'));
  const registerRef = React.useContext(DropdownTetherContext)?.handleNestedTether;

  const tooltipProps = useTooltip({
    placement,
    auto: !strictPlacement,
    ...passProps,
  });

  if (!content) {
    return <>{children}</>;
  }

  return (
    <span className={triggerClassName} {...tooltipProps.triggerProps}>
      {children}
      {tooltipProps.isOpen &&
        tooltipProps.renderLayer(
          <UITooltip
            elementRef={(ref) => {
              const instanceWrapper = ref
                ? {
                    getTetherInstance: () => ({
                      element: ref,
                    }),
                  }
                : null;
              registerRef?.(id, instanceWrapper);
            }}
            {...tooltipProps.layerProps}
            noPointerEvents={noPointerEvents}
            dataRole={dataRole}
          >
            {content}
          </UITooltip>,
        )}
    </span>
  );
};

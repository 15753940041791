// Libs
import React, { LinkHTMLAttributes } from 'react';

import { bemBlock } from '../../modules/bem';
import * as font from '../../modules/font';
import * as uiState from '../../modules/ui-state';
import {
  createComponent,
  sanitizeDangerousUrl,
  TComponent,
  WithNonInterferingComponent,
} from '../../modules/utils';

// Module
import './Link.less';

export type LinkProps<P extends object> = WithNonInterferingComponent<P, LinkOwnProps> &
  LinkHTMLAttributes<HTMLAnchorElement> & {
    disabled?: boolean;
    /** `external` property is valid for `<a>` version only and will be ignored in other cases. */
    external?: boolean;
    fontWeight?: font.Weight;
    /** Will try to convert `href` to safe URL. */
    sanitize?: boolean;
    size?: font.Size;
    type?: 'primary' | 'secondary';
  };

type LinkOwnProps = {
  className: string;
  href?: string;
  title?: string;
  target?: string;
  rel?: string;
  external?: boolean;
};

const block = bemBlock('n-Link');

/**
 * Use `Link` for clickable text elements and icons.
 *
 * `Link` will be rendered diferently, depending on passed properties.
 *
 * - If `component` is set, *Link* will be rendered as `component`
 * - If valid `href` is set, *Link* will be rendered as `<a>`
 * - If no `href` nor `component` is provided, *Link* will be rendered as `<span>`.
 *   The output will not be fully functional, however it will visually mimic the Link
 *   which may be useful in some cases.
 */
export function Link<P extends object>({
  className,
  component,
  disabled,
  external,
  fontWeight = 'semibold',
  href,
  sanitize,
  size,
  title,
  type = 'primary',
  ...passProps
}: LinkProps<P>): React.ReactElement {
  const calculatedHref = href && sanitize ? sanitizeDangerousUrl(href) : href;
  const ownProps: LinkOwnProps = {
    className: block({
      extra: [
        className,
        font.sizeClassName(size),
        font.weightClassName(fontWeight),
        uiState.disabledClassName(disabled),
      ],
      modifiers: {
        variant: type,
      },
    }),
    href: calculatedHref,
    title: title || href,
  };
  let tag: TComponent = 'span';

  if (component) {
    tag = component;
    ownProps.external = external;
  } else if (calculatedHref) {
    tag = 'a';

    if (external) {
      ownProps.target = '_blank';
      ownProps.rel = 'noopener noreferrer';
    }
  }

  return createComponent(tag, { ...ownProps, ...passProps });
}

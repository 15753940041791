import React from 'react';

import {
  OffsetBasedPaginationPageChangeHandler,
  OffsetBasedPaginationPageParams,
  Pagination,
} from '@neptune/shared/venus-ui';

import { PaginationLimitSelector } from './PaginationLimitSelector';

type PaginationWithLimitSelectorProps = OffsetBasedPaginationPageParams & {
  pageSizes: number[];
  onPageChange: OffsetBasedPaginationPageChangeHandler;
  onPageSizeChange: (pageSize: number) => void;
};

export function PaginationWithLimitSelector({
  limit,
  offset,
  total,
  pageSizes,
  onPageChange,
  onPageSizeChange,
}: PaginationWithLimitSelectorProps) {
  return (
    <Pagination
      offset={offset}
      limit={limit}
      total={total}
      onPageOffsetBasedChange={onPageChange}
      hideIfSinglePage={false}
      size="md"
    >
      <PaginationLimitSelector
        total={total}
        limit={limit}
        offset={offset}
        selected={limit}
        options={pageSizes}
        onChange={onPageSizeChange}
      />
    </Pagination>
  );
}

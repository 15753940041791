import { isLocalStorageCompatibleShape } from './local-storage-compatible';
import { version } from './local-storage-v13';
import { upgrade } from './local-storage-v13.upgrade';

export const upgradeToLocalStorageLatest = upgrade;
export type LocalStorageLatest = ThenArg<ReturnType<typeof upgradeToLocalStorageLatest>>;
export const localStorageLatestVersion = version;

export function isLocalStorageLatest(input: any): input is LocalStorageLatest {
  return isLocalStorageCompatibleShape(input) && input.version === version;
}

export function wrapAsLocalStorageLatest(data: LocalStorageLatest['data']): LocalStorageLatest {
  return { version, data };
}

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

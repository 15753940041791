/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributesHolderIdentifier } from './AttributesHolderIdentifier';
import {
    AttributesHolderIdentifierFromJSON,
    AttributesHolderIdentifierFromJSONTyped,
    AttributesHolderIdentifierToJSON,
} from './AttributesHolderIdentifier';

/**
 * 
 * @export
 * @interface TimeSeries
 */
export interface TimeSeries {
    /**
     * 
     * @type {string}
     * @memberof TimeSeries
     */
    attribute: string;
    /**
     * 
     * @type {AttributesHolderIdentifier}
     * @memberof TimeSeries
     */
    holder: AttributesHolderIdentifier;
    /**
     * 
     * @type {boolean}
     * @memberof TimeSeries
     */
    includePreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TimeSeries
     */
    lineage?: TimeSeriesLineageEnum;
    /**
     * 
     * @type {string}
     * @memberof TimeSeries
     */
    lineageEntityType?: TimeSeriesLineageEntityTypeEnum;
}


/**
 * @export
 */
export const TimeSeriesLineageEnum = {
    Full: 'FULL',
    None: 'NONE'
} as const;
export type TimeSeriesLineageEnum = typeof TimeSeriesLineageEnum[keyof typeof TimeSeriesLineageEnum];

/**
 * @export
 */
export const TimeSeriesLineageEntityTypeEnum = {
    Run: 'RUN',
    Experiment: 'EXPERIMENT'
} as const;
export type TimeSeriesLineageEntityTypeEnum = typeof TimeSeriesLineageEntityTypeEnum[keyof typeof TimeSeriesLineageEntityTypeEnum];


/**
 * Check if a given object implements the TimeSeries interface.
 */
export function instanceOfTimeSeries(value: object): value is TimeSeries {
    if (!('attribute' in value) || value['attribute'] === undefined) return false;
    if (!('holder' in value) || value['holder'] === undefined) return false;
    return true;
}

export function TimeSeriesFromJSON(json: any): TimeSeries {
    return TimeSeriesFromJSONTyped(json, false);
}

export function TimeSeriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSeries {
    if (json == null) {
        return json;
    }
    return {
        
        'attribute': json['attribute'],
        'holder': AttributesHolderIdentifierFromJSON(json['holder']),
        'includePreview': json['includePreview'] == null ? undefined : json['includePreview'],
        'lineage': json['lineage'] == null ? undefined : json['lineage'],
        'lineageEntityType': json['lineageEntityType'] == null ? undefined : json['lineageEntityType'],
    };
}

export function TimeSeriesToJSON(value?: TimeSeries | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attribute': value['attribute'],
        'holder': AttributesHolderIdentifierToJSON(value['holder']),
        'includePreview': value['includePreview'],
        'lineage': value['lineage'],
        'lineageEntityType': value['lineageEntityType'],
    };
}


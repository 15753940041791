import { LocalStorageCompatible } from './local-storage-compatible';
import { isLocalStorageV11, LocalStorageV11 } from './local-storage-v11';
import { upgrade as upgradeFromPrevious } from './local-storage-v11.upgrade';
import { LocalStorageV12 } from './local-storage-v12';

/*
 * NOTE: Create new migration files only when you need to remove or edit existing field. Do not create new migration file for adding new field.
 */
export async function upgrade(raw: LocalStorageCompatible): Promise<LocalStorageV12> {
  const input: LocalStorageV11 = isLocalStorageV11(raw) ? raw : await upgradeFromPrevious(raw);
  const { data } = input;

  const {
    chartsAxisScales,
    chartsXAxisMode,
    chartsXAxisMetric,
    chartsYAxisRange,
    chartsXAxisRange,
    chartsMetricsStepsRange,
    chartsIncludePreview,
    ...rest
  } = data;

  const chartWidgetOptionsByProject: LocalStorageV12['data']['chartWidgetOptionsByProject'] = {};

  if (chartsIncludePreview) {
    for (const projectId in chartsIncludePreview) {
      const inProjectIncludePreviews = chartsIncludePreview[projectId];
      chartWidgetOptionsByProject[projectId] = {};

      for (const attributeName in inProjectIncludePreviews) {
        chartWidgetOptionsByProject[projectId][attributeName] = {
          includePreview: inProjectIncludePreviews[attributeName],
        };
      }
    }
  }

  return {
    version: 12,
    data: {
      ...rest,
      chartWidgetOptionsByProject,
    },
  };
}

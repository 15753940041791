// Lib
import React from 'react';

import { Text } from '@neptune/shared/venus-ui';

// App
import ContactSupport from 'components/contact-support/ContactSupport';

// Module
import { ErrorPage } from './ErrorPage';

export const AppInitErrorPage = () => {
  return (
    <ErrorPage>
      <ErrorPage.Title>Something went wrong :(</ErrorPage.Title>
      <ErrorPage.Message>
        We're fixing the problem so please
        <Text fontWeight="bold"> try again in a few minutes</Text>.
      </ErrorPage.Message>
      <ErrorPage.Footer>
        If the problem still occurs, <ContactSupport>contact us</ContactSupport>.
      </ErrorPage.Footer>
    </ErrorPage>
  );
};

import React from 'react';
import { ColorResult, CustomPicker, HSLColor, RGBColor } from 'react-color';
import { Hue, Saturation } from 'react-color/lib/components/common';
// @ts-expect-error problem with types in react-color
import { SketchFields } from 'react-color/lib/components/sketch/SketchFields';
// @ts-expect-error problem with types in react-color
import { SketchPresetColors } from 'react-color/lib/components/sketch/SketchPresetColors';

import { bemBlock } from '@neptune/shared/venus-ui';

import { EyeDropper } from './EyeDropper';

import './SketchWithEyeDropper.less';

const block = bemBlock('sketch-with-eye-dropper');

type HSVColor = {
  a?: number | undefined;
  h: number;
  s: number;
  v: number;
};

type SketchWithEyeDropperProps = {
  className?: string;
  color?: string;
  presetColors?: string[];
  onChange: (color: ColorResult) => void;
};

type CustomSketchWithEyeDropperProps = {
  hex?: string;
  rgb?: RGBColor;
  hsl?: HSLColor;
  hsv?: HSVColor;
  className?: string;
  presetColors?: string[];
  onChange: (color: ColorResult | string, event?: React.ChangeEvent<HTMLInputElement>) => void;
};

function CustomSketchWithEyeDropper({
  rgb,
  hex,
  hsv,
  hsl,
  onChange,
  presetColors,
  className = '',
}: CustomSketchWithEyeDropperProps) {
  return (
    <div className={block({ extra: className })} data-role="color-picker">
      <div className={block('picker')}>
        <div className={block('saturation')}>
          {/* @ts-expect-error hsv and hsl are props of Saturation  */}
          <Saturation hsl={hsl} hsv={hsv} onChange={onChange} />
        </div>
        <div className={block('controls')}>
          <div className={block('sliders')}>
            <div className={block('hue')}>
              {/* @ts-expect-error hsl is prop of Hue  */}
              <Hue hsl={hsl} onChange={onChange} />
            </div>
          </div>
          <div className={block('active-color-wrapper')}>
            <div
              data-role="active-color"
              className={block('active-color')}
              style={rgb ? { background: `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})` } : undefined}
            />
          </div>
        </div>
        <div className={block('inputs')}>
          <EyeDropper onChange={onChange} />
          <SketchFields rgb={rgb} hsl={hsl} hex={hex} onChange={onChange} disableAlpha />
        </div>
        <SketchPresetColors colors={presetColors} onClick={onChange} />
      </div>
    </div>
  );
}

export const SketchWithEyeDropper = CustomPicker<SketchWithEyeDropperProps>(
  CustomSketchWithEyeDropper,
);

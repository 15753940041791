import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { bemBlock } from '../../../modules/bem';
import { LayoutRow } from '../../layout-row/LayoutRow';

const block = bemBlock('n-actions-menu-tab-group');

type TabItemProps = React.ComponentPropsWithoutRef<typeof DropdownMenu.RadioGroup>;

export const ActionsMenuTabGroup = React.forwardRef<
  React.ElementRef<typeof DropdownMenu.RadioGroup>,
  TabItemProps
>(({ className, children, ...props }, ref) => {
  return (
    <DropdownMenu.RadioGroup
      ref={ref}
      className={block({
        extra: className,
      })}
      {...props}
    >
      <LayoutRow span="auto" alignItems="center" spacedChildren="sm" withPadding={['xs', 'sm']}>
        {children}
      </LayoutRow>
    </DropdownMenu.RadioGroup>
  );
});

import React from 'react';
import { DebounceInput, DebounceInputProps, PropConstraints } from 'react-debounce-input';
import { mergeRefs } from 'react-laag';

import { plural } from '@neptune/shared/common-util';
import { bemBlock } from '@neptune/shared/venus-ui';

import {
  InputWithValidation,
  type InputWithValidationProps,
} from '../form-inputs/InputWithValidation';

import './RenameInput.less';

const RENAME_INPUT_DEBOUNCE_TIMEOUT = 300;
const RENAME_INPUT_MIN_LENGTH = 0;
const RENAME_INPUT_MAX_LENGTH = 256;
const RENAME_INPUT_ALLOWED_KEYS = [' ', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

const block = bemBlock('rename-input');
type RenameMenuItemProps = {
  name: string;
  onRename: (name: string) => void;
  isSaving?: boolean;
  onEnter?: () => void;
  minNameLength?: number;
  maxNameLength?: number;
  autoFocus?: boolean;
} & Omit<
  DebounceInputProps<HTMLInputElement, InputWithValidationProps & { type: 'input' }>,
  'onChange' | 'value' | 'type' | 'minLength' | 'maxLength'
>;

const RenameInputElement = React.forwardRef<
  HTMLInputElement,
  PropConstraints<HTMLInputElement> &
    Partial<
      InputWithValidationProps & {
        type: 'input';
        onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
      }
    >
>((props, ref) => {
  const { value, onChange, onKeyDown, inputProps = {}, ...rest } = props;
  const { maxLength: inputMaxLength = 256, onKeyDown: inputOnKeyDown } = inputProps;
  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (inputOnKeyDown) {
        inputOnKeyDown(event);
      }

      if (RENAME_INPUT_ALLOWED_KEYS.includes(event.key)) {
        return;
      }

      if (onKeyDown) {
        onKeyDown(event);
      }
    },
    [onKeyDown, inputOnKeyDown],
  );
  const inputWithValidationProps: InputWithValidationProps & { type: 'input' } = React.useMemo(
    () => ({
      size: 'medium',
      type: 'input',
      span: 'auto',
      inputProps: {
        ...inputProps,
        value,
        onChange,
        maxLength: inputMaxLength,
        onKeyDown: handleKeyDown,
      },
      ...rest,
    }),
    [inputProps, value, onChange, rest, inputMaxLength, handleKeyDown],
  );
  return <InputWithValidation ref={ref} {...inputWithValidationProps} />;
});

const DebounceInputWithRef = React.forwardRef<
  HTMLInputElement,
  DebounceInputProps<HTMLInputElement, Partial<InputWithValidationProps & { type: 'input' }>>
>((props, ref) => {
  return <DebounceInput {...props} inputRef={ref} />;
});

export const RenameInput = React.forwardRef<HTMLInputElement, RenameMenuItemProps>((props, ref) => {
  const {
    name,
    onRename,
    isSaving,
    onEnter,
    minNameLength = RENAME_INPUT_MIN_LENGTH,
    maxNameLength = RENAME_INPUT_MAX_LENGTH,
    inputProps = {},
    className,
    autoFocus,
    ...rest
  } = props;
  const [dirty, setDirty] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const inputRef = React.useRef<HTMLInputElement>();

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDirty(true);

      const value = event.target.value;

      if (value.length < minNameLength) {
        if (minNameLength === 0) {
          setError('Name cannot be empty');
        } else {
          const characters = plural(minNameLength, { one: 'character', other: 'characters' });
          setError(`Name cannot be shorter than ${minNameLength} ${characters}`);
        }

        return;
      }

      if (value.length > maxNameLength) {
        const characters = plural(maxNameLength, { one: 'character', other: 'characters' });
        setError(`Name cannot be longer than ${maxNameLength} ${characters}`);
        return;
      }

      setError(null);

      onRename(value);
    },
    [onRename, minNameLength, maxNameLength],
  );

  const status = React.useMemo(() => {
    if (!dirty) {
      return undefined;
    }

    if (error) {
      return 'invalid';
    }

    return isSaving ? 'pending' : 'valid';
  }, [dirty, isSaving, error]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.stopPropagation();

      if (status === 'invalid' || status === 'pending') {
        return;
      }

      if (event.key === 'Enter') {
        onEnter?.();
      }
    },
    [onEnter, status],
  );

  const inputPropsWithKeyDown = React.useMemo(
    () => ({
      ...inputProps,
      onKeyDown: handleKeyDown,
      'data-role': 'rename-input',
    }),
    [handleKeyDown, inputProps],
  );

  React.useEffect(() => {
    if (autoFocus && !rest.disabled) {
      inputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DebounceInputWithRef
      ref={mergeRefs(inputRef, ref)}
      element={RenameInputElement}
      debounceTimeout={RENAME_INPUT_DEBOUNCE_TIMEOUT}
      onChange={handleChange}
      value={name}
      containerClassName={block({ extra: className })}
      inputProps={inputPropsWithKeyDown}
      status={status}
      errorMessage={error}
      {...rest}
    />
  );
});

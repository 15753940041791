import React from 'react';

import { Icon } from '@neptune/shared/venus-ui';

export const ConflictIndicator: React.FC<{ isShown?: boolean; conflict?: string }> = ({
  isShown = false,
}) => {
  if (!isShown) {
    return null;
  }

  const transform = { x: -0.2, y: -0.2 };
  return <Icon data-role="conflict-indicator" color="warning" transform={transform} glyph="dot" />;
};

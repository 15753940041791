import React from 'react';
import {
  CellProps,
  Column,
  HeaderProps,
  Hooks,
  Row,
  useBlockLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import {
  AutoSizer,
  Grid,
  GridCellProps,
  Index,
  ScrollbarPresenceParams,
  ScrollSync,
} from 'react-virtualized';

import { naturalStringComparator } from '@neptune/shared/common-util';
import {
  bemBlock,
  Checkbox,
  Layout,
  OffsetBasedPaginationPageParams,
} from '@neptune/shared/venus-ui';

import { PaginationWithLimitSelector } from '../pagination-with-limit-selector/PaginationWithLimitSelector';

import { InteractiveTableHead } from './InteractiveTableHead';
import { DisplayRows, InteractiveTableToolbar } from './InteractiveTableToolbar';

import './InteractiveTable.less';

type InteractiveTableProps<D extends object> = {
  className?: string;
  columns: Column<D>[];
  data: D[];
  pageSizes?: number[];
};

const MIN_COL_WIDTH = 35;
const ROW_HEIGHT = 50;
const PAGE_SIZES = [10, 25, 50, 100];

const block = bemBlock('interactive-table');

export function InteractiveTable<D extends object>({
  className,
  columns,
  data,
  pageSizes = PAGE_SIZES,
}: InteractiveTableProps<D>): React.ReactElement {
  const [displayRows, setDisplayRows] = React.useState(DisplayRows.All);
  const [selectedIds, setSelectedIds] = React.useState<Record<string, boolean>>({});
  const [showVerticalScrollbar, setShowVerticalScrollbar] = React.useState(false);
  const [scrollbarSize, setScrollbarSize] = React.useState(0);

  const gridRef = React.useRef<Grid>(null);

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: MIN_COL_WIDTH,
    }),
    [],
  );

  const defaultSortBy = React.useMemo(
    () =>
      columns[0]
        ? [
            {
              id: String(columns[0].id),
            },
          ]
        : [],
    [columns],
  );

  const globalFilter = React.useCallback(
    (rows: Row<D>[], columnIDs: string[], filter: DisplayRows) => {
      // updating selection state does not automatically refresh filtered data, hence this "hack"
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      selectedIds;
      return rows.filter((row) => filter !== DisplayRows.Selected || row.isSelected);
    },
    [selectedIds],
  );

  const {
    getTableProps,
    headerGroups,
    visibleColumns,
    gotoPage,
    prepareRow,
    page,
    rows,
    selectedFlatRows,
    setGlobalFilter,
    setPageSize,
    state: { columnResizing, pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      globalFilter,
      initialState: {
        sortBy: defaultSortBy,
      },
      sortTypes: {
        customCompareNumbersAndStrings: (rowA, rowB, columnId) => {
          const a = rowA.values[columnId];
          const b = rowB.values[columnId];

          return naturalStringComparator(a, b);
        },
      },
    },
    useBlockLayout,
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    usePagination,
    useRowSelect,
    injectSelectionColumn,
  );

  // a bit of a hack that hoists selected rows ID before the hook execution in order to refresh global filter on row selection
  React.useEffect(() => {
    setSelectedIds(selectedRowIds);
  }, [selectedRowIds]);

  React.useEffect(() => {
    gridRef?.current?.recomputeGridSize();
  }, [columnResizing.isResizingColumn]);

  React.useEffect(() => {
    gridRef?.current?.forceUpdate();
  }, [rows, selectedFlatRows]);

  const handleDisplayRowsChange = React.useCallback(
    (newValue: DisplayRows) => {
      setDisplayRows(newValue);
      setGlobalFilter(newValue);
    },
    [setGlobalFilter],
  );

  const handleScrollbarPresenceChange = React.useCallback(
    ({ vertical, size }: ScrollbarPresenceParams) => {
      setScrollbarSize(size);
      setShowVerticalScrollbar(vertical);
    },
    [],
  );

  const handlePageChange = React.useCallback(
    ({ offset }: OffsetBasedPaginationPageParams) => {
      gotoPage(offset / pageSize);
    },
    [gotoPage, pageSize],
  );

  const getColumnWidth = React.useCallback(
    ({ index }: Index) => {
      return Number(visibleColumns[index]?.width) || MIN_COL_WIDTH;
    },
    [visibleColumns],
  );

  const cellRenderer = React.useCallback(
    ({ style, columnIndex, rowIndex, key }: GridCellProps) => {
      const row = page[rowIndex];
      prepareRow(row);
      const cell = row.cells[columnIndex];

      return (
        <div
          className={block('cell')}
          {...cell.getCellProps({ style, key })}
          data-role="table-cell"
          data-row={rowIndex}
          data-column={columnIndex}
        >
          {cell.render('Cell')}
        </div>
      );
    },
    [prepareRow, page],
  );

  const pagination = (
    <PaginationWithLimitSelector
      limit={pageSize}
      offset={pageIndex * pageSize}
      total={rows.length}
      pageSizes={pageSizes}
      onPageChange={handlePageChange}
      onPageSizeChange={setPageSize}
    />
  );

  return (
    <Layout.Column
      className={block({
        extra: className,
      })}
      data-role="interactive-table"
      width="100%"
      height="100%"
      spacedChildren="xs"
    >
      <InteractiveTableToolbar
        displayRows={displayRows}
        onDisplayRowsChange={handleDisplayRowsChange}
        paginationElement={pagination}
      />
      <ScrollSync>
        {({ scrollLeft, onScroll }) => (
          <Layout.Column {...getTableProps()} className={block('table')} span="greedy">
            <InteractiveTableHead
              headerGroups={headerGroups}
              scrollLeft={scrollLeft}
              scrollbarSize={scrollbarSize}
              showVerticalScrollbar={showVerticalScrollbar}
            />
            <Layout.Element span="greedy" position="relative" data-role="interactive-table-body">
              <AutoSizer>
                {({ height, width }) => (
                  <Grid
                    className={block('grid')}
                    cellRenderer={cellRenderer}
                    ref={gridRef}
                    columnCount={visibleColumns.length}
                    rowCount={page.length}
                    width={width}
                    height={height}
                    rowHeight={ROW_HEIGHT}
                    columnWidth={getColumnWidth}
                    onScroll={onScroll}
                    onScrollbarPresenceChange={handleScrollbarPresenceChange}
                  />
                )}
              </AutoSizer>
            </Layout.Element>
          </Layout.Column>
        )}
      </ScrollSync>
    </Layout.Column>
  );
}

function injectSelectionColumn<D extends object>(hooks: Hooks<D>) {
  hooks.visibleColumns.push((columns) => [
    {
      id: 'selection',
      Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<D>) => (
        <div className={block('selection-header')}>
          <Checkbox {...getToggleAllRowsSelectedProps()} data-role="toggle-select-all-rows" />
        </div>
      ),
      Cell: ({ row }: CellProps<D>) => (
        <div className={block('selection-cell')}>
          <Checkbox
            {...row.getToggleRowSelectedProps()}
            data-role="toggle-select-row"
            data-row={row.index}
          />
        </div>
      ),
      disableResizing: true,
      width: MIN_COL_WIDTH,
    },
    ...columns,
  ]);
}

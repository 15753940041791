import { LocalStorageCompatible } from './local-storage-compatible';
import { isLocalStorageV10, LocalStorageV10 } from './local-storage-v10';
import { upgrade as upgradeFromPrevious } from './local-storage-v10.upgrade';
import { LocalStorageV11 } from './local-storage-v11';

// in session storage, we had dynamic keys for colors and presetColors (based on the project id),
// for that reason we need to use a regex to get them all
function getSessionStorageByRegex(regex: RegExp) {
  const results: Record<string, any> = {};

  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);

    if (key && regex.test(key)) {
      try {
        const item = sessionStorage.getItem(key);
        results[key] = item && JSON.parse(item);
      } catch {}
    }
  }

  return results;
}

// the prefixes "neptune-colors--" and "neptune-preset-colors--" that were necessary in session storage,
// are not needed in our local storage structure, so we remove them
const removePrefixFromObjectKeys = (obj: Record<string, any>, prefix: string) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const newKey = key.replace(prefix, '');
    return { ...acc, [newKey]: value };
  }, {});
};

const COLORS_PREFIX = 'neptune-colors--';
const PRESET_COLORS_PREFIX = 'neptune-preset-colors--';

const getColorsFromSessionStorage = (): Record<string, Record<string, string>> => {
  const colorsObjects = getSessionStorageByRegex(new RegExp(`^${COLORS_PREFIX}`));
  return removePrefixFromObjectKeys(colorsObjects, COLORS_PREFIX);
};

const getPresetColorsFromSessionStorage = (): Record<string, string[]> => {
  const presetColorsObjects = getSessionStorageByRegex(new RegExp(`^${PRESET_COLORS_PREFIX}`));
  return removePrefixFromObjectKeys(presetColorsObjects, PRESET_COLORS_PREFIX);
};

/*
 * NOTE: Create new migration files only when you need to remove or edit existing field. Do not create new migration file for adding new field.
 */
export async function upgrade(raw: LocalStorageCompatible): Promise<LocalStorageV11> {
  const input: LocalStorageV10 = isLocalStorageV10(raw) ? raw : await upgradeFromPrevious(raw);
  const { data } = input;

  return {
    version: 11,
    data: {
      ...data,
      colors: getColorsFromSessionStorage(),
      presetColors: getPresetColorsFromSessionStorage(),
    },
  };
}

import React from 'react';
import { mergeRefs } from 'react-laag';

import { bemBlock } from '@neptune/shared/venus-ui';

import './AutoResizingTitle.less';

type AutoResizingTitleProps = {
  title: string;
  desiredFontSize?: number;
  maxLines?: number;
  minFontSize?: number;
  lineHeight?: number;
  onFontSizeChange?: (fontSize: number) => void;
} & React.HTMLAttributes<HTMLSpanElement>;

const block = bemBlock('auto-resizing-title');

export const AutoResizingTitle: React.FC<AutoResizingTitleProps> = React.forwardRef<
  HTMLSpanElement,
  AutoResizingTitleProps
>(
  (
    {
      title,
      desiredFontSize = 32,
      maxLines = 2,
      minFontSize = desiredFontSize / 2,
      lineHeight = 1.25,
      onFontSizeChange,
      ...spanProps
    },
    ref,
  ) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const textRef = React.useRef<HTMLDivElement>(null);
    const [fontSize, setFontSize] = React.useState(desiredFontSize);
    const [containerWidth, setContainerWidth] = React.useState(0);

    const changeFontSize = React.useCallback(
      (size: number) => {
        setFontSize(size);
        onFontSizeChange?.(size);
      },
      [onFontSizeChange],
    );

    const checkTextOverflow = React.useCallback(() => {
      const textElement = textRef.current;

      if (!textElement) {
        return;
      }

      // Calculate the maximum height (equivalent to 2 lines at desired font size)
      const calculatedLineHeight = desiredFontSize * lineHeight;
      const maxHeight = calculatedLineHeight * maxLines;

      // Reset to desired font size and check if it fits within max height
      textElement.style.fontSize = `${desiredFontSize}px`;
      textElement.style.maxHeight = 'none'; // Remove height constraint to measure true height
      textElement.style.webkitLineClamp = 'none'; // Remove line clamp to measure true height

      if (textElement.scrollHeight > maxHeight) {
        // Text is too tall at desired font size, need to reduce font size
        let currentSize = desiredFontSize;

        while (textElement.scrollHeight > maxHeight && currentSize > minFontSize) {
          // Don't go smaller than 8px font size
          currentSize -= 0.5;
          textElement.style.fontSize = `${currentSize}px`;
        }

        changeFontSize(currentSize);

        // If we've hit min font size and text still doesn't fit,
        // calculate how many lines we can fit and set line clamp
        if (currentSize <= minFontSize && textElement.scrollHeight > maxHeight) {
          // Calculate how many lines can fit in the maxHeight
          const currentLineHeight = currentSize * lineHeight;
          const linesCanFit = Math.floor(maxHeight / currentLineHeight);
          textElement.style.webkitLineClamp = linesCanFit.toString();
        } else {
          // Text fits within max height, no need for line clamp
          textElement.style.webkitLineClamp = 'none';
        }
      } else {
        // Text fits within max height at desired font size
        changeFontSize(desiredFontSize);
        textElement.style.webkitLineClamp = 'none';
      }

      // Restore max height constraint for actual display
      textElement.style.maxHeight = `${maxHeight}px`;
    }, [desiredFontSize, maxLines, minFontSize, lineHeight, changeFontSize]);

    React.useEffect(() => {
      // Initialize ResizeObserver
      if (!containerRef.current) {
        return;
      }

      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setContainerWidth(entry.contentRect.width);
        }
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }, []);

    // Check for text overflow when container width or title changes
    React.useEffect(() => {
      checkTextOverflow();
    }, [containerWidth, title, checkTextOverflow]);

    // Calculate the maximum height (2 lines at desired font size)
    const maxHeight = desiredFontSize * lineHeight * maxLines;

    return (
      <div ref={containerRef} className={block()}>
        <span
          {...spanProps}
          ref={mergeRefs(ref, textRef)}
          className={block({ element: 'text', extra: spanProps.className })}
          style={{
            fontSize: `${fontSize}px`,
            lineHeight: `${lineHeight}`,
            maxHeight: `${maxHeight}px`,
            ...(spanProps.style ?? {}),
          }}
          title={title}
        >
          {title}
        </span>
      </div>
    );
  },
);

import React from 'react';

import { bemBlock, Emblem, Text } from '@neptune/shared/venus-ui';

import './ConflictEmblem.less';

const block = bemBlock('conflict-emblem');

export const ConflictEmblem: React.FC = ({ children }) => {
  return (
    <Emblem
      className={block()}
      color="#FDF1DC"
      size="sm"
      text={
        <Text size="xxs" className={block('text')}>
          {children}
        </Text>
      }
    />
  );
};

// Libs
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faApple,
  faGithub,
  faKaggle,
  faLinkedin,
  faLinux,
  faPython,
  faTwitter,
  faWindows,
} from '@fortawesome/free-brands-svg-icons';
import {
  faBell,
  faCalendarAlt,
  faCaretSquareDown as farCaretSquareDown,
  faCaretSquareRight as farCaretSquareRight,
  faCheckCircle as farCheckCircle,
  faCircle as farCircle,
  faClipboard,
  faClone,
  faComment,
  faCommentAlt,
  faComments,
  faCreditCard,
  faFileAlt,
  faFileCode,
  faImage,
  faLightbulb,
  faListAlt,
  faPlusSquare,
  faQuestionCircle,
  faSquare as farSquare,
  faUser,
  faWindowMinimize,
} from '@fortawesome/free-regular-svg-icons';
import {
  faArrowDown,
  faArrowUp,
  faBan,
  faBars,
  faBold,
  faBoxArchive,
  faBriefcase,
  faCaretDown,
  faCaretRight,
  faCaretSquareDown,
  faCaretSquareRight,
  faCaretUp,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleNotch,
  faClock,
  faCloudUploadAlt,
  faCode,
  faCog,
  faColumns,
  faCompress,
  faCrown,
  faDatabase,
  faDesktop,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExternalLinkAlt,
  faFileExport,
  faFileImport,
  faFlask,
  faFolder,
  faFolderOpen,
  faFont,
  faGift,
  faGlobeAmericas,
  faGreaterThan,
  faGripHorizontal,
  faGripLines,
  faHashtag,
  faHeading,
  faInfo,
  faInfoCircle,
  faItalic,
  faLessThan,
  faLevelUpAlt,
  faLink,
  faList,
  faListOl,
  faListUl,
  faLock,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faMinus,
  faMinusCircle,
  faNetworkWired,
  faNotEqual,
  faParagraph,
  faPauseCircle,
  faPen,
  faPlus,
  faPlusCircle,
  faRandom,
  faReceipt,
  faRetweet,
  faRocket,
  faSearch,
  faSignOutAlt,
  faSlidersH,
  faSortDown,
  faSortUp,
  faSpinner,
  faSquare,
  faStar as fasFaStar,
  faStopwatch,
  faStrikethrough,
  faTachometerAlt,
  faTag,
  faTerminal,
  faThLarge,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faToggleOff,
  faToggleOn,
  faUndoAlt,
  faUserCog,
  faUserFriends,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

import {
  customIcons,
  nepAxis,
  nepChartDots,
  nepChartRough,
  nepChartSmooth,
  nepChip,
  nepCross,
  nepDotsV,
  nepDropdown,
  nepEye,
  nepEyeStrike,
  nepFastFastForward,
  nepFastForward,
  nepFile,
  nepFloat,
  nepFolder,
  nepGrid,
  nepGripBottomRight,
  nepLeftBracket,
  nepLeftOpen,
  nepNumber,
  nepOk,
  nepPencil,
  nepPlayCircle,
  nepRoundFlask,
  nepSourceCode,
  nepTrashBin,
  nepWarning,
} from '../components/icon/icons';

// Module
library.add(
  //   faAngleDoubleLeft,
  //   faAngleDoubleRight,
  //   faAngleDown,
  //   faAngleLeft,
  //   faAngleRight,
  //   faAngleUp,
  faApple,
  faArrowDown,
  faArrowUp,
  faBan,
  faBars,
  faBold,
  faBriefcase,
  faBoxArchive,
  faBell,
  faCalendarAlt,
  faCheckCircle,
  faCircleNotch,
  faCaretDown,
  faCaretRight,
  faCaretSquareDown,
  farCaretSquareDown,
  faCaretSquareRight,
  farCaretSquareRight,
  farCircle,
  farCheckCircle,
  faCaretUp,
  faChartLine,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faClipboard,
  faClone,
  faCloudUploadAlt,
  faClock,
  faCode,
  faCog,
  faColumns,
  faCommentAlt,
  faComment,
  faComments,
  faCompress,
  faCreditCard,
  faCrown,
  faDatabase,
  faDesktop,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExchangeAlt,
  //   faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExternalLinkAlt,
  faFileAlt,
  faFileCode,
  faFileImport,
  faFileExport,
  faFlask,
  faFolder,
  faFolderOpen,
  faFont,
  faItalic,
  faGift,
  faGithub,
  faGlobeAmericas,
  faGreaterThan,
  faGripHorizontal,
  faGripLines,
  faHashtag,
  faHeading,
  faImage,
  faInfo,
  faInfoCircle,
  faKaggle,
  faLessThan,
  faLightbulb,
  faLink,
  faLinkedin,
  faLinux,
  faList,
  faListAlt,
  faListOl,
  faListUl,
  faLevelUpAlt,
  //   faLifeRing,
  faLock,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faNetworkWired,
  faMinus,
  faMinusCircle,
  faNotEqual,
  faParagraph,
  faPauseCircle,
  faPen,
  faPlusCircle,
  faPlusSquare,
  faPython,
  faQuestionCircle,
  faRandom,
  faReceipt,
  faRocket,
  faRetweet,
  //   faSearchMinus,
  //   faShapes,
  faStrikethrough,
  faSignOutAlt,
  faSquare,
  farSquare,
  //   farFaStar,
  fasFaStar,
  faSlidersH,
  faSortDown,
  faSortUp,
  faSpinner,
  //   faStopCircle,
  faStopwatch,
  faTachometerAlt,
  faTag,
  faTerminal,
  faThumbtack,
  //   faTh,
  faThLarge,
  faTimes,
  faTimesCircle,
  faToggleOn,
  faToggleOff,
  faTwitter,
  faUndoAlt,
  faUser,
  faUserCog,
  faUserFriends,
  faUsers,
  faWindowMinimize,
  faWindows,
  nepChartDots,
  nepChartRough,
  nepChartSmooth,
  nepChip,
  nepLeftBracket,
  nepNumber,
  nepGrid,
  nepGripBottomRight,
  // nepResize,
  nepSourceCode,
  nepFloat,
  nepDropdown,
  nepWarning,
  nepPencil,
  faPlus,
  faSearch,
  nepFile,
  nepDotsV,
  nepRoundFlask,
  nepCross,
  nepEye,
  nepEyeStrike,
  nepTrashBin,
  nepOk,
  nepLeftOpen,
  nepFolder,
  nepPlayCircle,
  nepFastForward,
  nepFastFastForward,
  nepAxis,
  ...customIcons,
);

// Libs
import React from 'react';

import {
  Button,
  GenericLink,
  GenericLinkProps,
  Icon,
  Layout,
  Text,
} from '@neptune/shared/venus-ui';

// App
import ContactSupport from 'components/contact-support/ContactSupport';

import neptuneLogo from '../assets/neptune-logo.svg';

export type ErrorBoundaryMessageProps = {
  isRoot?: boolean;
  onReset?(): void;
};

export const ErrorBoundaryMessage: React.FC<ErrorBoundaryMessageProps> = ({
  isRoot = false,
  onReset,
}) => (
  <Layout.Column withPadding="xl" height="100%" width="100%" overflow="auto" spacedChildren="xl">
    {isRoot && (
      <Layout.Row span="shrink">
        <img src={neptuneLogo} height={44} alt="" />
      </Layout.Row>
    )}
    <Layout.Column alignItems="center" justifyContent="center" spacedChildren="lg">
      <Icon glyph="exclamation-triangle" color="warning" size="lg" />
      <Layout.Column alignItems="center" spacedChildren="md" span="shrink">
        <Text align="center" color="text-secondary" size="md">
          {isRoot
            ? 'Sorry, the Neptune application encountered an unexpected error and cannot continue.'
            : 'Sorry, we encountered an unexpected error and cannot continue.'}
        </Text>
        <Text align="center">
          Our engineering team has been notified about the issue and a fix is on its way.
        </Text>
      </Layout.Column>
      {isRoot && (
        <Button<GenericLinkProps>
          component={GenericLink}
          variant="primary"
          size="lg"
          onClick={onReset}
          href="/"
        >
          Take me Home
        </Button>
      )}
      <Text>
        If this issue persists please <ContactSupport>contact us</ContactSupport>.
      </Text>
    </Layout.Column>
  </Layout.Column>
);

import { LocalStorageCompatible } from './local-storage-compatible';
import { isLocalStorageV12, LocalStorageV12 } from './local-storage-v12';
import { upgrade as upgradeFromPrevious } from './local-storage-v12.upgrade';
import { LocalStorageV13 } from './local-storage-v13';

/*
 * NOTE: Create new migration files only when you need to remove or edit existing field. Do not create new migration file for adding new field.
 */
export async function upgrade(raw: LocalStorageCompatible): Promise<LocalStorageV13> {
  const input: LocalStorageV12 = isLocalStorageV12(raw) ? raw : await upgradeFromPrevious(raw);
  const { data } = input;

  for (const projectChartOptions of Object.values(data.chartWidgetOptionsByProject ?? {})) {
    for (const options of Object.values(projectChartOptions)) {
      if ('includePreview' in options) {
        (
          options as Required<
            LocalStorageV13['data']
          >['chartWidgetOptionsByProject'][string][string]
        ).previewPoints = options.includePreview ? 'on' : 'off';
        delete options.includePreview;
      }
    }
  }

  return {
    version: 13,
    data,
  };
}

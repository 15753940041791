import React from 'react';
import { HeaderGroup } from 'react-table';

import { bemBlock, Layout } from '@neptune/shared/venus-ui';

import { SortIndicator } from '../sort-indicator/SortIndicator';

import './InteractiveTableHead.less';

const block = bemBlock('interactive-table-head');

export function InteractiveTableHead<D extends object>({
  headerGroups,
  scrollLeft,
  scrollbarSize,
  showVerticalScrollbar,
}: {
  headerGroups: HeaderGroup<D>[];
  scrollLeft: number;
  scrollbarSize: number;
  showVerticalScrollbar: boolean;
}) {
  const ref = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollLeft = scrollLeft;
    }
  }, [scrollLeft]);

  const getHeaderGroupProps = React.useCallback(
    (headerGroup: HeaderGroup<D>) => {
      if (!showVerticalScrollbar || !scrollbarSize) {
        return headerGroup.getHeaderGroupProps();
      }

      const props = headerGroup.getHeaderGroupProps();

      return {
        ...props,
        style: {
          ...props.style,
          width: props.style?.width
            ? `${parseInt(props.style.width.toString(), 10) + scrollbarSize}px`
            : undefined,
        },
      };
    },
    [scrollbarSize, showVerticalScrollbar],
  );

  return (
    <Layout.Element
      elementRef={ref}
      overflow="hidden"
      span="shrink"
      data-role="interactive-table-head"
    >
      {headerGroups.map((headerGroup) => (
        <Layout.Row {...getHeaderGroupProps(headerGroup)}>
          {headerGroup.headers.map((header) => (
            <div
              className={block('header')}
              {...header.getHeaderProps()}
              data-role="table-header"
              data-header-id={header.id}
            >
              {header.render('Header')}
              {header.canSort && (
                <SortIndicator
                  className={block('sort')}
                  direction={getSortDirection(header)}
                  {...header.getSortByToggleProps()}
                />
              )}
              {header.canResize && (
                <div className={block('resizer')} {...header.getResizerProps()} />
              )}
            </div>
          ))}
          {showVerticalScrollbar && (
            <div className={block('scrollFix')} style={{ width: `${scrollbarSize}px` }} />
          )}
        </Layout.Row>
      ))}
    </Layout.Element>
  );
}

function getSortDirection<D extends object>(header: HeaderGroup<D>) {
  const direction = header.isSortedDesc ? 'desc' : 'asc';

  return header.isSorted ? direction : 'none';
}

/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QueryLeaderboardParamsNameAliasDTO } from './QueryLeaderboardParamsNameAliasDTO';
import {
    QueryLeaderboardParamsNameAliasDTOFromJSON,
    QueryLeaderboardParamsNameAliasDTOFromJSONTyped,
    QueryLeaderboardParamsNameAliasDTOToJSON,
} from './QueryLeaderboardParamsNameAliasDTO';

/**
 * 
 * @export
 * @interface QueryLeaderboardParamsQueryAliasesDTO
 */
export interface QueryLeaderboardParamsQueryAliasesDTO {
    /**
     * 
     * @type {Array<QueryLeaderboardParamsNameAliasDTO>}
     * @memberof QueryLeaderboardParamsQueryAliasesDTO
     */
    experimentAliases?: Array<QueryLeaderboardParamsNameAliasDTO>;
    /**
     * 
     * @type {Array<QueryLeaderboardParamsNameAliasDTO>}
     * @memberof QueryLeaderboardParamsQueryAliasesDTO
     */
    runAliases?: Array<QueryLeaderboardParamsNameAliasDTO>;
}

/**
 * Check if a given object implements the QueryLeaderboardParamsQueryAliasesDTO interface.
 */
export function instanceOfQueryLeaderboardParamsQueryAliasesDTO(value: object): value is QueryLeaderboardParamsQueryAliasesDTO {
    return true;
}

export function QueryLeaderboardParamsQueryAliasesDTOFromJSON(json: any): QueryLeaderboardParamsQueryAliasesDTO {
    return QueryLeaderboardParamsQueryAliasesDTOFromJSONTyped(json, false);
}

export function QueryLeaderboardParamsQueryAliasesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryLeaderboardParamsQueryAliasesDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'experimentAliases': json['experimentAliases'] == null ? undefined : ((json['experimentAliases'] as Array<any>).map(QueryLeaderboardParamsNameAliasDTOFromJSON)),
        'runAliases': json['runAliases'] == null ? undefined : ((json['runAliases'] as Array<any>).map(QueryLeaderboardParamsNameAliasDTOFromJSON)),
    };
}

export function QueryLeaderboardParamsQueryAliasesDTOToJSON(value?: QueryLeaderboardParamsQueryAliasesDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'experimentAliases': value['experimentAliases'] == null ? undefined : ((value['experimentAliases'] as Array<any>).map(QueryLeaderboardParamsNameAliasDTOToJSON)),
        'runAliases': value['runAliases'] == null ? undefined : ((value['runAliases'] as Array<any>).map(QueryLeaderboardParamsNameAliasDTOToJSON)),
    };
}


import React from 'react';

import { plural } from '@neptune/shared/common-util';
import { bemBlock, Layout, Link } from '@neptune/shared/venus-ui';

import { ConflictEmblem } from './ConflictEmblem';

import './ResetLocalSettingsEmblem.less';

const block = bemBlock('reset-local-settings-emblem');

export function ResetLocalSettingsEmblem<T extends string>({
  conflictName,
  conflictIds,
  onReset,
}: {
  conflictName: T;
  conflictIds?: string[];
  onReset?: (key: T, ids: string[]) => void;
}) {
  const length = conflictIds?.length ?? 0;
  const handleClick = React.useCallback(() => {
    if (Array.isArray(conflictIds) && conflictIds.length > 0) {
      onReset?.(conflictName, conflictIds);
    }
  }, [conflictIds, conflictName, onReset]);

  if (length === 0) {
    return null;
  }

  const pluralized = plural(length, { one: 'widget', other: 'widgets' });

  return (
    <Layout.Element
      withGutter="sm"
      data-role="reset-settings-emblem"
      data-conflict-name={conflictName}
    >
      <ConflictEmblem>
        {`Setting override in ${length} ${pluralized}. `}
        {onReset && (
          <Link
            data-role="conflict-reset"
            className={block('link')}
            size="xxs"
            onClick={handleClick}
          >
            Reset {pluralized}
          </Link>
        )}
      </ConflictEmblem>
    </Layout.Element>
  );
}

// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { Layout } from '../layout/Layout';

// Module
import './InputWithSuffix.less';

type InputWithSuffixProps = {
  'data-role'?: string;
  className?: string;
  disabled?: boolean;
  withoutOutline?: boolean;
  error?: boolean;
  suffix?: React.ReactElement;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  onClick?: (e: React.MouseEvent) => void;
};

const block = bemBlock('input-with-suffix');

export const InputWithSuffix: React.FC<InputWithSuffixProps> = ({
  className,
  disabled,
  withoutOutline,
  error,
  suffix,
  inputProps,
  'data-role': dataRole,
  onClick,
}) => {
  const { onFocus, onBlur, className: inputClassName, ...restProps } = inputProps || {};
  const [focused, setFocused] = React.useState(false);

  const handleFocus = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      onFocus?.(event);
    },
    [setFocused, onFocus],
  );

  const handleBlur = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      onBlur?.(event);
    },
    [setFocused, onBlur],
  );

  const modifiers = {
    'without-outline': withoutOutline,
    disabled,
    focused,
    error: !!error,
  };

  return (
    <Layout.Row className={block({ modifiers, extra: className })} overflow="hidden">
      <input
        className={block({ element: 'input', extra: inputClassName })}
        aria-invalid={!!error}
        data-role={dataRole}
        disabled={disabled}
        onClick={onClick}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...restProps}
      />
      <Layout.Row className={block('suffix')} alignItems="center" span="shrink">
        {suffix}
      </Layout.Row>
    </Layout.Row>
  );
};

import React from 'react';

import { bemBlock, Icon, IconStack, text } from '@neptune/shared/venus-ui';

import './IconWithIndicator.less';

const block = bemBlock('icon-with-indicator');

export function IconWithIndicator({
  glyph,
  showIndicator = false,
  size,
  className,
  transform = { x: 0.4, y: -0.2 },
  indicatorColor = 'info',
  indicatorSize = 'lg',
}: {
  glyph: React.ComponentProps<typeof Icon>['glyph'];
  showIndicator?: boolean;
  size?: React.ComponentProps<typeof Icon>['size'];
  className?: string;
  transform?: React.ComponentProps<typeof Icon>['transform'];
  indicatorColor?: text.Color;
  indicatorSize?: React.ComponentProps<typeof Icon>['size'];
}) {
  return (
    <IconStack className={block({ extra: className })}>
      <Icon color="text-alt" size={size} fixedWidth glyph={glyph} />
      {showIndicator && (
        <Icon
          className={block({ element: 'dot-indicator', modifiers: { color: indicatorColor } })}
          size={indicatorSize}
          transform={transform}
          glyph="dot"
        />
      )}
    </IconStack>
  );
}

import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';

interface UseDebounceControlledInputValueProps {
  debouncedValue: string;
  onValueChange: (value: string) => void;
  debounceDelayMs?: number;
}

export const useDebounceControlledInputValue = ({
  debouncedValue,
  onValueChange,
  debounceDelayMs = 300,
}: UseDebounceControlledInputValueProps) => {
  const [value, setValue] = useState(debouncedValue);

  const { debouncedOnValueChange, cancelDebouncedFunction } = useMemo(() => {
    const debouncedOnValueChange = debounce((newValue: string) => {
      onValueChange(newValue);
    }, debounceDelayMs);

    return {
      debouncedOnValueChange,
      cancelDebouncedFunction: () => debouncedOnValueChange.cancel(),
    };
  }, [onValueChange, debounceDelayMs]);

  const handleValueChange = (newValue: string) => {
    setValue(newValue);
    debouncedOnValueChange(newValue);
  };

  useEffect(() => {
    setValue(debouncedValue);

    return cancelDebouncedFunction;
  }, [cancelDebouncedFunction, debouncedValue]);

  return { value, handleValueChange };
};

export default useDebounceControlledInputValue;

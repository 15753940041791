/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectAliasGroupDTO } from './ProjectAliasGroupDTO';
import {
    ProjectAliasGroupDTOFromJSON,
    ProjectAliasGroupDTOFromJSONTyped,
    ProjectAliasGroupDTOToJSON,
} from './ProjectAliasGroupDTO';
import type { ReportVersionMetadataDTO } from './ReportVersionMetadataDTO';
import {
    ReportVersionMetadataDTOFromJSON,
    ReportVersionMetadataDTOFromJSONTyped,
    ReportVersionMetadataDTOToJSON,
} from './ReportVersionMetadataDTO';
import type { ReportNodeDTO } from './ReportNodeDTO';
import {
    ReportNodeDTOFromJSON,
    ReportNodeDTOFromJSONTyped,
    ReportNodeDTOToJSON,
} from './ReportNodeDTO';

/**
 * 
 * @export
 * @interface ReportVersionDTO
 */
export interface ReportVersionDTO {
    /**
     * List of experiment aliases
     * @type {Array<ProjectAliasGroupDTO>}
     * @memberof ReportVersionDTO
     */
    experimentAliases?: Array<ProjectAliasGroupDTO>;
    /**
     * 
     * @type {ReportVersionMetadataDTO}
     * @memberof ReportVersionDTO
     */
    metadata: ReportVersionMetadataDTO;
    /**
     * 
     * @type {Array<ReportNodeDTO>}
     * @memberof ReportVersionDTO
     */
    nodes: Array<ReportNodeDTO>;
    /**
     * 
     * @type {string}
     * @memberof ReportVersionDTO
     */
    reportName: string;
    /**
     * List of run id aliases
     * @type {Array<ProjectAliasGroupDTO>}
     * @memberof ReportVersionDTO
     */
    runAliases?: Array<ProjectAliasGroupDTO>;
}

/**
 * Check if a given object implements the ReportVersionDTO interface.
 */
export function instanceOfReportVersionDTO(value: object): value is ReportVersionDTO {
    if (!('metadata' in value) || value['metadata'] === undefined) return false;
    if (!('nodes' in value) || value['nodes'] === undefined) return false;
    if (!('reportName' in value) || value['reportName'] === undefined) return false;
    return true;
}

export function ReportVersionDTOFromJSON(json: any): ReportVersionDTO {
    return ReportVersionDTOFromJSONTyped(json, false);
}

export function ReportVersionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportVersionDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'experimentAliases': json['experimentAliases'] == null ? undefined : ((json['experimentAliases'] as Array<any>).map(ProjectAliasGroupDTOFromJSON)),
        'metadata': ReportVersionMetadataDTOFromJSON(json['metadata']),
        'nodes': ((json['nodes'] as Array<any>).map(ReportNodeDTOFromJSON)),
        'reportName': json['reportName'],
        'runAliases': json['runAliases'] == null ? undefined : ((json['runAliases'] as Array<any>).map(ProjectAliasGroupDTOFromJSON)),
    };
}

export function ReportVersionDTOToJSON(value?: ReportVersionDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'experimentAliases': value['experimentAliases'] == null ? undefined : ((value['experimentAliases'] as Array<any>).map(ProjectAliasGroupDTOToJSON)),
        'metadata': ReportVersionMetadataDTOToJSON(value['metadata']),
        'nodes': ((value['nodes'] as Array<any>).map(ReportNodeDTOToJSON)),
        'reportName': value['reportName'],
        'runAliases': value['runAliases'] == null ? undefined : ((value['runAliases'] as Array<any>).map(ProjectAliasGroupDTOToJSON)),
    };
}


export enum KnownAttributes {
  CreationTime = 'sys/creation_time',
  Description = 'sys/description',
  ExperimentIsHead = 'sys/experiment/is_head',
  // run only
  Entrypoint = 'source_code/entrypoint',
  // run only
  Failed = 'sys/failed',
  // run only
  Files = 'source_code/files',
  // run only
  Git = 'source_code/git',
  GitDiff = 'source_code/diff',
  GitUpstreamDiffPrefix = 'source_code/upstream_diff',
  Id = 'sys/id',
  CustomRunId = 'sys/custom_run_id',
  ModificationTime = 'sys/modification_time',
  MonitoringTime = 'sys/monitoring_time',
  Name = 'sys/name',
  // run only
  Owner = 'sys/owner',
  // model version only
  ModelId = 'sys/model_id',
  Requirements = 'source_code/requirements',
  // run only
  RunningTime = 'sys/running_time',
  Size = 'sys/size',
  // model version only
  Stage = 'sys/stage',
  State = 'sys/state',
  Tags = 'sys/tags',
  Trashed = 'sys/trashed',
  GroupTags = 'sys/group_tags',
  Archived = 'sys/archived',
}

export const knownAttributes: KnownAttributes[] = Object.values(KnownAttributes);

export enum KnownAttributesForExperimentIdentification {
  Id = KnownAttributes.Id,
  Name = KnownAttributes.Name,
  ExperimentIsHead = KnownAttributes.ExperimentIsHead,
}

export const knownAttributesForExperimentIdentification: KnownAttributesForExperimentIdentification[] =
  Object.values(KnownAttributesForExperimentIdentification);

/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TableViewParamsDTO } from './TableViewParamsDTO';
import {
    TableViewParamsDTOFromJSON,
    TableViewParamsDTOFromJSONTyped,
    TableViewParamsDTOToJSON,
} from './TableViewParamsDTO';

/**
 * 
 * @export
 * @interface RunGroupDTO
 */
export interface RunGroupDTO {
    /**
     * 
     * @type {string}
     * @memberof RunGroupDTO
     */
    color?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RunGroupDTO
     */
    experimentMemberKeys?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RunGroupDTO
     */
    firstMembersByQueryCount?: number;
    /**
     * 
     * @type {string}
     * @memberof RunGroupDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RunGroupDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RunGroupDTO
     */
    projectId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RunGroupDTO
     */
    runMemberKeys?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RunGroupDTO
     */
    showOnlySelectedRuns: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RunGroupDTO
     */
    showSelectedHiddenByFilter?: boolean;
    /**
     * 
     * @type {TableViewParamsDTO}
     * @memberof RunGroupDTO
     */
    viewParams: TableViewParamsDTO;
}

/**
 * Check if a given object implements the RunGroupDTO interface.
 */
export function instanceOfRunGroupDTO(value: object): value is RunGroupDTO {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('showOnlySelectedRuns' in value) || value['showOnlySelectedRuns'] === undefined) return false;
    if (!('viewParams' in value) || value['viewParams'] === undefined) return false;
    return true;
}

export function RunGroupDTOFromJSON(json: any): RunGroupDTO {
    return RunGroupDTOFromJSONTyped(json, false);
}

export function RunGroupDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RunGroupDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'color': json['color'] == null ? undefined : json['color'],
        'experimentMemberKeys': json['experimentMemberKeys'] == null ? undefined : json['experimentMemberKeys'],
        'firstMembersByQueryCount': json['firstMembersByQueryCount'] == null ? undefined : json['firstMembersByQueryCount'],
        'id': json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'projectId': json['projectId'] == null ? undefined : json['projectId'],
        'runMemberKeys': json['runMemberKeys'] == null ? undefined : json['runMemberKeys'],
        'showOnlySelectedRuns': json['showOnlySelectedRuns'],
        'showSelectedHiddenByFilter': json['showSelectedHiddenByFilter'] == null ? undefined : json['showSelectedHiddenByFilter'],
        'viewParams': TableViewParamsDTOFromJSON(json['viewParams']),
    };
}

export function RunGroupDTOToJSON(value?: RunGroupDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'color': value['color'],
        'experimentMemberKeys': value['experimentMemberKeys'],
        'firstMembersByQueryCount': value['firstMembersByQueryCount'],
        'id': value['id'],
        'name': value['name'],
        'projectId': value['projectId'],
        'runMemberKeys': value['runMemberKeys'],
        'showOnlySelectedRuns': value['showOnlySelectedRuns'],
        'showSelectedHiddenByFilter': value['showSelectedHiddenByFilter'],
        'viewParams': TableViewParamsDTOToJSON(value['viewParams']),
    };
}


import React from 'react';

type UseDropdownMaxHeightParams = {
  defaultHeight: number;
  minHeight?: number;
  padding?: number;
  side: 'top' | 'bottom';
};

export const useFitDropdown = ({
  defaultHeight,
  minHeight = 0,
  padding = 32,
  side,
}: UseDropdownMaxHeightParams) => {
  const ref = React.useRef<HTMLElement | null | undefined>();
  const [height, setHeight] = React.useState(defaultHeight);
  const [width, setWidth] = React.useState<number>(0);

  const updateHeight = React.useCallback(() => {
    let newHeight = window.innerHeight;
    const rect = ref.current?.getBoundingClientRect();

    if (rect) {
      newHeight = side === 'top' ? rect.y : window.innerHeight - (rect.y + rect.height);
    }

    setHeight(Math.min(defaultHeight, Math.max(minHeight, newHeight) - padding));
  }, [defaultHeight, minHeight, padding, side]);

  const updateWidth = React.useCallback(() => {
    setWidth(ref.current?.getBoundingClientRect()?.width ?? 0);
  }, []);

  const targetRef = React.useCallback(
    (node?: HTMLElement | null) => {
      const updateWidthAndHeight = () => {
        updateWidth();
        updateHeight();
      };

      if (ref.current) {
        window.removeEventListener('resize', updateWidthAndHeight, true);
        document.removeEventListener('scroll', updateHeight, true);
      }

      if (node) {
        window.addEventListener('resize', updateWidthAndHeight, true);
        document.addEventListener('scroll', updateHeight, true);
      }

      ref.current = node;

      if (ref.current) {
        updateWidthAndHeight();
      }
    },
    [updateHeight, updateWidth],
  );

  return {
    targetRef,
    height,
    width,
  };
};

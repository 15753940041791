import React from 'react';

import { bemBlock } from '../../../modules/bem';
import { LayoutRow } from '../../layout-row/LayoutRow';

import { ItemOwnProps } from './item-props';

import './ActionsMenuSlotItem.less';

const block = bemBlock('n-actions-menu-slot-item');

export const ActionsMenuSlotItem = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentProps<'div'> & Omit<ItemOwnProps, 'trailingElement' | 'leadingIcon'>
>(({ className, children, active, destructive, onKeyDown, ...props }, ref) => {
  const handleKeyDown = React.useCallback(
    (event: Parameters<NonNullable<typeof onKeyDown>>[0]) => {
      onKeyDown?.(event);

      if (event.target instanceof HTMLInputElement && event.code !== 'Escape') {
        // Prevent dropdown actions on keypresses inside input, like arrow keys.
        event.stopPropagation();
      }
    },
    [onKeyDown],
  );

  return (
    <div
      ref={ref}
      className={block({
        extra: className,
        modifiers: { active, destructive },
      })}
      onKeyDown={handleKeyDown}
      {...props}
    >
      <LayoutRow width="100%" spacedChildren="sm" alignItems="center">
        {children}
      </LayoutRow>
    </div>
  );
});

export const featureFlags = [
  'withAdminPanel',

  'withSmoothingV2',

  'withRunGroupsV4',

  'withSectionsInDashboards',

  'withDynamicRegexpMetrics',
  'withMarkdownWidgetsDashboard',
  'withMultiChartPngDownload',
  'withLocalGlobalConfigOrder',
  'withValueListWidget',
  'withRunsArchiving',
  'withHiFiCharts',
  'withTextualGrammarCompoundSearch',
  'withShortenedMetrics',
] as const;
export type FeatureFlag = (typeof featureFlags)[number];

export type FeatureFlagConfig = `${FeatureFlag}Config`;

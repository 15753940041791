import {
  distinctUntilChanged,
  map,
  merge,
  mergeMap,
  of,
  scan,
  share,
  startWith,
  Subject,
  take,
  timer,
} from 'rxjs';

export type EditControlAction = {
  value: number;
  delay?: number;
};

export function createEditStateObservable(
  control$: Subject<EditControlAction>,
  endEditWindow$: Subject<void>,
) {
  return control$.pipe(
    mergeMap(({ value, delay }) => {
      if (delay === undefined) {
        return of(value);
      }

      return merge(timer(delay), endEditWindow$).pipe(
        take(1),
        map(() => -value),
        startWith(value),
      );
    }),
    scan((refCount, value) => Math.max(0, refCount + value), 0),
    map((refCount) => refCount > 0),
    distinctUntilChanged(),
    share(),
  );
}

export function underEditModel() {
  const control$ = new Subject<EditControlAction>();
  const endEditWindow$ = new Subject<void>();

  return {
    observable$: createEditStateObservable(control$, endEditWindow$),

    stopEdit: () => control$.next({ value: -1 }),
    startEdit: () => control$.next({ value: 1 }),

    stopEditWindow: () => endEditWindow$.next(),
    startEditWindow: (delay: number) => control$.next({ value: 1, delay }),
  };
}

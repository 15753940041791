import React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';

import { bemBlock } from '../../modules/bem';

import './SliderTrack.less';

const block = bemBlock('n-slider-track');

export const Track = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Track>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Track>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Track
    data-role="slider-track"
    ref={ref}
    className={block({ extra: className })}
    {...props}
  />
));

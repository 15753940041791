import React from 'react';

import { neptuneUrls } from '@neptune/shared/common-domain';
import { Link, LinkProps } from '@neptune/shared/venus-ui';

type ContactSupportProps<P extends object> = LinkProps<P> & {
  subject?: string;
};

export function ContactSupport<P extends object>({ subject, ...props }: ContactSupportProps<P>) {
  const href = subject
    ? `mailto:${neptuneUrls.supportEmail}?subject=${subject}`
    : `mailto:${neptuneUrls.supportEmail}`;

  return <Link {...props} href={href} />;
}

export default ContactSupport;

// Libs
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import classNames from 'classnames';

// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import ncuiPropTypes from 'neptune-core-ui/helpers/prop-types';
// eslint-disable-next-line no-restricted-imports
import Table from 'neptune-core-ui/components/table/Table';

// Module
import './ScrollableTable.less';

const ColGroupType = PropTypes.shape({
  className: PropTypes.string,
  colSpan: PropTypes.number,
  renderer: PropTypes.func,
});

const propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.object,
      head: PropTypes.object,
      pinned: PropTypes.bool,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  data: PropTypes.array,
  colGroups: PropTypes.shape({
    fixed: PropTypes.arrayOf(ColGroupType),
    scrollable: PropTypes.arrayOf(ColGroupType),
  }),
  hoverableRows: PropTypes.bool,
  rowClassName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
    PropTypes.func,
  ]),
  getRowKey: PropTypes.func,
  theme: PropTypes.oneOf(['blue', 'grey']),
  onOrderChange: ncuiPropTypes.eventHandler,
  onRemove: ncuiPropTypes.eventHandler,
  onSortChange: ncuiPropTypes.eventHandler,
  dataRole: PropTypes.string,
};

const HOVERED_ROW_CLASS = 'n-table-row--hovered';

class ScrollableTable extends React.Component {
  constructor(...args) {
    super(...args);

    this.hoveredRow = null;
    this.scrollTop = 0;
    this.scrollLeft = 0;

    this.handleScroll = this.handleScroll.bind(this);
    this.resetFixedPartHover = this.resetFixedPartHover.bind(this);
    this.resetScrollablePartHover = this.resetScrollablePartHover.bind(this);
    this.syncFixedPartHover = this.syncFixedPartHover.bind(this);
    this.syncScrollablePartHover = this.syncScrollablePartHover.bind(this);
    this.updateFillerElement = this.updateFillerElement.bind(this);
  }

  componentDidMount() {
    this.scrollableBody.addEventListener('scroll', this.handleScroll);
    this.fixedBodyContainer.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.updateFillerElement);
    this.updateFillerElement();
  }

  componentWillUnmount() {
    this.scrollableBody.removeEventListener('scroll', this.handleScroll);
    this.fixedBodyContainer.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.updateFillerElement);
  }

  componentDidUpdate() {
    this.updateFillerElement();
  }

  handleScroll(event) {
    const { scrollTop } = event.target;
    const { scrollLeft } = this.scrollableBody;

    const scrolledVertically = scrollTop !== this.scrollTop;
    const scrolledHorizontally = scrollLeft !== this.scrollLeft;

    if (!scrolledVertically && !scrolledHorizontally) {
      return;
    }

    if (scrolledVertically) {
      if (this.fixedBodyContainer !== event.target) {
        this.fixedBodyContainer.scrollTop = scrollTop;
      } else if (this.scrollableBody !== event.target) {
        this.scrollableBody.scrollTop = scrollTop;
      }

      this.scrollTop = scrollTop;
    }

    if (scrolledHorizontally) {
      this.scrollableHead.style.marginLeft = -scrollLeft + 'px';
      this.scrollLeft = scrollLeft;
    }
  }

  syncFixedPartHover(ev) {
    const row = ev.currentTarget.dataset.row;
    this.syncHover(row, this.fixedBodyContent);
  }

  syncScrollablePartHover(ev) {
    const row = ev.currentTarget.dataset.row;
    this.syncHover(row, this.scrollableBodyContent);
  }

  syncHover(row, tablePart) {
    if (!this.props.hoverableRows || row === this.hoveredRow) {
      return;
    }

    const trNode = tablePart.querySelector(`tbody tr[data-row="${row}"]`);

    if (trNode) {
      trNode.classList.add(HOVERED_ROW_CLASS);
    }

    this.hoveredRow = row;
  }

  resetFixedPartHover() {
    this.resetHoveredRow(this.fixedBodyContent);
  }

  resetScrollablePartHover() {
    this.resetHoveredRow(this.scrollableBodyContent);
  }

  resetHoveredRow(tablePart) {
    if (!this.props.hoverableRows) {
      return;
    }

    if (this.hoveredRow) {
      const trNode = tablePart.querySelector(`tbody tr[data-row="${this.hoveredRow}"]`);

      if (trNode) {
        trNode.classList.remove(HOVERED_ROW_CLASS);
      }
    }

    this.hoveredRow = null;
  }

  splitColumns(columns) {
    return columns.reduce(
      (groups, column) => {
        if (column.pinned) {
          groups.fixedColumns.push(column);
          groups.fixedColumnsWidth += column.width;
        } else {
          groups.scrollableColumns.push(column);
          groups.scrollableColumnsWidth += column.width;
        }

        return groups;
      },
      {
        fixedColumns: [],
        fixedColumnsWidth: 0,
        scrollableColumns: [],
        scrollableColumnsWidth: 0,
      },
    );
  }

  getFixedPart(columns, data, width) {
    const {
      rowClassName,
      getRowKey,
      theme,
      colGroups,
      onOrderChange,
      onRemove,
      onSortChange,
      hoverableRows,
    } = this.props;

    return (
      <div className="n-scrollable-table__fixed" data-role="table-fixed" style={{ width }}>
        <div className="n-scrollable-table__fixed-head">
          <Table
            width={width}
            columns={columns}
            colGroups={colGroups?.fixed}
            renderParts={['head']}
            theme={theme}
            onOrderChange={onOrderChange}
            onRemove={onRemove}
            onSortChange={onSortChange}
            withBorderRadius={false}
          />
        </div>

        <div className="n-scrollable-table__fixed-body">
          <div
            ref={(node) => {
              this.fixedBodyContainer = node;
            }}
            className="n-scrollable-table__fixed-body-container"
          >
            <Table
              className="n-scrollable-table__fixed-body-content"
              tableRef={(tableNode) => {
                this.fixedBodyContent = tableNode;
              }}
              width={width}
              columns={columns}
              data={data}
              renderParts={['body']}
              hoverableRows={hoverableRows}
              rowClassName={rowClassName}
              getRowKey={getRowKey}
              onRowMouseOut={this.resetFixedPartHover}
              onRowMouseOver={this.syncFixedPartHover}
              theme={theme}
            />
            <div
              ref={(node) => {
                this.fillerElement = node;
              }}
              className="n-scrollable-table__fixed-filler"
            />
          </div>
        </div>
      </div>
    );
  }

  getScrollablePart(columns, data, width) {
    const {
      rowClassName,
      getRowKey,
      theme,
      colGroups,
      onOrderChange,
      onRemove,
      onRowClick,
      onSortChange,
      hoverableRows,
      withCustomScrollbar,
    } = this.props;

    const bodyClassName = classNames('n-scrollable-table__scrollable-body', {
      'n-scrollable-table__scrollable-body--withCustomScrollbar': withCustomScrollbar,
    });

    return (
      <div className="n-scrollable-table__scrollable" data-role="table-scrollable">
        <div className="n-scrollable-table__scrollable-head ui-tour__scrollable-header">
          <Table
            tableRef={(tableNode) => {
              this.scrollableHead = tableNode;
            }}
            width={width}
            columns={columns}
            colGroups={colGroups?.scrollable}
            renderParts={['head']}
            theme={theme}
            onOrderChange={onOrderChange}
            onRemove={onRemove}
            onSortChange={onSortChange}
            withBorderRadius={false}
          />
        </div>

        <div
          ref={(node) => {
            this.scrollableBody = node;
          }}
          className={bodyClassName}
        >
          <Table
            width={width}
            columns={columns}
            data={data}
            renderParts={['body']}
            hoverableRows={hoverableRows}
            rowClassName={rowClassName}
            getRowKey={getRowKey}
            onRowMouseOut={this.resetScrollablePartHover}
            onRowMouseOver={this.syncScrollablePartHover}
            theme={theme}
            tableRef={(tableNode) => {
              this.scrollableBodyContent = tableNode;
            }}
            onRowClick={onRowClick}
            withBorderRadius={false}
          />
        </div>
      </div>
    );
  }

  updateFillerElement() {
    const diff = this.fixedBodyContainer.clientHeight - this.scrollableBody.clientHeight;
    this.fillerElement.style.height = diff + 'px';
  }

  render() {
    const { className, columns, data, dataRole } = this.props;

    const cssClasses = classNames('n-scrollable-table', className);

    const { fixedColumns, fixedColumnsWidth, scrollableColumns, scrollableColumnsWidth } =
      this.splitColumns(columns);

    const fixedPartNode = this.getFixedPart(fixedColumns, data, fixedColumnsWidth);
    const scrollablePartNode = this.getScrollablePart(
      scrollableColumns,
      data,
      scrollableColumnsWidth,
    );

    return (
      <div className={cssClasses} data-role={dataRole}>
        {fixedPartNode}
        {scrollablePartNode}
      </div>
    );
  }
}

ScrollableTable.propTypes = propTypes;

ScrollableTable.defaultProps = {
  dataRole: 'scrollable-table',
};

export default ScrollableTable;

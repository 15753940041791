/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Alias,
  AliasParams,
  AttributeQueryDTO,
  AttributeStyleSettingsParamsDTO,
  AttributesDTO,
  AttributesRequestsListDTO,
  AttributesSearchResultDTO,
  AttributesStyleSettingsResultDTO,
  AuditInfo,
  BoolAttributeDTO,
  BulkOpResultDTO,
  CheckpointDTO,
  CheckpointListDTO,
  CheckpointUpdateDTO,
  ComplexAttributeDTO,
  DashboardDTO,
  DashboardListDTO,
  DashboardVersionDTO,
  DatetimeAttributeDTO,
  DependencyOnInaccessibleProjectsErrorDTO,
  DownloadPrepareRequest,
  Experiment,
  ExperimentAttributesDTO,
  ExperimentCreationParams,
  ExperimentStats,
  FileAttributeDTO,
  FileEntry,
  FloatAttributeDTO,
  FloatSeriesAttributeDTO,
  FloatSeriesValuesDTO,
  FloatTimeSeriesValuesRequest,
  ImageSeriesValuesDTO,
  IntAttributeDTO,
  LeaderboardCsvExportParamsDTO,
  LeaderboardEntriesSearchResultDTO,
  LeaderboardFieldSuggestionDTO,
  LeaderboardViewDTO,
  LeaderboardViewListDTO,
  LeaderboardViewSetDefaultDTO,
  LeaderboardViewUpdateDTO,
  LimitedChannelValuesDTO,
  MultiSearchLeaderboardEntriesParamsDTO,
  NewCheckpointDTO,
  NewDashboardDTO,
  NewLeaderboardViewDTO,
  NewProjectChartDTO,
  NewVersionDashboardDTO,
  NotebookDTO,
  NotebookListDTO,
  NotebookUpdateDTO,
  NotebookWithNoContentDTO,
  Operation,
  OperationError,
  OperationErrorsListDTO,
  OptionalOperationError,
  PageDTODashboardVersionDTO,
  ProjectChartDTO,
  ProjectChartListDTO,
  ProjectChartUpdateDTO,
  QueryAttributeDefinitionsBodyDTO,
  QueryAttributeDefinitionsDTO,
  QueryAttributeDefinitionsPrioritizedBodyDTO,
  QueryAttributeDefinitionsPrioritizedResultDTO,
  QueryAttributeDefinitionsResultDTO,
  QueryAttributeValuesBodyDTO,
  QueryAttributeValuesResultDTO,
  QueryAttributesBodyDTO,
  QueryAttributesResultDTO,
  ReportMetadataListDTO,
  ReportVersionDTO,
  ReportVersionListDTO,
  ReportVersionMetadataDTO,
  RunListDTO,
  SearchAttributesStyleSettingsParamsDTO,
  SearchLeaderboardEntriesParamsDTO,
  SearchLeaderboardParamsDTO,
  SeriesViewListDTO,
  SeriesViewRequestDTO,
  Signal,
  StringAttributeDTO,
  StringSeriesAttributeDTO,
  StringSeriesValuesDTO,
  StringSetAttributeDTO,
  TagsSearchResultDTO,
  TimeSeriesViewRequest,
  TimeSeriesViewResponse,
  TrackingDataDTO,
  UpdateDashboardDTO,
  UpdateDashboardVersionDTO,
  UpdateTagsParams,
  UploadMetadataDTO,
} from '../models/index';
import {
    AliasFromJSON,
    AliasToJSON,
    AliasParamsFromJSON,
    AliasParamsToJSON,
    AttributeQueryDTOFromJSON,
    AttributeQueryDTOToJSON,
    AttributeStyleSettingsParamsDTOFromJSON,
    AttributeStyleSettingsParamsDTOToJSON,
    AttributesDTOFromJSON,
    AttributesDTOToJSON,
    AttributesRequestsListDTOFromJSON,
    AttributesRequestsListDTOToJSON,
    AttributesSearchResultDTOFromJSON,
    AttributesSearchResultDTOToJSON,
    AttributesStyleSettingsResultDTOFromJSON,
    AttributesStyleSettingsResultDTOToJSON,
    AuditInfoFromJSON,
    AuditInfoToJSON,
    BoolAttributeDTOFromJSON,
    BoolAttributeDTOToJSON,
    BulkOpResultDTOFromJSON,
    BulkOpResultDTOToJSON,
    CheckpointDTOFromJSON,
    CheckpointDTOToJSON,
    CheckpointListDTOFromJSON,
    CheckpointListDTOToJSON,
    CheckpointUpdateDTOFromJSON,
    CheckpointUpdateDTOToJSON,
    ComplexAttributeDTOFromJSON,
    ComplexAttributeDTOToJSON,
    DashboardDTOFromJSON,
    DashboardDTOToJSON,
    DashboardListDTOFromJSON,
    DashboardListDTOToJSON,
    DashboardVersionDTOFromJSON,
    DashboardVersionDTOToJSON,
    DatetimeAttributeDTOFromJSON,
    DatetimeAttributeDTOToJSON,
    DependencyOnInaccessibleProjectsErrorDTOFromJSON,
    DependencyOnInaccessibleProjectsErrorDTOToJSON,
    DownloadPrepareRequestFromJSON,
    DownloadPrepareRequestToJSON,
    ExperimentFromJSON,
    ExperimentToJSON,
    ExperimentAttributesDTOFromJSON,
    ExperimentAttributesDTOToJSON,
    ExperimentCreationParamsFromJSON,
    ExperimentCreationParamsToJSON,
    ExperimentStatsFromJSON,
    ExperimentStatsToJSON,
    FileAttributeDTOFromJSON,
    FileAttributeDTOToJSON,
    FileEntryFromJSON,
    FileEntryToJSON,
    FloatAttributeDTOFromJSON,
    FloatAttributeDTOToJSON,
    FloatSeriesAttributeDTOFromJSON,
    FloatSeriesAttributeDTOToJSON,
    FloatSeriesValuesDTOFromJSON,
    FloatSeriesValuesDTOToJSON,
    FloatTimeSeriesValuesRequestFromJSON,
    FloatTimeSeriesValuesRequestToJSON,
    ImageSeriesValuesDTOFromJSON,
    ImageSeriesValuesDTOToJSON,
    IntAttributeDTOFromJSON,
    IntAttributeDTOToJSON,
    LeaderboardCsvExportParamsDTOFromJSON,
    LeaderboardCsvExportParamsDTOToJSON,
    LeaderboardEntriesSearchResultDTOFromJSON,
    LeaderboardEntriesSearchResultDTOToJSON,
    LeaderboardFieldSuggestionDTOFromJSON,
    LeaderboardFieldSuggestionDTOToJSON,
    LeaderboardViewDTOFromJSON,
    LeaderboardViewDTOToJSON,
    LeaderboardViewListDTOFromJSON,
    LeaderboardViewListDTOToJSON,
    LeaderboardViewSetDefaultDTOFromJSON,
    LeaderboardViewSetDefaultDTOToJSON,
    LeaderboardViewUpdateDTOFromJSON,
    LeaderboardViewUpdateDTOToJSON,
    LimitedChannelValuesDTOFromJSON,
    LimitedChannelValuesDTOToJSON,
    MultiSearchLeaderboardEntriesParamsDTOFromJSON,
    MultiSearchLeaderboardEntriesParamsDTOToJSON,
    NewCheckpointDTOFromJSON,
    NewCheckpointDTOToJSON,
    NewDashboardDTOFromJSON,
    NewDashboardDTOToJSON,
    NewLeaderboardViewDTOFromJSON,
    NewLeaderboardViewDTOToJSON,
    NewProjectChartDTOFromJSON,
    NewProjectChartDTOToJSON,
    NewVersionDashboardDTOFromJSON,
    NewVersionDashboardDTOToJSON,
    NotebookDTOFromJSON,
    NotebookDTOToJSON,
    NotebookListDTOFromJSON,
    NotebookListDTOToJSON,
    NotebookUpdateDTOFromJSON,
    NotebookUpdateDTOToJSON,
    NotebookWithNoContentDTOFromJSON,
    NotebookWithNoContentDTOToJSON,
    OperationFromJSON,
    OperationToJSON,
    OperationErrorFromJSON,
    OperationErrorToJSON,
    OperationErrorsListDTOFromJSON,
    OperationErrorsListDTOToJSON,
    OptionalOperationErrorFromJSON,
    OptionalOperationErrorToJSON,
    PageDTODashboardVersionDTOFromJSON,
    PageDTODashboardVersionDTOToJSON,
    ProjectChartDTOFromJSON,
    ProjectChartDTOToJSON,
    ProjectChartListDTOFromJSON,
    ProjectChartListDTOToJSON,
    ProjectChartUpdateDTOFromJSON,
    ProjectChartUpdateDTOToJSON,
    QueryAttributeDefinitionsBodyDTOFromJSON,
    QueryAttributeDefinitionsBodyDTOToJSON,
    QueryAttributeDefinitionsDTOFromJSON,
    QueryAttributeDefinitionsDTOToJSON,
    QueryAttributeDefinitionsPrioritizedBodyDTOFromJSON,
    QueryAttributeDefinitionsPrioritizedBodyDTOToJSON,
    QueryAttributeDefinitionsPrioritizedResultDTOFromJSON,
    QueryAttributeDefinitionsPrioritizedResultDTOToJSON,
    QueryAttributeDefinitionsResultDTOFromJSON,
    QueryAttributeDefinitionsResultDTOToJSON,
    QueryAttributeValuesBodyDTOFromJSON,
    QueryAttributeValuesBodyDTOToJSON,
    QueryAttributeValuesResultDTOFromJSON,
    QueryAttributeValuesResultDTOToJSON,
    QueryAttributesBodyDTOFromJSON,
    QueryAttributesBodyDTOToJSON,
    QueryAttributesResultDTOFromJSON,
    QueryAttributesResultDTOToJSON,
    ReportMetadataListDTOFromJSON,
    ReportMetadataListDTOToJSON,
    ReportVersionDTOFromJSON,
    ReportVersionDTOToJSON,
    ReportVersionListDTOFromJSON,
    ReportVersionListDTOToJSON,
    ReportVersionMetadataDTOFromJSON,
    ReportVersionMetadataDTOToJSON,
    RunListDTOFromJSON,
    RunListDTOToJSON,
    SearchAttributesStyleSettingsParamsDTOFromJSON,
    SearchAttributesStyleSettingsParamsDTOToJSON,
    SearchLeaderboardEntriesParamsDTOFromJSON,
    SearchLeaderboardEntriesParamsDTOToJSON,
    SearchLeaderboardParamsDTOFromJSON,
    SearchLeaderboardParamsDTOToJSON,
    SeriesViewListDTOFromJSON,
    SeriesViewListDTOToJSON,
    SeriesViewRequestDTOFromJSON,
    SeriesViewRequestDTOToJSON,
    SignalFromJSON,
    SignalToJSON,
    StringAttributeDTOFromJSON,
    StringAttributeDTOToJSON,
    StringSeriesAttributeDTOFromJSON,
    StringSeriesAttributeDTOToJSON,
    StringSeriesValuesDTOFromJSON,
    StringSeriesValuesDTOToJSON,
    StringSetAttributeDTOFromJSON,
    StringSetAttributeDTOToJSON,
    TagsSearchResultDTOFromJSON,
    TagsSearchResultDTOToJSON,
    TimeSeriesViewRequestFromJSON,
    TimeSeriesViewRequestToJSON,
    TimeSeriesViewResponseFromJSON,
    TimeSeriesViewResponseToJSON,
    TrackingDataDTOFromJSON,
    TrackingDataDTOToJSON,
    UpdateDashboardDTOFromJSON,
    UpdateDashboardDTOToJSON,
    UpdateDashboardVersionDTOFromJSON,
    UpdateDashboardVersionDTOToJSON,
    UpdateTagsParamsFromJSON,
    UpdateTagsParamsToJSON,
    UploadMetadataDTOFromJSON,
    UploadMetadataDTOToJSON,
} from '../models/index';

export interface ArchiveRunsRequest {
    runList: RunListDTO;
}

export interface CompareCheckpointsContentRequest {
    sourceCheckpointId: string;
    targetCheckpointId: string;
}

export interface CreateAliasRequest {
    projectIdentifier: string;
    aliasToCreate: AliasParams;
}

export interface CreateCheckpointRequest {
    jupyterPath: string;
    notebookId: string;
    description?: string;
    name?: string;
}

export interface CreateDashboardRequest {
    projectIdentifier: string;
    createDashboard: NewDashboardDTO;
}

export interface CreateEmptyCheckpointRequest {
    notebookId: string;
    checkpoint: NewCheckpointDTO;
}

export interface CreateExperimentRequest {
    experimentCreationParams: ExperimentCreationParams;
    userAgent?: string;
    xNeptuneCliVersion?: string;
    xNeptuneLegacyClient?: boolean;
}

export interface CreateLeaderboardViewRequest {
    projectIdentifier: string;
    createView: NewLeaderboardViewDTO;
}

export interface CreateNewVersionOfDashboardRequest {
    dashboardId: string;
    projectIdentifier: string;
    createVersionDashboard: NewVersionDashboardDTO;
}

export interface CreateNotebookRequest {
    projectIdentifier: string;
}

export interface CreateProjectChartRequest {
    projectIdentifier: string;
    chart: NewProjectChartDTO;
}

export interface CreateReportVersionRequest {
    projectIdentifier: string;
    reportVersion: ReportVersionDTO;
    reportId?: string;
}

export interface DeleteAliasRequest {
    aliasId: string;
}

export interface DeleteAllDashboardVersionsRequest {
    branchVersionId: string;
    projectIdentifier: string;
}

export interface DeleteAllExperimentsRequest {
    projectIdentifier: string;
}

export interface DeleteAllReportVersionsRequest {
    projectIdentifier: string;
    reportId: string;
}

export interface DeleteCheckpointRequest {
    id: string;
}

export interface DeleteDashboardRequest {
    dashboardId: string;
    projectIdentifier: string;
}

export interface DeleteExperimentsRequest {
    projectIdentifier: string;
    experimentIdentifiers?: Array<string>;
}

export interface DeleteLeaderboardViewRequest {
    projectIdentifier: string;
    viewId: string;
}

export interface DeleteNotebookRequest {
    id: string;
}

export interface DeleteProjectChartRequest {
    chartId: string;
    projectIdentifier: string;
}

export interface DeleteReportVersionRequest {
    projectIdentifier: string;
    reportId: string;
    versionId: string;
}

export interface DownloadRequest {
    id: string;
}

export interface DownloadAttributeRequest {
    attribute: string;
    expectedContentDisposition?: DownloadAttributeExpectedContentDispositionEnum;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
    path?: string;
}

export interface ExecuteOperationsRequest {
    operations: Array<Operation>;
    userAgent?: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface ExperimentProjectStatsRequest {
    projectIdentifier: string;
}

export interface ExperimentUserStatsRequest {
    organization: string;
    username: string;
}

export interface FileAtomMultipartUploadFinishRequest {
    attribute: string;
    experimentIdentifier: string;
    uploadId: string;
}

export interface FileAtomMultipartUploadPartRequest {
    attribute: string;
    experimentIdentifier: string;
    uploadId: string;
    uploadPartIdx: number;
}

export interface FileAtomMultipartUploadStartRequest {
    attribute: string;
    experimentIdentifier: string;
    ext: string;
    totalLength: number;
}

export interface FileAtomUploadRequest {
    attribute: string;
    experimentIdentifier: string;
    ext: string;
}

export interface FileSetFileMultipartUploadFinishRequest {
    attribute: string;
    experimentIdentifier: string;
    subPath: string;
    uploadId: string;
}

export interface FileSetFileMultipartUploadPartRequest {
    attribute: string;
    experimentIdentifier: string;
    subPath: string;
    uploadId: string;
    uploadPartIdx: number;
}

export interface FileSetFileMultipartUploadStartRequest {
    attribute: string;
    experimentIdentifier: string;
    subPath: string;
    totalLength: number;
}

export interface FileSetFileUploadRequest {
    attribute: string;
    experimentIdentifier: string;
    subPath: string;
}

export interface GetAliasRequest {
    aliasId: string;
}

export interface GetAttributesRequest {
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetAttributesWithPathsFilterRequest {
    holderIdentifier?: string;
    holderType?: string;
    attributeQuery?: AttributeQueryDTO;
}

export interface GetAttributesWithPathsFilterProtoRequest {
    holderIdentifier: string;
    holderType?: string;
    attributeQuery?: AttributeQueryDTO;
}

export interface GetAuditInfoRequest {
    organizationIdentifier: string;
    projectIdentifier?: string;
    username?: string;
}

export interface GetBoolAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetChannelImageRequest {
    channelId: string;
    experimentId: string;
    fileName: string;
}

export interface GetChannelValuesRequest {
    channelId: string;
    limit?: number;
    offset?: number;
}

export interface GetCheckpointRequest {
    id: string;
}

export interface GetCheckpointContentRequest {
    id: string;
    expectedContentDisposition?: GetCheckpointContentExpectedContentDispositionEnum;
}

export interface GetComplexAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetDashboardRequest {
    dashboardId: string;
    projectIdentifier: string;
}

export interface GetDatetimeAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetDownloadPrepareRequestRequest {
    id: string;
}

export interface GetExperimentRequest {
    experimentId: string;
}

export interface GetExperimentAttributesRequest {
    experimentId: string;
}

export interface GetFileAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetFloatAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetFloatSeriesAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetFloatSeriesValuesRequest {
    attribute: string;
    experimentId?: string;
    includePreview?: boolean;
    limit?: number;
    lineage?: GetFloatSeriesValuesLineageEnum;
    skipToStep?: number;
}

export interface GetFloatSeriesValuesCSVRequest {
    attribute: string;
    expectedContentDisposition?: GetFloatSeriesValuesCSVExpectedContentDispositionEnum;
    experimentId?: string;
    gzipped?: boolean;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetFloatSeriesValuesProtoRequest {
    attribute: string;
    experimentId?: string;
    includePreview?: boolean;
    limit?: number;
    lineage?: GetFloatSeriesValuesProtoLineageEnum;
    skipToStep?: number;
}

export interface GetImageSeriesValueRequest {
    attribute: string;
    index: number;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetImageSeriesValuesRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
    limit?: number;
    offset?: number;
}

export interface GetImageSeriesValuesZipRequest {
    attribute: string;
    expectedContentDisposition?: GetImageSeriesValuesZipExpectedContentDispositionEnum;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetIntAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetLatestPublishedReportVersionRequest {
    projectIdentifier: string;
    reportId: string;
}

export interface GetLeaderboardViewRequest {
    projectIdentifier: string;
    viewId: string;
}

export interface GetMultipleFloatSeriesValuesCSVRequest {
    request: AttributesRequestsListDTO;
}

export interface GetMultipleFloatSeriesValuesProtoRequest {
    request: FloatTimeSeriesValuesRequest;
}

export interface GetNotebookRequest {
    id: string;
}

export interface GetProjectChartRequest {
    chartId: string;
    projectIdentifier: string;
}

export interface GetReportVersionRequest {
    projectIdentifier: string;
    reportId: string;
    versionId: string;
}

export interface GetSeriesViewRequest {
    projectIdentifier: string;
    request: Array<SeriesViewRequestDTO>;
    pointCount?: number;
}

export interface GetStringAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetStringSeriesAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetStringSeriesValuesRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
    limit?: number;
    offset?: number;
}

export interface GetStringSeriesValuesCSVRequest {
    attribute: string;
    expectedContentDisposition?: GetStringSeriesValuesCSVExpectedContentDispositionEnum;
    experimentId?: string;
    gzipped?: boolean;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetStringSetAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetTimeSeriesViewRequest {
    request: TimeSeriesViewRequest;
    projectIdentifier?: string;
}

export interface ListCheckpointsRequest {
    notebookId: string;
    id?: Array<string>;
    limit?: number;
    maxCreationTime?: Date;
    minCreationTime?: Date;
    offset?: number;
    owner?: Array<string>;
    searchTerm?: string;
}

export interface ListDashboardBranchVersionsRequest {
    projectIdentifier: string;
    versionBranch: string;
    drafts?: ListDashboardBranchVersionsDraftsEnum;
    limit?: number;
    offset?: number;
    substringQuery?: string;
}

export interface ListDashboardsRequest {
    projectIdentifier: string;
    branchIds?: Array<string>;
    dashboardType?: ListDashboardsDashboardTypeEnum;
    drafts?: ListDashboardsDraftsEnum;
    owners?: Array<string>;
}

export interface ListLeaderboardViewsRequest {
    projectIdentifier: string;
}

export interface ListNotebooksRequest {
    projectIdentifier: string;
    id?: Array<string>;
    limit?: number;
    maxCreationTime?: Date;
    minCreationTime?: Date;
    offset?: number;
    owner?: Array<string>;
    sortBy?: ListNotebooksSortByEnum;
    sortDirection?: ListNotebooksSortDirectionEnum;
}

export interface ListProjectAliasesRequest {
    projectIdentifier: string;
}

export interface ListProjectChartsRequest {
    projectIdentifier: string;
}

export interface ListReportDraftsMetadataRequest {
    owner: string;
    projectIdentifier: string;
    reportIds?: Array<string>;
}

export interface ListReportVersionsMetadataRequest {
    projectIdentifier: string;
    reportIds: Array<string>;
    searchTerm?: string;
}

export interface ListReportsMetadataRequest {
    projectIdentifier: string;
    reportIds?: Array<string>;
}

export interface ListTrackingDataRequest {
    projectIdentifiers: Array<string>;
}

export interface LsFileSetAttributeRequest {
    attribute: string;
    path: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface MultiSearchLeaderboardEntriesRequest {
    params: Array<MultiSearchLeaderboardEntriesParamsDTO>;
}

export interface PingRequest {
    experimentId?: string;
}

export interface PrepareForDownloadFileSetAttributeZipRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface PrepareForDownloadLeaderboardCsvRequest {
    projectIdentifier: string;
    csvExporting: LeaderboardCsvExportParamsDTO;
}

export interface PutAttributeStyleSettingsRequest {
    params: AttributeStyleSettingsParamsDTO;
}

export interface QueryAttributeDefinitionsRequest {
    experimentIdentifier: string;
    attributeType?: Array<string>;
}

export interface QueryAttributeDefinitionsOnlyWithDiffsRequest {
    projectIdentifier: string;
    query: QueryAttributeDefinitionsBodyDTO;
}

export interface QueryAttributeDefinitionsPrioritizedRequest {
    query: QueryAttributeDefinitionsPrioritizedBodyDTO;
}

export interface QueryAttributeDefinitionsProtoRequest {
    experimentIdentifier: string;
    attributeType?: Array<string>;
}

export interface QueryAttributeDefinitionsWithinProjectRequest {
    query: QueryAttributeDefinitionsBodyDTO;
    projectIdentifier?: string;
}

export interface QueryAttributeStyleSettingsRequest {
    params: SearchAttributesStyleSettingsParamsDTO;
}

export interface QueryAttributesWithinProjectRequest {
    query: QueryAttributesBodyDTO;
    projectIdentifier?: string;
}

export interface QueryAttributesWithinProjectProtoRequest {
    projectIdentifier: string;
    query: QueryAttributesBodyDTO;
}

export interface QueryFilteredAttributeDefinitionsRequest {
    experimentIdentifier: string;
    attributeType?: Array<string>;
    queryAttributeDefinitionsDTO?: QueryAttributeDefinitionsDTO;
}

export interface QueryUniqueAttributeValuesRequest {
    projectIdentifier: string;
    query: QueryAttributeValuesBodyDTO;
}

export interface RestoreAllExperimentsRequest {
    projectIdentifier: string;
}

export interface RestoreExperimentsRequest {
    projectIdentifier: string;
    experimentIdentifiers?: Array<string>;
}

export interface SearchLeaderboardAttributesRequest {
    projectIdentifier: string;
    params: SearchLeaderboardParamsDTO;
    attributeType?: Array<string>;
    limit?: number;
    search?: string;
    type?: Array<string>;
}

export interface SearchLeaderboardEntriesRequest {
    projectIdentifier: string;
    params: SearchLeaderboardEntriesParamsDTO;
    type?: Array<string>;
}

export interface SearchLeaderboardEntriesProtoRequest {
    projectIdentifier: string;
    params: SearchLeaderboardEntriesParamsDTO;
    type?: Array<string>;
}

export interface SearchLeaderboardTagsRequest {
    projectIdentifier: string;
    params: SearchLeaderboardParamsDTO;
    attributePath?: SearchLeaderboardTagsAttributePathEnum;
    search?: string;
    type?: Array<string>;
}

export interface SetDefaultViewRequest {
    setDefaultOperation: LeaderboardViewSetDefaultDTO;
}

export interface SignalRunsRequest {
    projectIdentifier: string;
    signal: Signal;
}

export interface SuggestionDeclineRequest {
    projectIdentifier: string;
    suggestion: LeaderboardFieldSuggestionDTO;
    viewId?: string;
}

export interface TrashExperimentsRequest {
    projectIdentifier: string;
    experimentIdentifiers?: Array<string>;
}

export interface UnarchiveRunsRequest {
    runList: RunListDTO;
}

export interface UpdateAliasRequest {
    aliasId: string;
    aliasToUpdate: AliasParams;
}

export interface UpdateCheckpointRequest {
    id: string;
    checkpointUpdate: CheckpointUpdateDTO;
}

export interface UpdateDashboardRequest {
    dashboardId: string;
    projectIdentifier: string;
    updateDashboard: UpdateDashboardDTO;
}

export interface UpdateDashboardVersionRequest {
    dashboardId: string;
    projectIdentifier: string;
    updateDashboardVersionDTO: UpdateDashboardVersionDTO;
    updateTimestamp?: boolean;
}

export interface UpdateLeaderboardViewRequest {
    projectIdentifier: string;
    viewId: string;
    updateView: LeaderboardViewUpdateDTO;
}

export interface UpdateNotebookRequest {
    id: string;
    notebookUpdate: NotebookUpdateDTO;
}

export interface UpdateProjectChartRequest {
    chartId: string;
    projectIdentifier: string;
    update: ProjectChartUpdateDTO;
}

export interface UpdateReportDraftRequest {
    projectIdentifier: string;
    publishIfUnmodifiedSince: Date;
    reportId: string;
    versionId: string;
    reportDraft: ReportVersionDTO;
}

export interface UpdateReportVersionMetadataRequest {
    projectIdentifier: string;
    publishIfUnmodifiedSince: Date;
    reportId: string;
    versionId: string;
    metadata: Omit<ReportVersionMetadataDTO, 'reportId'|'versionCreationTime'|'versionId'|'versionLastModificationTime'>;
}

export interface UpdateTags2Request {
    projectIdentifier: string;
    updateTagsParams: UpdateTagsParams;
}

export interface UploadAttributeRequest {
    attribute: string;
    ext: string;
    experimentId?: string;
}

export interface UploadCheckpointContentRequest {
    id: string;
}

export interface UploadFileSetAttributeChunkRequest {
    attribute: string;
    path: string;
    experimentId?: string;
    reset?: boolean;
}

export interface UploadFileSetAttributeTarRequest {
    attribute: string;
    experimentId?: string;
    reset?: boolean;
}

export interface ValidateExperimentsClipboardRequest {
    projectIdentifier: string;
    trashed: boolean;
    experimentIdentifiers?: Array<string>;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Archive Runs
     */
    async archiveRunsRaw(requestParameters: ArchiveRunsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters['runList'] == null) {
            throw new runtime.RequiredError(
                'runList',
                'Required parameter "runList" was null or undefined when calling archiveRuns().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/runs:archive`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RunListDTOToJSON(requestParameters['runList']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Archive Runs
     */
    async archiveRuns(requestParameters: ArchiveRunsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BulkOpResultDTO> {
        const response = await this.archiveRunsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Compare notebook checkpoint content, response is in nbdime rest api format
     */
    async compareCheckpointsContentRaw(requestParameters: CompareCheckpointsContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['sourceCheckpointId'] == null) {
            throw new runtime.RequiredError(
                'sourceCheckpointId',
                'Required parameter "sourceCheckpointId" was null or undefined when calling compareCheckpointsContent().'
            );
        }

        if (requestParameters['targetCheckpointId'] == null) {
            throw new runtime.RequiredError(
                'targetCheckpointId',
                'Required parameter "targetCheckpointId" was null or undefined when calling compareCheckpointsContent().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sourceCheckpointId'] != null) {
            queryParameters['sourceCheckpointId'] = requestParameters['sourceCheckpointId'];
        }

        if (requestParameters['targetCheckpointId'] != null) {
            queryParameters['targetCheckpointId'] = requestParameters['targetCheckpointId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/compare`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Compare notebook checkpoint content, response is in nbdime rest api format
     */
    async compareCheckpointsContent(requestParameters: CompareCheckpointsContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.compareCheckpointsContentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create alias
     */
    async createAliasRaw(requestParameters: CreateAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Alias>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling createAlias().'
            );
        }

        if (requestParameters['aliasToCreate'] == null) {
            throw new runtime.RequiredError(
                'aliasToCreate',
                'Required parameter "aliasToCreate" was null or undefined when calling createAlias().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/aliases/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AliasParamsToJSON(requestParameters['aliasToCreate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AliasFromJSON(jsonValue));
    }

    /**
     * Create alias
     */
    async createAlias(requestParameters: CreateAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Alias> {
        const response = await this.createAliasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create checkpoint
     * @deprecated
     */
    async createCheckpointRaw(requestParameters: CreateCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotebookDTO>> {
        if (requestParameters['jupyterPath'] == null) {
            throw new runtime.RequiredError(
                'jupyterPath',
                'Required parameter "jupyterPath" was null or undefined when calling createCheckpoint().'
            );
        }

        if (requestParameters['notebookId'] == null) {
            throw new runtime.RequiredError(
                'notebookId',
                'Required parameter "notebookId" was null or undefined when calling createCheckpoint().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['description'] != null) {
            queryParameters['description'] = requestParameters['description'];
        }

        if (requestParameters['jupyterPath'] != null) {
            queryParameters['jupyterPath'] = requestParameters['jupyterPath'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/{notebookId}/checkpoints`.replace(`{${"notebookId"}}`, encodeURIComponent(String(requestParameters['notebookId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotebookDTOFromJSON(jsonValue));
    }

    /**
     * Create checkpoint
     * @deprecated
     */
    async createCheckpoint(requestParameters: CreateCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotebookDTO> {
        const response = await this.createCheckpointRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create dashboard
     */
    async createDashboardRaw(requestParameters: CreateDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling createDashboard().'
            );
        }

        if (requestParameters['createDashboard'] == null) {
            throw new runtime.RequiredError(
                'createDashboard',
                'Required parameter "createDashboard" was null or undefined when calling createDashboard().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewDashboardDTOToJSON(requestParameters['createDashboard']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDTOFromJSON(jsonValue));
    }

    /**
     * Create dashboard
     */
    async createDashboard(requestParameters: CreateDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardDTO> {
        const response = await this.createDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create checkpoint
     */
    async createEmptyCheckpointRaw(requestParameters: CreateEmptyCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckpointDTO>> {
        if (requestParameters['notebookId'] == null) {
            throw new runtime.RequiredError(
                'notebookId',
                'Required parameter "notebookId" was null or undefined when calling createEmptyCheckpoint().'
            );
        }

        if (requestParameters['checkpoint'] == null) {
            throw new runtime.RequiredError(
                'checkpoint',
                'Required parameter "checkpoint" was null or undefined when calling createEmptyCheckpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/{notebookId}/checkpoints2`.replace(`{${"notebookId"}}`, encodeURIComponent(String(requestParameters['notebookId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewCheckpointDTOToJSON(requestParameters['checkpoint']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckpointDTOFromJSON(jsonValue));
    }

    /**
     * Create checkpoint
     */
    async createEmptyCheckpoint(requestParameters: CreateEmptyCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckpointDTO> {
        const response = await this.createEmptyCheckpointRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create experiment
     */
    async createExperimentRaw(requestParameters: CreateExperimentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Experiment>> {
        if (requestParameters['experimentCreationParams'] == null) {
            throw new runtime.RequiredError(
                'experimentCreationParams',
                'Required parameter "experimentCreationParams" was null or undefined when calling createExperiment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['userAgent'] != null) {
            headerParameters['User-Agent'] = String(requestParameters['userAgent']);
        }

        if (requestParameters['xNeptuneCliVersion'] != null) {
            headerParameters['X-Neptune-CliVersion'] = String(requestParameters['xNeptuneCliVersion']);
        }

        if (requestParameters['xNeptuneLegacyClient'] != null) {
            headerParameters['X-Neptune-LegacyClient'] = String(requestParameters['xNeptuneLegacyClient']);
        }

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExperimentCreationParamsToJSON(requestParameters['experimentCreationParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperimentFromJSON(jsonValue));
    }

    /**
     * Create experiment
     */
    async createExperiment(requestParameters: CreateExperimentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Experiment> {
        const response = await this.createExperimentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create view in project
     */
    async createLeaderboardViewRaw(requestParameters: CreateLeaderboardViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LeaderboardViewDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling createLeaderboardView().'
            );
        }

        if (requestParameters['createView'] == null) {
            throw new runtime.RequiredError(
                'createView',
                'Required parameter "createView" was null or undefined when calling createLeaderboardView().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/views`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewLeaderboardViewDTOToJSON(requestParameters['createView']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LeaderboardViewDTOFromJSON(jsonValue));
    }

    /**
     * Create view in project
     */
    async createLeaderboardView(requestParameters: CreateLeaderboardViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LeaderboardViewDTO> {
        const response = await this.createLeaderboardViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create new updated version of dashboard
     */
    async createNewVersionOfDashboardRaw(requestParameters: CreateNewVersionOfDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardDTO>> {
        if (requestParameters['dashboardId'] == null) {
            throw new runtime.RequiredError(
                'dashboardId',
                'Required parameter "dashboardId" was null or undefined when calling createNewVersionOfDashboard().'
            );
        }

        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling createNewVersionOfDashboard().'
            );
        }

        if (requestParameters['createVersionDashboard'] == null) {
            throw new runtime.RequiredError(
                'createVersionDashboard',
                'Required parameter "createVersionDashboard" was null or undefined when calling createNewVersionOfDashboard().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards/{dashboardId}/version`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters['dashboardId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewVersionDashboardDTOToJSON(requestParameters['createVersionDashboard']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDTOFromJSON(jsonValue));
    }

    /**
     * Create new updated version of dashboard
     */
    async createNewVersionOfDashboard(requestParameters: CreateNewVersionOfDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardDTO> {
        const response = await this.createNewVersionOfDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create notebook
     */
    async createNotebookRaw(requestParameters: CreateNotebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotebookWithNoContentDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling createNotebook().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotebookWithNoContentDTOFromJSON(jsonValue));
    }

    /**
     * Create notebook
     */
    async createNotebook(requestParameters: CreateNotebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotebookWithNoContentDTO> {
        const response = await this.createNotebookRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create chart
     */
    async createProjectChartRaw(requestParameters: CreateProjectChartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectChartDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling createProjectChart().'
            );
        }

        if (requestParameters['chart'] == null) {
            throw new runtime.RequiredError(
                'chart',
                'Required parameter "chart" was null or undefined when calling createProjectChart().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/projectCharts/{projectIdentifier}`.replace(`{${"projectIdentifier"}}`, encodeURIComponent(String(requestParameters['projectIdentifier']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewProjectChartDTOToJSON(requestParameters['chart']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectChartDTOFromJSON(jsonValue));
    }

    /**
     * Create chart
     */
    async createProjectChart(requestParameters: CreateProjectChartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectChartDTO> {
        const response = await this.createProjectChartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new report version
     */
    async createReportVersionRaw(requestParameters: CreateReportVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportVersionDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling createReportVersion().'
            );
        }

        if (requestParameters['reportVersion'] == null) {
            throw new runtime.RequiredError(
                'reportVersion',
                'Required parameter "reportVersion" was null or undefined when calling createReportVersion().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['reportId'] != null) {
            queryParameters['reportId'] = requestParameters['reportId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/reports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportVersionDTOToJSON(requestParameters['reportVersion']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportVersionDTOFromJSON(jsonValue));
    }

    /**
     * Create a new report version
     */
    async createReportVersion(requestParameters: CreateReportVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportVersionDTO> {
        const response = await this.createReportVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete alias
     */
    async deleteAliasRaw(requestParameters: DeleteAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['aliasId'] == null) {
            throw new runtime.RequiredError(
                'aliasId',
                'Required parameter "aliasId" was null or undefined when calling deleteAlias().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/aliases/{aliasId}`.replace(`{${"aliasId"}}`, encodeURIComponent(String(requestParameters['aliasId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete alias
     */
    async deleteAlias(requestParameters: DeleteAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAliasRaw(requestParameters, initOverrides);
    }

    /**
     * Delete all versions of a dashboard by branch version
     */
    async deleteAllDashboardVersionsRaw(requestParameters: DeleteAllDashboardVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['branchVersionId'] == null) {
            throw new runtime.RequiredError(
                'branchVersionId',
                'Required parameter "branchVersionId" was null or undefined when calling deleteAllDashboardVersions().'
            );
        }

        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling deleteAllDashboardVersions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards/branch/{branchVersionId}`.replace(`{${"branchVersionId"}}`, encodeURIComponent(String(requestParameters['branchVersionId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete all versions of a dashboard by branch version
     */
    async deleteAllDashboardVersions(requestParameters: DeleteAllDashboardVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAllDashboardVersionsRaw(requestParameters, initOverrides);
    }

    /**
     * Delete experiments from trash
     */
    async deleteAllExperimentsRaw(requestParameters: DeleteAllExperimentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling deleteAllExperiments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments/trash/deleteAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Delete experiments from trash
     */
    async deleteAllExperiments(requestParameters: DeleteAllExperimentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BulkOpResultDTO> {
        const response = await this.deleteAllExperimentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete all versions of a report
     */
    async deleteAllReportVersionsRaw(requestParameters: DeleteAllReportVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling deleteAllReportVersions().'
            );
        }

        if (requestParameters['reportId'] == null) {
            throw new runtime.RequiredError(
                'reportId',
                'Required parameter "reportId" was null or undefined when calling deleteAllReportVersions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/reports/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters['reportId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete all versions of a report
     */
    async deleteAllReportVersions(requestParameters: DeleteAllReportVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAllReportVersionsRaw(requestParameters, initOverrides);
    }

    /**
     * Delete checkpoint
     */
    async deleteCheckpointRaw(requestParameters: DeleteCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteCheckpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/checkpoints/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete checkpoint
     */
    async deleteCheckpoint(requestParameters: DeleteCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCheckpointRaw(requestParameters, initOverrides);
    }

    /**
     * Delete dashboard
     */
    async deleteDashboardRaw(requestParameters: DeleteDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['dashboardId'] == null) {
            throw new runtime.RequiredError(
                'dashboardId',
                'Required parameter "dashboardId" was null or undefined when calling deleteDashboard().'
            );
        }

        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling deleteDashboard().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards/{dashboardId}`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters['dashboardId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete dashboard
     */
    async deleteDashboard(requestParameters: DeleteDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDashboardRaw(requestParameters, initOverrides);
    }

    /**
     * Delete experiments from trash
     */
    async deleteExperimentsRaw(requestParameters: DeleteExperimentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling deleteExperiments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments/trash/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['experimentIdentifiers'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Delete experiments from trash
     */
    async deleteExperiments(requestParameters: DeleteExperimentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BulkOpResultDTO> {
        const response = await this.deleteExperimentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete view
     */
    async deleteLeaderboardViewRaw(requestParameters: DeleteLeaderboardViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling deleteLeaderboardView().'
            );
        }

        if (requestParameters['viewId'] == null) {
            throw new runtime.RequiredError(
                'viewId',
                'Required parameter "viewId" was null or undefined when calling deleteLeaderboardView().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/views/{viewId}`.replace(`{${"viewId"}}`, encodeURIComponent(String(requestParameters['viewId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete view
     */
    async deleteLeaderboardView(requestParameters: DeleteLeaderboardViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLeaderboardViewRaw(requestParameters, initOverrides);
    }

    /**
     * Delete notebook
     */
    async deleteNotebookRaw(requestParameters: DeleteNotebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteNotebook().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete notebook
     */
    async deleteNotebook(requestParameters: DeleteNotebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNotebookRaw(requestParameters, initOverrides);
    }

    /**
     * Delete chart
     */
    async deleteProjectChartRaw(requestParameters: DeleteProjectChartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['chartId'] == null) {
            throw new runtime.RequiredError(
                'chartId',
                'Required parameter "chartId" was null or undefined when calling deleteProjectChart().'
            );
        }

        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling deleteProjectChart().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/projectCharts/{projectIdentifier}/{chartId}`.replace(`{${"chartId"}}`, encodeURIComponent(String(requestParameters['chartId']))).replace(`{${"projectIdentifier"}}`, encodeURIComponent(String(requestParameters['projectIdentifier']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete chart
     */
    async deleteProjectChart(requestParameters: DeleteProjectChartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProjectChartRaw(requestParameters, initOverrides);
    }

    /**
     * Delete version of a report; currently only drafts can be deleted
     */
    async deleteReportVersionRaw(requestParameters: DeleteReportVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling deleteReportVersion().'
            );
        }

        if (requestParameters['reportId'] == null) {
            throw new runtime.RequiredError(
                'reportId',
                'Required parameter "reportId" was null or undefined when calling deleteReportVersion().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling deleteReportVersion().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/reports/{reportId}/versions/{versionId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters['reportId']))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters['versionId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete version of a report; currently only drafts can be deleted
     */
    async deleteReportVersion(requestParameters: DeleteReportVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteReportVersionRaw(requestParameters, initOverrides);
    }

    /**
     * Get download stream
     */
    async downloadRaw(requestParameters: DownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling download().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/download/downloadFile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get download stream
     */
    async download(requestParameters: DownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download file attribute
     */
    async downloadAttributeRaw(requestParameters: DownloadAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling downloadAttribute().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['expectedContentDisposition'] != null) {
            queryParameters['expectedContentDisposition'] = requestParameters['expectedContentDisposition'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        if (requestParameters['path'] != null) {
            queryParameters['path'] = requestParameters['path'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download file attribute
     */
    async downloadAttribute(requestParameters: DownloadAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Execute operations
     */
    async executeOperationsRaw(requestParameters: ExecuteOperationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OperationError>>> {
        if (requestParameters['operations'] == null) {
            throw new runtime.RequiredError(
                'operations',
                'Required parameter "operations" was null or undefined when calling executeOperations().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['userAgent'] != null) {
            headerParameters['User-Agent'] = String(requestParameters['userAgent']);
        }

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/operations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['operations']!.map(OperationToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OperationErrorFromJSON));
    }

    /**
     * Execute operations
     */
    async executeOperations(requestParameters: ExecuteOperationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OperationError>> {
        const response = await this.executeOperationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get experiment stats for project
     */
    async experimentProjectStatsRaw(requestParameters: ExperimentProjectStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExperimentStats>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling experimentProjectStats().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/experimentStats/project`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperimentStatsFromJSON(jsonValue));
    }

    /**
     * Get experiment stats for project
     */
    async experimentProjectStats(requestParameters: ExperimentProjectStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExperimentStats> {
        const response = await this.experimentProjectStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get experiment stats for user
     */
    async experimentUserStatsRaw(requestParameters: ExperimentUserStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExperimentStats>> {
        if (requestParameters['organization'] == null) {
            throw new runtime.RequiredError(
                'organization',
                'Required parameter "organization" was null or undefined when calling experimentUserStats().'
            );
        }

        if (requestParameters['username'] == null) {
            throw new runtime.RequiredError(
                'username',
                'Required parameter "username" was null or undefined when calling experimentUserStats().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organization'] != null) {
            queryParameters['organization'] = requestParameters['organization'];
        }

        if (requestParameters['username'] != null) {
            queryParameters['username'] = requestParameters['username'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/experimentStats/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperimentStatsFromJSON(jsonValue));
    }

    /**
     * Get experiment stats for user
     */
    async experimentUserStats(requestParameters: ExperimentUserStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExperimentStats> {
        const response = await this.experimentUserStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * fileAtomMultipartUploadFinish
     */
    async fileAtomMultipartUploadFinishRaw(requestParameters: FileAtomMultipartUploadFinishRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperationErrorsListDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling fileAtomMultipartUploadFinish().'
            );
        }

        if (requestParameters['experimentIdentifier'] == null) {
            throw new runtime.RequiredError(
                'experimentIdentifier',
                'Required parameter "experimentIdentifier" was null or undefined when calling fileAtomMultipartUploadFinish().'
            );
        }

        if (requestParameters['uploadId'] == null) {
            throw new runtime.RequiredError(
                'uploadId',
                'Required parameter "uploadId" was null or undefined when calling fileAtomMultipartUploadFinish().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentIdentifier'] != null) {
            queryParameters['experimentIdentifier'] = requestParameters['experimentIdentifier'];
        }

        if (requestParameters['uploadId'] != null) {
            queryParameters['uploadId'] = requestParameters['uploadId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/storage/file/upload/finish`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationErrorsListDTOFromJSON(jsonValue));
    }

    /**
     * fileAtomMultipartUploadFinish
     */
    async fileAtomMultipartUploadFinish(requestParameters: FileAtomMultipartUploadFinishRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperationErrorsListDTO> {
        const response = await this.fileAtomMultipartUploadFinishRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * fileAtomMultipartUploadPart
     */
    async fileAtomMultipartUploadPartRaw(requestParameters: FileAtomMultipartUploadPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperationErrorsListDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling fileAtomMultipartUploadPart().'
            );
        }

        if (requestParameters['experimentIdentifier'] == null) {
            throw new runtime.RequiredError(
                'experimentIdentifier',
                'Required parameter "experimentIdentifier" was null or undefined when calling fileAtomMultipartUploadPart().'
            );
        }

        if (requestParameters['uploadId'] == null) {
            throw new runtime.RequiredError(
                'uploadId',
                'Required parameter "uploadId" was null or undefined when calling fileAtomMultipartUploadPart().'
            );
        }

        if (requestParameters['uploadPartIdx'] == null) {
            throw new runtime.RequiredError(
                'uploadPartIdx',
                'Required parameter "uploadPartIdx" was null or undefined when calling fileAtomMultipartUploadPart().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentIdentifier'] != null) {
            queryParameters['experimentIdentifier'] = requestParameters['experimentIdentifier'];
        }

        if (requestParameters['uploadId'] != null) {
            queryParameters['uploadId'] = requestParameters['uploadId'];
        }

        if (requestParameters['uploadPartIdx'] != null) {
            queryParameters['uploadPartIdx'] = requestParameters['uploadPartIdx'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/storage/file/upload/part`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationErrorsListDTOFromJSON(jsonValue));
    }

    /**
     * fileAtomMultipartUploadPart
     */
    async fileAtomMultipartUploadPart(requestParameters: FileAtomMultipartUploadPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperationErrorsListDTO> {
        const response = await this.fileAtomMultipartUploadPartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * fileAtomMultipartUploadStart
     */
    async fileAtomMultipartUploadStartRaw(requestParameters: FileAtomMultipartUploadStartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadMetadataDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling fileAtomMultipartUploadStart().'
            );
        }

        if (requestParameters['experimentIdentifier'] == null) {
            throw new runtime.RequiredError(
                'experimentIdentifier',
                'Required parameter "experimentIdentifier" was null or undefined when calling fileAtomMultipartUploadStart().'
            );
        }

        if (requestParameters['ext'] == null) {
            throw new runtime.RequiredError(
                'ext',
                'Required parameter "ext" was null or undefined when calling fileAtomMultipartUploadStart().'
            );
        }

        if (requestParameters['totalLength'] == null) {
            throw new runtime.RequiredError(
                'totalLength',
                'Required parameter "totalLength" was null or undefined when calling fileAtomMultipartUploadStart().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentIdentifier'] != null) {
            queryParameters['experimentIdentifier'] = requestParameters['experimentIdentifier'];
        }

        if (requestParameters['ext'] != null) {
            queryParameters['ext'] = requestParameters['ext'];
        }

        if (requestParameters['totalLength'] != null) {
            queryParameters['totalLength'] = requestParameters['totalLength'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/storage/file/upload/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadMetadataDTOFromJSON(jsonValue));
    }

    /**
     * fileAtomMultipartUploadStart
     */
    async fileAtomMultipartUploadStart(requestParameters: FileAtomMultipartUploadStartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadMetadataDTO> {
        const response = await this.fileAtomMultipartUploadStartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * fileAtomUpload
     */
    async fileAtomUploadRaw(requestParameters: FileAtomUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperationErrorsListDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling fileAtomUpload().'
            );
        }

        if (requestParameters['experimentIdentifier'] == null) {
            throw new runtime.RequiredError(
                'experimentIdentifier',
                'Required parameter "experimentIdentifier" was null or undefined when calling fileAtomUpload().'
            );
        }

        if (requestParameters['ext'] == null) {
            throw new runtime.RequiredError(
                'ext',
                'Required parameter "ext" was null or undefined when calling fileAtomUpload().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentIdentifier'] != null) {
            queryParameters['experimentIdentifier'] = requestParameters['experimentIdentifier'];
        }

        if (requestParameters['ext'] != null) {
            queryParameters['ext'] = requestParameters['ext'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/storage/file/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationErrorsListDTOFromJSON(jsonValue));
    }

    /**
     * fileAtomUpload
     */
    async fileAtomUpload(requestParameters: FileAtomUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperationErrorsListDTO> {
        const response = await this.fileAtomUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * fileSetFileMultipartUploadFinish
     */
    async fileSetFileMultipartUploadFinishRaw(requestParameters: FileSetFileMultipartUploadFinishRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperationErrorsListDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling fileSetFileMultipartUploadFinish().'
            );
        }

        if (requestParameters['experimentIdentifier'] == null) {
            throw new runtime.RequiredError(
                'experimentIdentifier',
                'Required parameter "experimentIdentifier" was null or undefined when calling fileSetFileMultipartUploadFinish().'
            );
        }

        if (requestParameters['subPath'] == null) {
            throw new runtime.RequiredError(
                'subPath',
                'Required parameter "subPath" was null or undefined when calling fileSetFileMultipartUploadFinish().'
            );
        }

        if (requestParameters['uploadId'] == null) {
            throw new runtime.RequiredError(
                'uploadId',
                'Required parameter "uploadId" was null or undefined when calling fileSetFileMultipartUploadFinish().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentIdentifier'] != null) {
            queryParameters['experimentIdentifier'] = requestParameters['experimentIdentifier'];
        }

        if (requestParameters['subPath'] != null) {
            queryParameters['subPath'] = requestParameters['subPath'];
        }

        if (requestParameters['uploadId'] != null) {
            queryParameters['uploadId'] = requestParameters['uploadId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/storage/fileset/upload/finish`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationErrorsListDTOFromJSON(jsonValue));
    }

    /**
     * fileSetFileMultipartUploadFinish
     */
    async fileSetFileMultipartUploadFinish(requestParameters: FileSetFileMultipartUploadFinishRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperationErrorsListDTO> {
        const response = await this.fileSetFileMultipartUploadFinishRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * fileSetFileMultipartUploadPart
     */
    async fileSetFileMultipartUploadPartRaw(requestParameters: FileSetFileMultipartUploadPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperationErrorsListDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling fileSetFileMultipartUploadPart().'
            );
        }

        if (requestParameters['experimentIdentifier'] == null) {
            throw new runtime.RequiredError(
                'experimentIdentifier',
                'Required parameter "experimentIdentifier" was null or undefined when calling fileSetFileMultipartUploadPart().'
            );
        }

        if (requestParameters['subPath'] == null) {
            throw new runtime.RequiredError(
                'subPath',
                'Required parameter "subPath" was null or undefined when calling fileSetFileMultipartUploadPart().'
            );
        }

        if (requestParameters['uploadId'] == null) {
            throw new runtime.RequiredError(
                'uploadId',
                'Required parameter "uploadId" was null or undefined when calling fileSetFileMultipartUploadPart().'
            );
        }

        if (requestParameters['uploadPartIdx'] == null) {
            throw new runtime.RequiredError(
                'uploadPartIdx',
                'Required parameter "uploadPartIdx" was null or undefined when calling fileSetFileMultipartUploadPart().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentIdentifier'] != null) {
            queryParameters['experimentIdentifier'] = requestParameters['experimentIdentifier'];
        }

        if (requestParameters['subPath'] != null) {
            queryParameters['subPath'] = requestParameters['subPath'];
        }

        if (requestParameters['uploadId'] != null) {
            queryParameters['uploadId'] = requestParameters['uploadId'];
        }

        if (requestParameters['uploadPartIdx'] != null) {
            queryParameters['uploadPartIdx'] = requestParameters['uploadPartIdx'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/storage/fileset/upload/part`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationErrorsListDTOFromJSON(jsonValue));
    }

    /**
     * fileSetFileMultipartUploadPart
     */
    async fileSetFileMultipartUploadPart(requestParameters: FileSetFileMultipartUploadPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperationErrorsListDTO> {
        const response = await this.fileSetFileMultipartUploadPartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * fileSetFileMultipartUploadStart
     */
    async fileSetFileMultipartUploadStartRaw(requestParameters: FileSetFileMultipartUploadStartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadMetadataDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling fileSetFileMultipartUploadStart().'
            );
        }

        if (requestParameters['experimentIdentifier'] == null) {
            throw new runtime.RequiredError(
                'experimentIdentifier',
                'Required parameter "experimentIdentifier" was null or undefined when calling fileSetFileMultipartUploadStart().'
            );
        }

        if (requestParameters['subPath'] == null) {
            throw new runtime.RequiredError(
                'subPath',
                'Required parameter "subPath" was null or undefined when calling fileSetFileMultipartUploadStart().'
            );
        }

        if (requestParameters['totalLength'] == null) {
            throw new runtime.RequiredError(
                'totalLength',
                'Required parameter "totalLength" was null or undefined when calling fileSetFileMultipartUploadStart().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentIdentifier'] != null) {
            queryParameters['experimentIdentifier'] = requestParameters['experimentIdentifier'];
        }

        if (requestParameters['subPath'] != null) {
            queryParameters['subPath'] = requestParameters['subPath'];
        }

        if (requestParameters['totalLength'] != null) {
            queryParameters['totalLength'] = requestParameters['totalLength'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/storage/fileset/upload/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadMetadataDTOFromJSON(jsonValue));
    }

    /**
     * fileSetFileMultipartUploadStart
     */
    async fileSetFileMultipartUploadStart(requestParameters: FileSetFileMultipartUploadStartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadMetadataDTO> {
        const response = await this.fileSetFileMultipartUploadStartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * fileSetFileUpload
     */
    async fileSetFileUploadRaw(requestParameters: FileSetFileUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperationErrorsListDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling fileSetFileUpload().'
            );
        }

        if (requestParameters['experimentIdentifier'] == null) {
            throw new runtime.RequiredError(
                'experimentIdentifier',
                'Required parameter "experimentIdentifier" was null or undefined when calling fileSetFileUpload().'
            );
        }

        if (requestParameters['subPath'] == null) {
            throw new runtime.RequiredError(
                'subPath',
                'Required parameter "subPath" was null or undefined when calling fileSetFileUpload().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentIdentifier'] != null) {
            queryParameters['experimentIdentifier'] = requestParameters['experimentIdentifier'];
        }

        if (requestParameters['subPath'] != null) {
            queryParameters['subPath'] = requestParameters['subPath'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/storage/fileset/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationErrorsListDTOFromJSON(jsonValue));
    }

    /**
     * fileSetFileUpload
     */
    async fileSetFileUpload(requestParameters: FileSetFileUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperationErrorsListDTO> {
        const response = await this.fileSetFileUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get alias
     */
    async getAliasRaw(requestParameters: GetAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Alias>> {
        if (requestParameters['aliasId'] == null) {
            throw new runtime.RequiredError(
                'aliasId',
                'Required parameter "aliasId" was null or undefined when calling getAlias().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/aliases/{aliasId}`.replace(`{${"aliasId"}}`, encodeURIComponent(String(requestParameters['aliasId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AliasFromJSON(jsonValue));
    }

    /**
     * Get alias
     */
    async getAlias(requestParameters: GetAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Alias> {
        const response = await this.getAliasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get attributes
     */
    async getAttributesRaw(requestParameters: GetAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AttributesDTO>> {
        const queryParameters: any = {};

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/attributes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes
     */
    async getAttributes(requestParameters: GetAttributesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AttributesDTO> {
        const response = await this.getAttributesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get attributes
     */
    async getAttributesWithPathsFilterRaw(requestParameters: GetAttributesWithPathsFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AttributesDTO>> {
        const queryParameters: any = {};

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/attributes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AttributeQueryDTOToJSON(requestParameters['attributeQuery']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes
     */
    async getAttributesWithPathsFilter(requestParameters: GetAttributesWithPathsFilterRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AttributesDTO> {
        const response = await this.getAttributesWithPathsFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get attributes
     */
    async getAttributesWithPathsFilterProtoRaw(requestParameters: GetAttributesWithPathsFilterProtoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['holderIdentifier'] == null) {
            throw new runtime.RequiredError(
                'holderIdentifier',
                'Required parameter "holderIdentifier" was null or undefined when calling getAttributesWithPathsFilterProto().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/proto/attributes/attributes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AttributeQueryDTOToJSON(requestParameters['attributeQuery']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get attributes
     */
    async getAttributesWithPathsFilterProto(requestParameters: GetAttributesWithPathsFilterProtoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getAttributesWithPathsFilterProtoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get organization audit data
     */
    async getAuditInfoRaw(requestParameters: GetAuditInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuditInfo>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling getAuditInfo().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['username'] != null) {
            queryParameters['username'] = requestParameters['username'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/audit`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuditInfoFromJSON(jsonValue));
    }

    /**
     * Get organization audit data
     */
    async getAuditInfo(requestParameters: GetAuditInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuditInfo> {
        const response = await this.getAuditInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get boolean attribute
     */
    async getBoolAttributeRaw(requestParameters: GetBoolAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BoolAttributeDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getBoolAttribute().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/booleans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BoolAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get boolean attribute
     */
    async getBoolAttribute(requestParameters: GetBoolAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BoolAttributeDTO> {
        const response = await this.getBoolAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get image
     */
    async getChannelImageRaw(requestParameters: GetChannelImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['channelId'] == null) {
            throw new runtime.RequiredError(
                'channelId',
                'Required parameter "channelId" was null or undefined when calling getChannelImage().'
            );
        }

        if (requestParameters['experimentId'] == null) {
            throw new runtime.RequiredError(
                'experimentId',
                'Required parameter "experimentId" was null or undefined when calling getChannelImage().'
            );
        }

        if (requestParameters['fileName'] == null) {
            throw new runtime.RequiredError(
                'fileName',
                'Required parameter "fileName" was null or undefined when calling getChannelImage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/images/{experimentId}/{channelId}/{fileName}`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters['channelId']))).replace(`{${"experimentId"}}`, encodeURIComponent(String(requestParameters['experimentId']))).replace(`{${"fileName"}}`, encodeURIComponent(String(requestParameters['fileName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get image
     */
    async getChannelImage(requestParameters: GetChannelImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getChannelImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get values of a channel
     * @deprecated
     */
    async getChannelValuesRaw(requestParameters: GetChannelValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LimitedChannelValuesDTO>> {
        if (requestParameters['channelId'] == null) {
            throw new runtime.RequiredError(
                'channelId',
                'Required parameter "channelId" was null or undefined when calling getChannelValues().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/channels/{channelId}/values`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters['channelId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LimitedChannelValuesDTOFromJSON(jsonValue));
    }

    /**
     * Get values of a channel
     * @deprecated
     */
    async getChannelValues(requestParameters: GetChannelValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LimitedChannelValuesDTO> {
        const response = await this.getChannelValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get notebook checkpoint
     */
    async getCheckpointRaw(requestParameters: GetCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckpointDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getCheckpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/checkpoints/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckpointDTOFromJSON(jsonValue));
    }

    /**
     * Get notebook checkpoint
     */
    async getCheckpoint(requestParameters: GetCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckpointDTO> {
        const response = await this.getCheckpointRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get notebook checkpoint content
     */
    async getCheckpointContentRaw(requestParameters: GetCheckpointContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getCheckpointContent().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['expectedContentDisposition'] != null) {
            queryParameters['expectedContentDisposition'] = requestParameters['expectedContentDisposition'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/checkpoints/{id}/content`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get notebook checkpoint content
     */
    async getCheckpointContent(requestParameters: GetCheckpointContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getCheckpointContentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get object attribute
     */
    async getComplexAttributeRaw(requestParameters: GetComplexAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ComplexAttributeDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getComplexAttribute().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/complexes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ComplexAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get object attribute
     */
    async getComplexAttribute(requestParameters: GetComplexAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ComplexAttributeDTO> {
        const response = await this.getComplexAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get dashboard
     */
    async getDashboardRaw(requestParameters: GetDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardDTO>> {
        if (requestParameters['dashboardId'] == null) {
            throw new runtime.RequiredError(
                'dashboardId',
                'Required parameter "dashboardId" was null or undefined when calling getDashboard().'
            );
        }

        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling getDashboard().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards/{dashboardId}`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters['dashboardId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDTOFromJSON(jsonValue));
    }

    /**
     * Get dashboard
     */
    async getDashboard(requestParameters: GetDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardDTO> {
        const response = await this.getDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get datetime attribute
     */
    async getDatetimeAttributeRaw(requestParameters: GetDatetimeAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatetimeAttributeDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getDatetimeAttribute().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/datetimes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatetimeAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get datetime attribute
     */
    async getDatetimeAttribute(requestParameters: GetDatetimeAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatetimeAttributeDTO> {
        const response = await this.getDatetimeAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get download request
     */
    async getDownloadPrepareRequestRaw(requestParameters: GetDownloadPrepareRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DownloadPrepareRequest>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getDownloadPrepareRequest().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/download/downloadRequest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DownloadPrepareRequestFromJSON(jsonValue));
    }

    /**
     * Get download request
     */
    async getDownloadPrepareRequest(requestParameters: GetDownloadPrepareRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DownloadPrepareRequest> {
        const response = await this.getDownloadPrepareRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get experiment
     */
    async getExperimentRaw(requestParameters: GetExperimentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Experiment>> {
        if (requestParameters['experimentId'] == null) {
            throw new runtime.RequiredError(
                'experimentId',
                'Required parameter "experimentId" was null or undefined when calling getExperiment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperimentFromJSON(jsonValue));
    }

    /**
     * Get experiment
     */
    async getExperiment(requestParameters: GetExperimentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Experiment> {
        const response = await this.getExperimentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get experiment attributes
     */
    async getExperimentAttributesRaw(requestParameters: GetExperimentAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExperimentAttributesDTO>> {
        if (requestParameters['experimentId'] == null) {
            throw new runtime.RequiredError(
                'experimentId',
                'Required parameter "experimentId" was null or undefined when calling getExperimentAttributes().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperimentAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get experiment attributes
     */
    async getExperimentAttributes(requestParameters: GetExperimentAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExperimentAttributesDTO> {
        const response = await this.getExperimentAttributesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get file attribute
     */
    async getFileAttributeRaw(requestParameters: GetFileAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileAttributeDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getFileAttribute().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/files`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get file attribute
     */
    async getFileAttribute(requestParameters: GetFileAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileAttributeDTO> {
        const response = await this.getFileAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get float attribute
     */
    async getFloatAttributeRaw(requestParameters: GetFloatAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FloatAttributeDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getFloatAttribute().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/floats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FloatAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get float attribute
     */
    async getFloatAttribute(requestParameters: GetFloatAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FloatAttributeDTO> {
        const response = await this.getFloatAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get float series attribute
     */
    async getFloatSeriesAttributeRaw(requestParameters: GetFloatSeriesAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FloatSeriesAttributeDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getFloatSeriesAttribute().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/floatSeries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FloatSeriesAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get float series attribute
     */
    async getFloatSeriesAttribute(requestParameters: GetFloatSeriesAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FloatSeriesAttributeDTO> {
        const response = await this.getFloatSeriesAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get float series values
     */
    async getFloatSeriesValuesRaw(requestParameters: GetFloatSeriesValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FloatSeriesValuesDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getFloatSeriesValues().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['includePreview'] != null) {
            queryParameters['includePreview'] = requestParameters['includePreview'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['lineage'] != null) {
            queryParameters['lineage'] = requestParameters['lineage'];
        }

        if (requestParameters['skipToStep'] != null) {
            queryParameters['skipToStep'] = requestParameters['skipToStep'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/series/float`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FloatSeriesValuesDTOFromJSON(jsonValue));
    }

    /**
     * Get float series values
     */
    async getFloatSeriesValues(requestParameters: GetFloatSeriesValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FloatSeriesValuesDTO> {
        const response = await this.getFloatSeriesValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get values of a float series
     */
    async getFloatSeriesValuesCSVRaw(requestParameters: GetFloatSeriesValuesCSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getFloatSeriesValuesCSV().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['expectedContentDisposition'] != null) {
            queryParameters['expectedContentDisposition'] = requestParameters['expectedContentDisposition'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['gzipped'] != null) {
            queryParameters['gzipped'] = requestParameters['gzipped'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/floatSeries/csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get values of a float series
     */
    async getFloatSeriesValuesCSV(requestParameters: GetFloatSeriesValuesCSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getFloatSeriesValuesCSVRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get float series values
     */
    async getFloatSeriesValuesProtoRaw(requestParameters: GetFloatSeriesValuesProtoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getFloatSeriesValuesProto().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['includePreview'] != null) {
            queryParameters['includePreview'] = requestParameters['includePreview'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['lineage'] != null) {
            queryParameters['lineage'] = requestParameters['lineage'];
        }

        if (requestParameters['skipToStep'] != null) {
            queryParameters['skipToStep'] = requestParameters['skipToStep'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/proto/attributes/series/float`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get float series values
     */
    async getFloatSeriesValuesProto(requestParameters: GetFloatSeriesValuesProtoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getFloatSeriesValuesProtoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get image series single value
     */
    async getImageSeriesValueRaw(requestParameters: GetImageSeriesValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getImageSeriesValue().'
            );
        }

        if (requestParameters['index'] == null) {
            throw new runtime.RequiredError(
                'index',
                'Required parameter "index" was null or undefined when calling getImageSeriesValue().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        if (requestParameters['index'] != null) {
            queryParameters['index'] = requestParameters['index'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/series/image/single`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get image series single value
     */
    async getImageSeriesValue(requestParameters: GetImageSeriesValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getImageSeriesValueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get image series values
     */
    async getImageSeriesValuesRaw(requestParameters: GetImageSeriesValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImageSeriesValuesDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getImageSeriesValues().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/series/image`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageSeriesValuesDTOFromJSON(jsonValue));
    }

    /**
     * Get image series values
     */
    async getImageSeriesValues(requestParameters: GetImageSeriesValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImageSeriesValuesDTO> {
        const response = await this.getImageSeriesValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get values of a image series
     */
    async getImageSeriesValuesZipRaw(requestParameters: GetImageSeriesValuesZipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getImageSeriesValuesZip().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['expectedContentDisposition'] != null) {
            queryParameters['expectedContentDisposition'] = requestParameters['expectedContentDisposition'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/imageSeries/zip`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get values of a image series
     */
    async getImageSeriesValuesZip(requestParameters: GetImageSeriesValuesZipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getImageSeriesValuesZipRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get integer attribute
     */
    async getIntAttributeRaw(requestParameters: GetIntAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IntAttributeDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getIntAttribute().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/integers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get integer attribute
     */
    async getIntAttribute(requestParameters: GetIntAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IntAttributeDTO> {
        const response = await this.getIntAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a latest report version
     */
    async getLatestPublishedReportVersionRaw(requestParameters: GetLatestPublishedReportVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportVersionDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling getLatestPublishedReportVersion().'
            );
        }

        if (requestParameters['reportId'] == null) {
            throw new runtime.RequiredError(
                'reportId',
                'Required parameter "reportId" was null or undefined when calling getLatestPublishedReportVersion().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/reports/{reportId}/latestVersion`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters['reportId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportVersionDTOFromJSON(jsonValue));
    }

    /**
     * Retrieve a latest report version
     */
    async getLatestPublishedReportVersion(requestParameters: GetLatestPublishedReportVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportVersionDTO> {
        const response = await this.getLatestPublishedReportVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get view
     */
    async getLeaderboardViewRaw(requestParameters: GetLeaderboardViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LeaderboardViewDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling getLeaderboardView().'
            );
        }

        if (requestParameters['viewId'] == null) {
            throw new runtime.RequiredError(
                'viewId',
                'Required parameter "viewId" was null or undefined when calling getLeaderboardView().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/views/{viewId}`.replace(`{${"viewId"}}`, encodeURIComponent(String(requestParameters['viewId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LeaderboardViewDTOFromJSON(jsonValue));
    }

    /**
     * Get view
     */
    async getLeaderboardView(requestParameters: GetLeaderboardViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LeaderboardViewDTO> {
        const response = await this.getLeaderboardViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get multiple float series in single ZIP
     */
    async getMultipleFloatSeriesValuesCSVRaw(requestParameters: GetMultipleFloatSeriesValuesCSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DownloadPrepareRequest>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling getMultipleFloatSeriesValuesCSV().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/floatSeries/csv/multiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AttributesRequestsListDTOToJSON(requestParameters['request']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DownloadPrepareRequestFromJSON(jsonValue));
    }

    /**
     * Get multiple float series in single ZIP
     */
    async getMultipleFloatSeriesValuesCSV(requestParameters: GetMultipleFloatSeriesValuesCSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DownloadPrepareRequest> {
        const response = await this.getMultipleFloatSeriesValuesCSVRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get multiple float series values
     */
    async getMultipleFloatSeriesValuesProtoRaw(requestParameters: GetMultipleFloatSeriesValuesProtoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling getMultipleFloatSeriesValuesProto().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/proto/attributes/series/float`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FloatTimeSeriesValuesRequestToJSON(requestParameters['request']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get multiple float series values
     */
    async getMultipleFloatSeriesValuesProto(requestParameters: GetMultipleFloatSeriesValuesProtoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getMultipleFloatSeriesValuesProtoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get notebook
     */
    async getNotebookRaw(requestParameters: GetNotebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotebookDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getNotebook().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotebookDTOFromJSON(jsonValue));
    }

    /**
     * Get notebook
     */
    async getNotebook(requestParameters: GetNotebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotebookDTO> {
        const response = await this.getNotebookRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get chart
     */
    async getProjectChartRaw(requestParameters: GetProjectChartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectChartDTO>> {
        if (requestParameters['chartId'] == null) {
            throw new runtime.RequiredError(
                'chartId',
                'Required parameter "chartId" was null or undefined when calling getProjectChart().'
            );
        }

        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling getProjectChart().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/projectCharts/{projectIdentifier}/{chartId}`.replace(`{${"chartId"}}`, encodeURIComponent(String(requestParameters['chartId']))).replace(`{${"projectIdentifier"}}`, encodeURIComponent(String(requestParameters['projectIdentifier']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectChartDTOFromJSON(jsonValue));
    }

    /**
     * Get chart
     */
    async getProjectChart(requestParameters: GetProjectChartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectChartDTO> {
        const response = await this.getProjectChartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a report version
     */
    async getReportVersionRaw(requestParameters: GetReportVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportVersionDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling getReportVersion().'
            );
        }

        if (requestParameters['reportId'] == null) {
            throw new runtime.RequiredError(
                'reportId',
                'Required parameter "reportId" was null or undefined when calling getReportVersion().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling getReportVersion().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/reports/{reportId}/versions/{versionId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters['reportId']))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters['versionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportVersionDTOFromJSON(jsonValue));
    }

    /**
     * Retrieve a report version
     */
    async getReportVersion(requestParameters: GetReportVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportVersionDTO> {
        const response = await this.getReportVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get series view by channel UUIDs
     */
    async getSeriesViewRaw(requestParameters: GetSeriesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SeriesViewListDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling getSeriesView().'
            );
        }

        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling getSeriesView().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pointCount'] != null) {
            queryParameters['pointCount'] = requestParameters['pointCount'];
        }

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/channels/view`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['request']!.map(SeriesViewRequestDTOToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SeriesViewListDTOFromJSON(jsonValue));
    }

    /**
     * Get series view by channel UUIDs
     */
    async getSeriesView(requestParameters: GetSeriesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SeriesViewListDTO> {
        const response = await this.getSeriesViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get string attribute
     */
    async getStringAttributeRaw(requestParameters: GetStringAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringAttributeDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getStringAttribute().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/strings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get string attribute
     */
    async getStringAttribute(requestParameters: GetStringAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringAttributeDTO> {
        const response = await this.getStringAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get string series attribute
     */
    async getStringSeriesAttributeRaw(requestParameters: GetStringSeriesAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringSeriesAttributeDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getStringSeriesAttribute().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/stringSeries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringSeriesAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get string series attribute
     */
    async getStringSeriesAttribute(requestParameters: GetStringSeriesAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringSeriesAttributeDTO> {
        const response = await this.getStringSeriesAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get string series values
     */
    async getStringSeriesValuesRaw(requestParameters: GetStringSeriesValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringSeriesValuesDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getStringSeriesValues().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/series/string`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringSeriesValuesDTOFromJSON(jsonValue));
    }

    /**
     * Get string series values
     */
    async getStringSeriesValues(requestParameters: GetStringSeriesValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringSeriesValuesDTO> {
        const response = await this.getStringSeriesValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get values of a string series
     */
    async getStringSeriesValuesCSVRaw(requestParameters: GetStringSeriesValuesCSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getStringSeriesValuesCSV().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['expectedContentDisposition'] != null) {
            queryParameters['expectedContentDisposition'] = requestParameters['expectedContentDisposition'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['gzipped'] != null) {
            queryParameters['gzipped'] = requestParameters['gzipped'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/stringSeries/csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get values of a string series
     */
    async getStringSeriesValuesCSV(requestParameters: GetStringSeriesValuesCSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getStringSeriesValuesCSVRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get string set attribute
     */
    async getStringSetAttributeRaw(requestParameters: GetStringSetAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringSetAttributeDTO>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling getStringSetAttribute().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/stringSets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringSetAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get string set attribute
     */
    async getStringSetAttribute(requestParameters: GetStringSetAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringSetAttributeDTO> {
        const response = await this.getStringSetAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get series view
     */
    async getTimeSeriesViewRaw(requestParameters: GetTimeSeriesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TimeSeriesViewResponse>> {
        if (requestParameters['request'] == null) {
            throw new runtime.RequiredError(
                'request',
                'Required parameter "request" was null or undefined when calling getTimeSeriesView().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/timeseries/view`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TimeSeriesViewRequestToJSON(requestParameters['request']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TimeSeriesViewResponseFromJSON(jsonValue));
    }

    /**
     * Get series view
     */
    async getTimeSeriesView(requestParameters: GetTimeSeriesViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TimeSeriesViewResponse> {
        const response = await this.getTimeSeriesViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get notebook checkpoints
     */
    async listCheckpointsRaw(requestParameters: ListCheckpointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckpointListDTO>> {
        if (requestParameters['notebookId'] == null) {
            throw new runtime.RequiredError(
                'notebookId',
                'Required parameter "notebookId" was null or undefined when calling listCheckpoints().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['maxCreationTime'] != null) {
            queryParameters['maxCreationTime'] = (requestParameters['maxCreationTime'] as any).toISOString();
        }

        if (requestParameters['minCreationTime'] != null) {
            queryParameters['minCreationTime'] = (requestParameters['minCreationTime'] as any).toISOString();
        }

        if (requestParameters['notebookId'] != null) {
            queryParameters['notebookId'] = requestParameters['notebookId'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['owner'] != null) {
            queryParameters['owner'] = requestParameters['owner'];
        }

        if (requestParameters['searchTerm'] != null) {
            queryParameters['searchTerm'] = requestParameters['searchTerm'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/checkpoints`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckpointListDTOFromJSON(jsonValue));
    }

    /**
     * Get notebook checkpoints
     */
    async listCheckpoints(requestParameters: ListCheckpointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckpointListDTO> {
        const response = await this.listCheckpointsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List dashboard versions of a branch
     */
    async listDashboardBranchVersionsRaw(requestParameters: ListDashboardBranchVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTODashboardVersionDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling listDashboardBranchVersions().'
            );
        }

        if (requestParameters['versionBranch'] == null) {
            throw new runtime.RequiredError(
                'versionBranch',
                'Required parameter "versionBranch" was null or undefined when calling listDashboardBranchVersions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['drafts'] != null) {
            queryParameters['drafts'] = requestParameters['drafts'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['substringQuery'] != null) {
            queryParameters['substringQuery'] = requestParameters['substringQuery'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards/list/versions/{versionBranch}`.replace(`{${"versionBranch"}}`, encodeURIComponent(String(requestParameters['versionBranch']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTODashboardVersionDTOFromJSON(jsonValue));
    }

    /**
     * List dashboard versions of a branch
     */
    async listDashboardBranchVersions(requestParameters: ListDashboardBranchVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTODashboardVersionDTO> {
        const response = await this.listDashboardBranchVersionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List latest versions of dashboard
     */
    async listDashboardsRaw(requestParameters: ListDashboardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardListDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling listDashboards().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['branchIds'] != null) {
            queryParameters['branchIds'] = requestParameters['branchIds'];
        }

        if (requestParameters['dashboardType'] != null) {
            queryParameters['dashboardType'] = requestParameters['dashboardType'];
        }

        if (requestParameters['drafts'] != null) {
            queryParameters['drafts'] = requestParameters['drafts'];
        }

        if (requestParameters['owners'] != null) {
            queryParameters['owners'] = requestParameters['owners'];
        }

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardListDTOFromJSON(jsonValue));
    }

    /**
     * List latest versions of dashboard
     */
    async listDashboards(requestParameters: ListDashboardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardListDTO> {
        const response = await this.listDashboardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List views in project
     */
    async listLeaderboardViewsRaw(requestParameters: ListLeaderboardViewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LeaderboardViewListDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling listLeaderboardViews().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/views`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LeaderboardViewListDTOFromJSON(jsonValue));
    }

    /**
     * List views in project
     */
    async listLeaderboardViews(requestParameters: ListLeaderboardViewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LeaderboardViewListDTO> {
        const response = await this.listLeaderboardViewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get notebooks
     */
    async listNotebooksRaw(requestParameters: ListNotebooksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotebookListDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling listNotebooks().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['maxCreationTime'] != null) {
            queryParameters['maxCreationTime'] = (requestParameters['maxCreationTime'] as any).toISOString();
        }

        if (requestParameters['minCreationTime'] != null) {
            queryParameters['minCreationTime'] = (requestParameters['minCreationTime'] as any).toISOString();
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['owner'] != null) {
            queryParameters['owner'] = requestParameters['owner'];
        }

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['sortBy'] != null) {
            queryParameters['sortBy'] = requestParameters['sortBy'];
        }

        if (requestParameters['sortDirection'] != null) {
            queryParameters['sortDirection'] = requestParameters['sortDirection'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotebookListDTOFromJSON(jsonValue));
    }

    /**
     * Get notebooks
     */
    async listNotebooks(requestParameters: ListNotebooksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotebookListDTO> {
        const response = await this.listNotebooksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List project aliases
     */
    async listProjectAliasesRaw(requestParameters: ListProjectAliasesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Alias>>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling listProjectAliases().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/aliases/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AliasFromJSON));
    }

    /**
     * List project aliases
     */
    async listProjectAliases(requestParameters: ListProjectAliasesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Alias>> {
        const response = await this.listProjectAliasesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * listCharts
     */
    async listProjectChartsRaw(requestParameters: ListProjectChartsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectChartListDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling listProjectCharts().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/projectCharts/{projectIdentifier}`.replace(`{${"projectIdentifier"}}`, encodeURIComponent(String(requestParameters['projectIdentifier']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectChartListDTOFromJSON(jsonValue));
    }

    /**
     * listCharts
     */
    async listProjectCharts(requestParameters: ListProjectChartsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectChartListDTO> {
        const response = await this.listProjectChartsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List drafts of reports belonging to a particular user
     */
    async listReportDraftsMetadataRaw(requestParameters: ListReportDraftsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportVersionListDTO>> {
        if (requestParameters['owner'] == null) {
            throw new runtime.RequiredError(
                'owner',
                'Required parameter "owner" was null or undefined when calling listReportDraftsMetadata().'
            );
        }

        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling listReportDraftsMetadata().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['owner'] != null) {
            queryParameters['owner'] = requestParameters['owner'];
        }

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['reportIds'] != null) {
            queryParameters['reportIds'] = requestParameters['reportIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/reports/versions/drafts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportVersionListDTOFromJSON(jsonValue));
    }

    /**
     * List drafts of reports belonging to a particular user
     */
    async listReportDraftsMetadata(requestParameters: ListReportDraftsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportVersionListDTO> {
        const response = await this.listReportDraftsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List report versions\' metadata
     */
    async listReportVersionsMetadataRaw(requestParameters: ListReportVersionsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportVersionListDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling listReportVersionsMetadata().'
            );
        }

        if (requestParameters['reportIds'] == null) {
            throw new runtime.RequiredError(
                'reportIds',
                'Required parameter "reportIds" was null or undefined when calling listReportVersionsMetadata().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['reportIds'] != null) {
            queryParameters['reportIds'] = requestParameters['reportIds'];
        }

        if (requestParameters['searchTerm'] != null) {
            queryParameters['searchTerm'] = requestParameters['searchTerm'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/reports/versions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportVersionListDTOFromJSON(jsonValue));
    }

    /**
     * List report versions\' metadata
     */
    async listReportVersionsMetadata(requestParameters: ListReportVersionsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportVersionListDTO> {
        const response = await this.listReportVersionsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List reports\' metadata
     */
    async listReportsMetadataRaw(requestParameters: ListReportsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportMetadataListDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling listReportsMetadata().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['reportIds'] != null) {
            queryParameters['reportIds'] = requestParameters['reportIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/reports/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportMetadataListDTOFromJSON(jsonValue));
    }

    /**
     * List reports\' metadata
     */
    async listReportsMetadata(requestParameters: ListReportsMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportMetadataListDTO> {
        const response = await this.listReportsMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List tracking data
     */
    async listTrackingDataRaw(requestParameters: ListTrackingDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TrackingDataDTO>>> {
        if (requestParameters['projectIdentifiers'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifiers',
                'Required parameter "projectIdentifiers" was null or undefined when calling listTrackingData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/tracking`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['projectIdentifiers'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrackingDataDTOFromJSON));
    }

    /**
     * List tracking data
     */
    async listTrackingData(requestParameters: ListTrackingDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TrackingDataDTO>> {
        const response = await this.listTrackingDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List files in attribute of type File Set
     */
    async lsFileSetAttributeRaw(requestParameters: LsFileSetAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileEntry>>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling lsFileSetAttribute().'
            );
        }

        if (requestParameters['path'] == null) {
            throw new runtime.RequiredError(
                'path',
                'Required parameter "path" was null or undefined when calling lsFileSetAttribute().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        if (requestParameters['path'] != null) {
            queryParameters['path'] = requestParameters['path'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/ls`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileEntryFromJSON));
    }

    /**
     * List files in attribute of type File Set
     */
    async lsFileSetAttribute(requestParameters: LsFileSetAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileEntry>> {
        const response = await this.lsFileSetAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Multi search leaderboard entries
     */
    async multiSearchLeaderboardEntriesRaw(requestParameters: MultiSearchLeaderboardEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LeaderboardEntriesSearchResultDTO>>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling multiSearchLeaderboardEntries().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/entries/msearch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['params']!.map(MultiSearchLeaderboardEntriesParamsDTOToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LeaderboardEntriesSearchResultDTOFromJSON));
    }

    /**
     * Multi search leaderboard entries
     */
    async multiSearchLeaderboardEntries(requestParameters: MultiSearchLeaderboardEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LeaderboardEntriesSearchResultDTO>> {
        const response = await this.multiSearchLeaderboardEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Ping experiment
     */
    async pingRaw(requestParameters: PingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/ping`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Ping experiment
     */
    async ping(requestParameters: PingRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pingRaw(requestParameters, initOverrides);
    }

    /**
     * Creates fileset attribute\'s zip download request
     */
    async prepareForDownloadFileSetAttributeZipRaw(requestParameters: PrepareForDownloadFileSetAttributeZipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DownloadPrepareRequest>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling prepareForDownloadFileSetAttributeZip().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['holderIdentifier'] != null) {
            queryParameters['holderIdentifier'] = requestParameters['holderIdentifier'];
        }

        if (requestParameters['holderType'] != null) {
            queryParameters['holderType'] = requestParameters['holderType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/downloadFileSetZipRequest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DownloadPrepareRequestFromJSON(jsonValue));
    }

    /**
     * Creates fileset attribute\'s zip download request
     */
    async prepareForDownloadFileSetAttributeZip(requestParameters: PrepareForDownloadFileSetAttributeZipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DownloadPrepareRequest> {
        const response = await this.prepareForDownloadFileSetAttributeZipRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Prepare download leaderboard as CSV
     */
    async prepareForDownloadLeaderboardCsvRaw(requestParameters: PrepareForDownloadLeaderboardCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DownloadPrepareRequest>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling prepareForDownloadLeaderboardCsv().'
            );
        }

        if (requestParameters['csvExporting'] == null) {
            throw new runtime.RequiredError(
                'csvExporting',
                'Required parameter "csvExporting" was null or undefined when calling prepareForDownloadLeaderboardCsv().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/prepareForDownloadLeaderboardCsv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LeaderboardCsvExportParamsDTOToJSON(requestParameters['csvExporting']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DownloadPrepareRequestFromJSON(jsonValue));
    }

    /**
     * Prepare download leaderboard as CSV
     */
    async prepareForDownloadLeaderboardCsv(requestParameters: PrepareForDownloadLeaderboardCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DownloadPrepareRequest> {
        const response = await this.prepareForDownloadLeaderboardCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates or updates attribute style settings
     */
    async putAttributeStyleSettingsRaw(requestParameters: PutAttributeStyleSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling putAttributeStyleSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/style/putAttributeStyleSettings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AttributeStyleSettingsParamsDTOToJSON(requestParameters['params']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates or updates attribute style settings
     */
    async putAttributeStyleSettings(requestParameters: PutAttributeStyleSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putAttributeStyleSettingsRaw(requestParameters, initOverrides);
    }

    /**
     * [deprecated] Queries attribute definitions of an experiment
     * @deprecated
     */
    async queryAttributeDefinitionsRaw(requestParameters: QueryAttributeDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AttributesSearchResultDTO>> {
        if (requestParameters['experimentIdentifier'] == null) {
            throw new runtime.RequiredError(
                'experimentIdentifier',
                'Required parameter "experimentIdentifier" was null or undefined when calling queryAttributeDefinitions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attributeType'] != null) {
            queryParameters['attributeType'] = requestParameters['attributeType'];
        }

        if (requestParameters['experimentIdentifier'] != null) {
            queryParameters['experimentIdentifier'] = requestParameters['experimentIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/queryAttributeDefinitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttributesSearchResultDTOFromJSON(jsonValue));
    }

    /**
     * [deprecated] Queries attribute definitions of an experiment
     * @deprecated
     */
    async queryAttributeDefinitions(requestParameters: QueryAttributeDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AttributesSearchResultDTO> {
        const response = await this.queryAttributeDefinitionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Queries attribute definitions with different values
     */
    async queryAttributeDefinitionsOnlyWithDiffsRaw(requestParameters: QueryAttributeDefinitionsOnlyWithDiffsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryAttributeDefinitionsResultDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling queryAttributeDefinitionsOnlyWithDiffs().'
            );
        }

        if (requestParameters['query'] == null) {
            throw new runtime.RequiredError(
                'query',
                'Required parameter "query" was null or undefined when calling queryAttributeDefinitionsOnlyWithDiffs().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/attributes/definitions/query-diffs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryAttributeDefinitionsBodyDTOToJSON(requestParameters['query']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryAttributeDefinitionsResultDTOFromJSON(jsonValue));
    }

    /**
     * Queries attribute definitions with different values
     */
    async queryAttributeDefinitionsOnlyWithDiffs(requestParameters: QueryAttributeDefinitionsOnlyWithDiffsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryAttributeDefinitionsResultDTO> {
        const response = await this.queryAttributeDefinitionsOnlyWithDiffsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Queries prioritized attribute definitions, definitions that fulfill the priority queryare returned as first.
     */
    async queryAttributeDefinitionsPrioritizedRaw(requestParameters: QueryAttributeDefinitionsPrioritizedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryAttributeDefinitionsPrioritizedResultDTO>> {
        if (requestParameters['query'] == null) {
            throw new runtime.RequiredError(
                'query',
                'Required parameter "query" was null or undefined when calling queryAttributeDefinitionsPrioritized().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/attributes/definitions/prioritized`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryAttributeDefinitionsPrioritizedBodyDTOToJSON(requestParameters['query']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryAttributeDefinitionsPrioritizedResultDTOFromJSON(jsonValue));
    }

    /**
     * Queries prioritized attribute definitions, definitions that fulfill the priority queryare returned as first.
     */
    async queryAttributeDefinitionsPrioritized(requestParameters: QueryAttributeDefinitionsPrioritizedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryAttributeDefinitionsPrioritizedResultDTO> {
        const response = await this.queryAttributeDefinitionsPrioritizedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Queries attribute definitions of an experiment
     */
    async queryAttributeDefinitionsProtoRaw(requestParameters: QueryAttributeDefinitionsProtoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['experimentIdentifier'] == null) {
            throw new runtime.RequiredError(
                'experimentIdentifier',
                'Required parameter "experimentIdentifier" was null or undefined when calling queryAttributeDefinitionsProto().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attributeType'] != null) {
            queryParameters['attributeType'] = requestParameters['attributeType'];
        }

        if (requestParameters['experimentIdentifier'] != null) {
            queryParameters['experimentIdentifier'] = requestParameters['experimentIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/proto/attributes/queryAttributeDefinitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Queries attribute definitions of an experiment
     */
    async queryAttributeDefinitionsProto(requestParameters: QueryAttributeDefinitionsProtoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.queryAttributeDefinitionsProtoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Queries attribute definitions
     */
    async queryAttributeDefinitionsWithinProjectRaw(requestParameters: QueryAttributeDefinitionsWithinProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryAttributeDefinitionsResultDTO>> {
        if (requestParameters['query'] == null) {
            throw new runtime.RequiredError(
                'query',
                'Required parameter "query" was null or undefined when calling queryAttributeDefinitionsWithinProject().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/attributes/definitions/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryAttributeDefinitionsBodyDTOToJSON(requestParameters['query']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryAttributeDefinitionsResultDTOFromJSON(jsonValue));
    }

    /**
     * Queries attribute definitions
     */
    async queryAttributeDefinitionsWithinProject(requestParameters: QueryAttributeDefinitionsWithinProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryAttributeDefinitionsResultDTO> {
        const response = await this.queryAttributeDefinitionsWithinProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Queries attributes style settings
     */
    async queryAttributeStyleSettingsRaw(requestParameters: QueryAttributeStyleSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AttributesStyleSettingsResultDTO>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling queryAttributeStyleSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/style/queryAttributeStyleSettings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchAttributesStyleSettingsParamsDTOToJSON(requestParameters['params']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttributesStyleSettingsResultDTOFromJSON(jsonValue));
    }

    /**
     * Queries attributes style settings
     */
    async queryAttributeStyleSettings(requestParameters: QueryAttributeStyleSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AttributesStyleSettingsResultDTO> {
        const response = await this.queryAttributeStyleSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Queries attributes
     */
    async queryAttributesWithinProjectRaw(requestParameters: QueryAttributesWithinProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryAttributesResultDTO>> {
        if (requestParameters['query'] == null) {
            throw new runtime.RequiredError(
                'query',
                'Required parameter "query" was null or undefined when calling queryAttributesWithinProject().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/attributes/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryAttributesBodyDTOToJSON(requestParameters['query']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryAttributesResultDTOFromJSON(jsonValue));
    }

    /**
     * Queries attributes
     */
    async queryAttributesWithinProject(requestParameters: QueryAttributesWithinProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryAttributesResultDTO> {
        const response = await this.queryAttributesWithinProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Queries attributes
     */
    async queryAttributesWithinProjectProtoRaw(requestParameters: QueryAttributesWithinProjectProtoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling queryAttributesWithinProjectProto().'
            );
        }

        if (requestParameters['query'] == null) {
            throw new runtime.RequiredError(
                'query',
                'Required parameter "query" was null or undefined when calling queryAttributesWithinProjectProto().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/proto/leaderboard/attributes/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryAttributesBodyDTOToJSON(requestParameters['query']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Queries attributes
     */
    async queryAttributesWithinProjectProto(requestParameters: QueryAttributesWithinProjectProtoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.queryAttributesWithinProjectProtoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Queries attribute definitions of an experiment
     */
    async queryFilteredAttributeDefinitionsRaw(requestParameters: QueryFilteredAttributeDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AttributesSearchResultDTO>> {
        if (requestParameters['experimentIdentifier'] == null) {
            throw new runtime.RequiredError(
                'experimentIdentifier',
                'Required parameter "experimentIdentifier" was null or undefined when calling queryFilteredAttributeDefinitions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attributeType'] != null) {
            queryParameters['attributeType'] = requestParameters['attributeType'];
        }

        if (requestParameters['experimentIdentifier'] != null) {
            queryParameters['experimentIdentifier'] = requestParameters['experimentIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/queryAttributeDefinitions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryAttributeDefinitionsDTOToJSON(requestParameters['queryAttributeDefinitionsDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttributesSearchResultDTOFromJSON(jsonValue));
    }

    /**
     * Queries attribute definitions of an experiment
     */
    async queryFilteredAttributeDefinitions(requestParameters: QueryFilteredAttributeDefinitionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AttributesSearchResultDTO> {
        const response = await this.queryFilteredAttributeDefinitionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Currently only String attribute type is supported
     * Queries unique attribute values within a project
     */
    async queryUniqueAttributeValuesRaw(requestParameters: QueryUniqueAttributeValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryAttributeValuesResultDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling queryUniqueAttributeValues().'
            );
        }

        if (requestParameters['query'] == null) {
            throw new runtime.RequiredError(
                'query',
                'Required parameter "query" was null or undefined when calling queryUniqueAttributeValues().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/attributes/values/unique`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryAttributeValuesBodyDTOToJSON(requestParameters['query']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryAttributeValuesResultDTOFromJSON(jsonValue));
    }

    /**
     * Currently only String attribute type is supported
     * Queries unique attribute values within a project
     */
    async queryUniqueAttributeValues(requestParameters: QueryUniqueAttributeValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryAttributeValuesResultDTO> {
        const response = await this.queryUniqueAttributeValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Restore experiments from trash
     */
    async restoreAllExperimentsRaw(requestParameters: RestoreAllExperimentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling restoreAllExperiments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments/trash/restoreAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Restore experiments from trash
     */
    async restoreAllExperiments(requestParameters: RestoreAllExperimentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BulkOpResultDTO> {
        const response = await this.restoreAllExperimentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Restore experiments from trash
     */
    async restoreExperimentsRaw(requestParameters: RestoreExperimentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling restoreExperiments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments/trash/restore`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['experimentIdentifiers'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Restore experiments from trash
     */
    async restoreExperiments(requestParameters: RestoreExperimentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BulkOpResultDTO> {
        const response = await this.restoreExperimentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List definitions of all attributes in project
     */
    async searchLeaderboardAttributesRaw(requestParameters: SearchLeaderboardAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AttributesSearchResultDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling searchLeaderboardAttributes().'
            );
        }

        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling searchLeaderboardAttributes().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attributeType'] != null) {
            queryParameters['attributeType'] = requestParameters['attributeType'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/attributes/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchLeaderboardParamsDTOToJSON(requestParameters['params']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttributesSearchResultDTOFromJSON(jsonValue));
    }

    /**
     * List definitions of all attributes in project
     */
    async searchLeaderboardAttributes(requestParameters: SearchLeaderboardAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AttributesSearchResultDTO> {
        const response = await this.searchLeaderboardAttributesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get leaderboard
     */
    async searchLeaderboardEntriesRaw(requestParameters: SearchLeaderboardEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LeaderboardEntriesSearchResultDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling searchLeaderboardEntries().'
            );
        }

        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling searchLeaderboardEntries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/entries/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchLeaderboardEntriesParamsDTOToJSON(requestParameters['params']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LeaderboardEntriesSearchResultDTOFromJSON(jsonValue));
    }

    /**
     * Get leaderboard
     */
    async searchLeaderboardEntries(requestParameters: SearchLeaderboardEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LeaderboardEntriesSearchResultDTO> {
        const response = await this.searchLeaderboardEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get leaderboard
     */
    async searchLeaderboardEntriesProtoRaw(requestParameters: SearchLeaderboardEntriesProtoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling searchLeaderboardEntriesProto().'
            );
        }

        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling searchLeaderboardEntriesProto().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/proto/leaderboard/entries/search/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchLeaderboardEntriesParamsDTOToJSON(requestParameters['params']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get leaderboard
     */
    async searchLeaderboardEntriesProto(requestParameters: SearchLeaderboardEntriesProtoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.searchLeaderboardEntriesProtoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get tags defined in experiments within project
     */
    async searchLeaderboardTagsRaw(requestParameters: SearchLeaderboardTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagsSearchResultDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling searchLeaderboardTags().'
            );
        }

        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling searchLeaderboardTags().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attributePath'] != null) {
            queryParameters['attributePath'] = requestParameters['attributePath'];
        }

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/tags/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchLeaderboardParamsDTOToJSON(requestParameters['params']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagsSearchResultDTOFromJSON(jsonValue));
    }

    /**
     * Get tags defined in experiments within project
     */
    async searchLeaderboardTags(requestParameters: SearchLeaderboardTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagsSearchResultDTO> {
        const response = await this.searchLeaderboardTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set default
     */
    async setDefaultViewRaw(requestParameters: SetDefaultViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['setDefaultOperation'] == null) {
            throw new runtime.RequiredError(
                'setDefaultOperation',
                'Required parameter "setDefaultOperation" was null or undefined when calling setDefaultView().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/views/setDefault`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LeaderboardViewSetDefaultDTOToJSON(requestParameters['setDefaultOperation']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set default
     */
    async setDefaultView(requestParameters: SetDefaultViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setDefaultViewRaw(requestParameters, initOverrides);
    }

    /**
     * Signal Experiment
     */
    async signalRunsRaw(requestParameters: SignalRunsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling signalRuns().'
            );
        }

        if (requestParameters['signal'] == null) {
            throw new runtime.RequiredError(
                'signal',
                'Required parameter "signal" was null or undefined when calling signalRuns().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments/signal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignalToJSON(requestParameters['signal']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Signal Experiment
     */
    async signalRuns(requestParameters: SignalRunsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BulkOpResultDTO> {
        const response = await this.signalRunsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Decline column suggestion from the project
     * @deprecated
     */
    async suggestionDeclineRaw(requestParameters: SuggestionDeclineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling suggestionDecline().'
            );
        }

        if (requestParameters['suggestion'] == null) {
            throw new runtime.RequiredError(
                'suggestion',
                'Required parameter "suggestion" was null or undefined when calling suggestionDecline().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['viewId'] != null) {
            queryParameters['viewId'] = requestParameters['viewId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/suggestions/decline`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LeaderboardFieldSuggestionDTOToJSON(requestParameters['suggestion']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Decline column suggestion from the project
     * @deprecated
     */
    async suggestionDecline(requestParameters: SuggestionDeclineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.suggestionDeclineRaw(requestParameters, initOverrides);
    }

    /**
     * Trash experiments
     */
    async trashExperimentsRaw(requestParameters: TrashExperimentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling trashExperiments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments/trash`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['experimentIdentifiers'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Trash experiments
     */
    async trashExperiments(requestParameters: TrashExperimentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BulkOpResultDTO> {
        const response = await this.trashExperimentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unarchive Runs
     */
    async unarchiveRunsRaw(requestParameters: UnarchiveRunsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters['runList'] == null) {
            throw new runtime.RequiredError(
                'runList',
                'Required parameter "runList" was null or undefined when calling unarchiveRuns().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/runs:unarchive`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RunListDTOToJSON(requestParameters['runList']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Unarchive Runs
     */
    async unarchiveRuns(requestParameters: UnarchiveRunsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BulkOpResultDTO> {
        const response = await this.unarchiveRunsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update alias
     */
    async updateAliasRaw(requestParameters: UpdateAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Alias>> {
        if (requestParameters['aliasId'] == null) {
            throw new runtime.RequiredError(
                'aliasId',
                'Required parameter "aliasId" was null or undefined when calling updateAlias().'
            );
        }

        if (requestParameters['aliasToUpdate'] == null) {
            throw new runtime.RequiredError(
                'aliasToUpdate',
                'Required parameter "aliasToUpdate" was null or undefined when calling updateAlias().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/aliases/{aliasId}`.replace(`{${"aliasId"}}`, encodeURIComponent(String(requestParameters['aliasId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AliasParamsToJSON(requestParameters['aliasToUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AliasFromJSON(jsonValue));
    }

    /**
     * Update alias
     */
    async updateAlias(requestParameters: UpdateAliasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Alias> {
        const response = await this.updateAliasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update checkpoint
     */
    async updateCheckpointRaw(requestParameters: UpdateCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckpointDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateCheckpoint().'
            );
        }

        if (requestParameters['checkpointUpdate'] == null) {
            throw new runtime.RequiredError(
                'checkpointUpdate',
                'Required parameter "checkpointUpdate" was null or undefined when calling updateCheckpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/checkpoints/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CheckpointUpdateDTOToJSON(requestParameters['checkpointUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckpointDTOFromJSON(jsonValue));
    }

    /**
     * Update checkpoint
     */
    async updateCheckpoint(requestParameters: UpdateCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckpointDTO> {
        const response = await this.updateCheckpointRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update dashboard
     */
    async updateDashboardRaw(requestParameters: UpdateDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardDTO>> {
        if (requestParameters['dashboardId'] == null) {
            throw new runtime.RequiredError(
                'dashboardId',
                'Required parameter "dashboardId" was null or undefined when calling updateDashboard().'
            );
        }

        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling updateDashboard().'
            );
        }

        if (requestParameters['updateDashboard'] == null) {
            throw new runtime.RequiredError(
                'updateDashboard',
                'Required parameter "updateDashboard" was null or undefined when calling updateDashboard().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards/{dashboardId}`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters['dashboardId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDashboardDTOToJSON(requestParameters['updateDashboard']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDTOFromJSON(jsonValue));
    }

    /**
     * Update dashboard
     */
    async updateDashboard(requestParameters: UpdateDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardDTO> {
        const response = await this.updateDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update dashboard version
     */
    async updateDashboardVersionRaw(requestParameters: UpdateDashboardVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardVersionDTO>> {
        if (requestParameters['dashboardId'] == null) {
            throw new runtime.RequiredError(
                'dashboardId',
                'Required parameter "dashboardId" was null or undefined when calling updateDashboardVersion().'
            );
        }

        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling updateDashboardVersion().'
            );
        }

        if (requestParameters['updateDashboardVersionDTO'] == null) {
            throw new runtime.RequiredError(
                'updateDashboardVersionDTO',
                'Required parameter "updateDashboardVersionDTO" was null or undefined when calling updateDashboardVersion().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['updateTimestamp'] != null) {
            queryParameters['updateTimestamp'] = requestParameters['updateTimestamp'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards/{dashboardId}/version`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters['dashboardId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDashboardVersionDTOToJSON(requestParameters['updateDashboardVersionDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardVersionDTOFromJSON(jsonValue));
    }

    /**
     * Update dashboard version
     */
    async updateDashboardVersion(requestParameters: UpdateDashboardVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardVersionDTO> {
        const response = await this.updateDashboardVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update view
     */
    async updateLeaderboardViewRaw(requestParameters: UpdateLeaderboardViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LeaderboardViewDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling updateLeaderboardView().'
            );
        }

        if (requestParameters['viewId'] == null) {
            throw new runtime.RequiredError(
                'viewId',
                'Required parameter "viewId" was null or undefined when calling updateLeaderboardView().'
            );
        }

        if (requestParameters['updateView'] == null) {
            throw new runtime.RequiredError(
                'updateView',
                'Required parameter "updateView" was null or undefined when calling updateLeaderboardView().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/views/{viewId}`.replace(`{${"viewId"}}`, encodeURIComponent(String(requestParameters['viewId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LeaderboardViewUpdateDTOToJSON(requestParameters['updateView']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LeaderboardViewDTOFromJSON(jsonValue));
    }

    /**
     * Update view
     */
    async updateLeaderboardView(requestParameters: UpdateLeaderboardViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LeaderboardViewDTO> {
        const response = await this.updateLeaderboardViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update notebook
     */
    async updateNotebookRaw(requestParameters: UpdateNotebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotebookDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateNotebook().'
            );
        }

        if (requestParameters['notebookUpdate'] == null) {
            throw new runtime.RequiredError(
                'notebookUpdate',
                'Required parameter "notebookUpdate" was null or undefined when calling updateNotebook().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: NotebookUpdateDTOToJSON(requestParameters['notebookUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotebookDTOFromJSON(jsonValue));
    }

    /**
     * Update notebook
     */
    async updateNotebook(requestParameters: UpdateNotebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotebookDTO> {
        const response = await this.updateNotebookRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update chart
     */
    async updateProjectChartRaw(requestParameters: UpdateProjectChartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectChartDTO>> {
        if (requestParameters['chartId'] == null) {
            throw new runtime.RequiredError(
                'chartId',
                'Required parameter "chartId" was null or undefined when calling updateProjectChart().'
            );
        }

        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling updateProjectChart().'
            );
        }

        if (requestParameters['update'] == null) {
            throw new runtime.RequiredError(
                'update',
                'Required parameter "update" was null or undefined when calling updateProjectChart().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/projectCharts/{projectIdentifier}/{chartId}`.replace(`{${"chartId"}}`, encodeURIComponent(String(requestParameters['chartId']))).replace(`{${"projectIdentifier"}}`, encodeURIComponent(String(requestParameters['projectIdentifier']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectChartUpdateDTOToJSON(requestParameters['update']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectChartDTOFromJSON(jsonValue));
    }

    /**
     * Update chart
     */
    async updateProjectChart(requestParameters: UpdateProjectChartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectChartDTO> {
        const response = await this.updateProjectChartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a draft of a report
     */
    async updateReportDraftRaw(requestParameters: UpdateReportDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportVersionDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling updateReportDraft().'
            );
        }

        if (requestParameters['publishIfUnmodifiedSince'] == null) {
            throw new runtime.RequiredError(
                'publishIfUnmodifiedSince',
                'Required parameter "publishIfUnmodifiedSince" was null or undefined when calling updateReportDraft().'
            );
        }

        if (requestParameters['reportId'] == null) {
            throw new runtime.RequiredError(
                'reportId',
                'Required parameter "reportId" was null or undefined when calling updateReportDraft().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling updateReportDraft().'
            );
        }

        if (requestParameters['reportDraft'] == null) {
            throw new runtime.RequiredError(
                'reportDraft',
                'Required parameter "reportDraft" was null or undefined when calling updateReportDraft().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['publishIfUnmodifiedSince'] != null) {
            queryParameters['publishIfUnmodifiedSince'] = (requestParameters['publishIfUnmodifiedSince'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/reports/{reportId}/versions/{versionId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters['reportId']))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters['versionId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReportVersionDTOToJSON(requestParameters['reportDraft']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportVersionDTOFromJSON(jsonValue));
    }

    /**
     * Update a draft of a report
     */
    async updateReportDraft(requestParameters: UpdateReportDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportVersionDTO> {
        const response = await this.updateReportDraftRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update metadata of a report version
     */
    async updateReportVersionMetadataRaw(requestParameters: UpdateReportVersionMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportVersionMetadataDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling updateReportVersionMetadata().'
            );
        }

        if (requestParameters['publishIfUnmodifiedSince'] == null) {
            throw new runtime.RequiredError(
                'publishIfUnmodifiedSince',
                'Required parameter "publishIfUnmodifiedSince" was null or undefined when calling updateReportVersionMetadata().'
            );
        }

        if (requestParameters['reportId'] == null) {
            throw new runtime.RequiredError(
                'reportId',
                'Required parameter "reportId" was null or undefined when calling updateReportVersionMetadata().'
            );
        }

        if (requestParameters['versionId'] == null) {
            throw new runtime.RequiredError(
                'versionId',
                'Required parameter "versionId" was null or undefined when calling updateReportVersionMetadata().'
            );
        }

        if (requestParameters['metadata'] == null) {
            throw new runtime.RequiredError(
                'metadata',
                'Required parameter "metadata" was null or undefined when calling updateReportVersionMetadata().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['publishIfUnmodifiedSince'] != null) {
            queryParameters['publishIfUnmodifiedSince'] = (requestParameters['publishIfUnmodifiedSince'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/reports/{reportId}/versions/{versionId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters['reportId']))).replace(`{${"versionId"}}`, encodeURIComponent(String(requestParameters['versionId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ReportVersionMetadataDTOToJSON(requestParameters['metadata']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportVersionMetadataDTOFromJSON(jsonValue));
    }

    /**
     * Update metadata of a report version
     */
    async updateReportVersionMetadata(requestParameters: UpdateReportVersionMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportVersionMetadataDTO> {
        const response = await this.updateReportVersionMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update tags in experiments
     */
    async updateTags2Raw(requestParameters: UpdateTags2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling updateTags2().'
            );
        }

        if (requestParameters['updateTagsParams'] == null) {
            throw new runtime.RequiredError(
                'updateTagsParams',
                'Required parameter "updateTagsParams" was null or undefined when calling updateTags2().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments/tags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTagsParamsToJSON(requestParameters['updateTagsParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Update tags in experiments
     */
    async updateTags2(requestParameters: UpdateTags2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BulkOpResultDTO> {
        const response = await this.updateTags2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload file attribute
     */
    async uploadAttributeRaw(requestParameters: UploadAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalOperationError>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling uploadAttribute().'
            );
        }

        if (requestParameters['ext'] == null) {
            throw new runtime.RequiredError(
                'ext',
                'Required parameter "ext" was null or undefined when calling uploadAttribute().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['ext'] != null) {
            queryParameters['ext'] = requestParameters['ext'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionalOperationErrorFromJSON(jsonValue));
    }

    /**
     * Upload file attribute
     */
    async uploadAttribute(requestParameters: UploadAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalOperationError> {
        const response = await this.uploadAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload checkpoint content
     */
    async uploadCheckpointContentRaw(requestParameters: UploadCheckpointContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uploadCheckpointContent().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/checkpoints/{id}/content`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Upload checkpoint content
     */
    async uploadCheckpointContent(requestParameters: UploadCheckpointContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uploadCheckpointContentRaw(requestParameters, initOverrides);
    }

    /**
     * Upload fileset attribute\'s file\'s chunk
     */
    async uploadFileSetAttributeChunkRaw(requestParameters: UploadFileSetAttributeChunkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalOperationError>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling uploadFileSetAttributeChunk().'
            );
        }

        if (requestParameters['path'] == null) {
            throw new runtime.RequiredError(
                'path',
                'Required parameter "path" was null or undefined when calling uploadFileSetAttributeChunk().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['path'] != null) {
            queryParameters['path'] = requestParameters['path'];
        }

        if (requestParameters['reset'] != null) {
            queryParameters['reset'] = requestParameters['reset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/uploadFileSetChunk`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionalOperationErrorFromJSON(jsonValue));
    }

    /**
     * Upload fileset attribute\'s file\'s chunk
     */
    async uploadFileSetAttributeChunk(requestParameters: UploadFileSetAttributeChunkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalOperationError> {
        const response = await this.uploadFileSetAttributeChunkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload fileset attribute\'s file\'s tar stream
     */
    async uploadFileSetAttributeTarRaw(requestParameters: UploadFileSetAttributeTarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalOperationError>> {
        if (requestParameters['attribute'] == null) {
            throw new runtime.RequiredError(
                'attribute',
                'Required parameter "attribute" was null or undefined when calling uploadFileSetAttributeTar().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['attribute'] != null) {
            queryParameters['attribute'] = requestParameters['attribute'];
        }

        if (requestParameters['experimentId'] != null) {
            queryParameters['experimentId'] = requestParameters['experimentId'];
        }

        if (requestParameters['reset'] != null) {
            queryParameters['reset'] = requestParameters['reset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/uploadFileSetTar`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionalOperationErrorFromJSON(jsonValue));
    }

    /**
     * Upload fileset attribute\'s file\'s tar stream
     */
    async uploadFileSetAttributeTar(requestParameters: UploadFileSetAttributeTarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalOperationError> {
        const response = await this.uploadFileSetAttributeTarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate experiments clipboard
     */
    async validateExperimentsClipboardRaw(requestParameters: ValidateExperimentsClipboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling validateExperimentsClipboard().'
            );
        }

        if (requestParameters['trashed'] == null) {
            throw new runtime.RequiredError(
                'trashed',
                'Required parameter "trashed" was null or undefined when calling validateExperimentsClipboard().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['trashed'] != null) {
            queryParameters['trashed'] = requestParameters['trashed'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments/select`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['experimentIdentifiers'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Validate experiments clipboard
     */
    async validateExperimentsClipboard(requestParameters: ValidateExperimentsClipboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BulkOpResultDTO> {
        const response = await this.validateExperimentsClipboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const DownloadAttributeExpectedContentDispositionEnum = {
    Inline: 'inline',
    Attachment: 'attachment'
} as const;
export type DownloadAttributeExpectedContentDispositionEnum = typeof DownloadAttributeExpectedContentDispositionEnum[keyof typeof DownloadAttributeExpectedContentDispositionEnum];
/**
 * @export
 */
export const GetCheckpointContentExpectedContentDispositionEnum = {
    Inline: 'inline',
    Attachment: 'attachment'
} as const;
export type GetCheckpointContentExpectedContentDispositionEnum = typeof GetCheckpointContentExpectedContentDispositionEnum[keyof typeof GetCheckpointContentExpectedContentDispositionEnum];
/**
 * @export
 */
export const GetFloatSeriesValuesLineageEnum = {
    Full: 'FULL',
    None: 'NONE'
} as const;
export type GetFloatSeriesValuesLineageEnum = typeof GetFloatSeriesValuesLineageEnum[keyof typeof GetFloatSeriesValuesLineageEnum];
/**
 * @export
 */
export const GetFloatSeriesValuesCSVExpectedContentDispositionEnum = {
    Inline: 'inline',
    Attachment: 'attachment'
} as const;
export type GetFloatSeriesValuesCSVExpectedContentDispositionEnum = typeof GetFloatSeriesValuesCSVExpectedContentDispositionEnum[keyof typeof GetFloatSeriesValuesCSVExpectedContentDispositionEnum];
/**
 * @export
 */
export const GetFloatSeriesValuesProtoLineageEnum = {
    Full: 'FULL',
    None: 'NONE'
} as const;
export type GetFloatSeriesValuesProtoLineageEnum = typeof GetFloatSeriesValuesProtoLineageEnum[keyof typeof GetFloatSeriesValuesProtoLineageEnum];
/**
 * @export
 */
export const GetImageSeriesValuesZipExpectedContentDispositionEnum = {
    Inline: 'inline',
    Attachment: 'attachment'
} as const;
export type GetImageSeriesValuesZipExpectedContentDispositionEnum = typeof GetImageSeriesValuesZipExpectedContentDispositionEnum[keyof typeof GetImageSeriesValuesZipExpectedContentDispositionEnum];
/**
 * @export
 */
export const GetStringSeriesValuesCSVExpectedContentDispositionEnum = {
    Inline: 'inline',
    Attachment: 'attachment'
} as const;
export type GetStringSeriesValuesCSVExpectedContentDispositionEnum = typeof GetStringSeriesValuesCSVExpectedContentDispositionEnum[keyof typeof GetStringSeriesValuesCSVExpectedContentDispositionEnum];
/**
 * @export
 */
export const ListDashboardBranchVersionsDraftsEnum = {
    False: 'false',
    True: 'true',
    All: 'all'
} as const;
export type ListDashboardBranchVersionsDraftsEnum = typeof ListDashboardBranchVersionsDraftsEnum[keyof typeof ListDashboardBranchVersionsDraftsEnum];
/**
 * @export
 */
export const ListDashboardsDashboardTypeEnum = {
    Compare: 'compare',
    Experiment: 'experiment',
    Report: 'report'
} as const;
export type ListDashboardsDashboardTypeEnum = typeof ListDashboardsDashboardTypeEnum[keyof typeof ListDashboardsDashboardTypeEnum];
/**
 * @export
 */
export const ListDashboardsDraftsEnum = {
    False: 'false',
    True: 'true',
    All: 'all'
} as const;
export type ListDashboardsDraftsEnum = typeof ListDashboardsDraftsEnum[keyof typeof ListDashboardsDraftsEnum];
/**
 * @export
 */
export const ListNotebooksSortByEnum = {
    Name: 'name',
    Owner: 'owner',
    UpdateTime: 'updateTime'
} as const;
export type ListNotebooksSortByEnum = typeof ListNotebooksSortByEnum[keyof typeof ListNotebooksSortByEnum];
/**
 * @export
 */
export const ListNotebooksSortDirectionEnum = {
    Ascending: 'ascending',
    Descending: 'descending'
} as const;
export type ListNotebooksSortDirectionEnum = typeof ListNotebooksSortDirectionEnum[keyof typeof ListNotebooksSortDirectionEnum];
/**
 * @export
 */
export const SearchLeaderboardTagsAttributePathEnum = {
    Tags: 'sys/tags',
    GroupTags: 'sys/group_tags'
} as const;
export type SearchLeaderboardTagsAttributePathEnum = typeof SearchLeaderboardTagsAttributePathEnum[keyof typeof SearchLeaderboardTagsAttributePathEnum];

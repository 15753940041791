import { CustomIconDefinition } from '../types';

export const nepPlayCircle: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'play-circle',
  icon: [
    20,
    20,
    [],
    'e00c',
    'M2.6665 10C2.6665 5.94993 5.94975 2.66669 9.99984 2.66669C14.0499 2.66669 17.3332 5.94993 17.3332 10C17.3332 14.0501 14.0499 17.3334 9.99984 17.3334C5.94975 17.3334 2.6665 14.0501 2.6665 10ZM9.99984 0.666687C4.84518 0.666687 0.666504 4.84536 0.666504 10C0.666504 15.1547 4.84518 19.3334 9.99984 19.3334C15.1545 19.3334 19.3332 15.1547 19.3332 10C19.3332 4.84536 15.1545 0.666687 9.99984 0.666687ZM8.8882 5.83458C8.58134 5.63 8.1868 5.61093 7.86164 5.78495C7.53648 5.95897 7.3335 6.29783 7.3335 6.66663V13.3333C7.3335 13.7021 7.53648 14.0409 7.86164 14.215C8.1868 14.389 8.58134 14.3699 8.8882 14.1653L13.8882 10.832C14.1664 10.6465 14.3335 10.3343 14.3335 9.99996C14.3335 9.66561 14.1664 9.35337 13.8882 9.16791L8.8882 5.83458ZM11.5307 9.99996L9.3335 11.4648V8.53514L11.5307 9.99996Z',
  ],
};

/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NameAliasDTO
 */
export interface NameAliasDTO {
    /**
     * Creation timestamp
     * @type {Date}
     * @memberof NameAliasDTO
     */
    createdAt: Date;
    /**
     * Name alias
     * @type {string}
     * @memberof NameAliasDTO
     */
    nameAlias: string;
    /**
     * Original identifier (run ID or experiment name)
     * @type {string}
     * @memberof NameAliasDTO
     */
    originalId: string;
}

/**
 * Check if a given object implements the NameAliasDTO interface.
 */
export function instanceOfNameAliasDTO(value: object): value is NameAliasDTO {
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('nameAlias' in value) || value['nameAlias'] === undefined) return false;
    if (!('originalId' in value) || value['originalId'] === undefined) return false;
    return true;
}

export function NameAliasDTOFromJSON(json: any): NameAliasDTO {
    return NameAliasDTOFromJSONTyped(json, false);
}

export function NameAliasDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameAliasDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'nameAlias': json['nameAlias'],
        'originalId': json['originalId'],
    };
}

export function NameAliasDTOToJSON(value?: NameAliasDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'createdAt': ((value['createdAt']).toISOString()),
        'nameAlias': value['nameAlias'],
        'originalId': value['originalId'],
    };
}


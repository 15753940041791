import React from 'react';

import { DropdownToggleInterface } from '@neptune/shared/venus-domain';
import { bemBlock, Button, Dropdown } from '@neptune/shared/venus-ui';

import { ColorPicker } from './ColorPicker';

import './ColorPickerDropdown.less';

const block = bemBlock('color-picker-dropdown');

type ColorPickerDropdownBaseProps = {
  label?: string;
  color?: string;
  className?: string;
  presetColors?: string[];
  toggle?: React.ReactElement<DropdownToggleInterface> | string;
  dataRole?: string;
  onAddPresetColor?: (color: string) => void;
};

type ColorPickerDropdownClearableProps = ColorPickerDropdownBaseProps & {
  clearable: true;
  onChange?: (color: string | undefined) => void;
};

type ColorPickerDropdownStandardProps = ColorPickerDropdownBaseProps & {
  clearable?: false;
  onChange?: (color: string) => void;
};

type ColorPickerDropdownProps =
  | ColorPickerDropdownStandardProps
  | ColorPickerDropdownClearableProps;

export function ColorPickerDropdown(props: ColorPickerDropdownStandardProps): JSX.Element;
export function ColorPickerDropdown(props: ColorPickerDropdownClearableProps): JSX.Element;

export function ColorPickerDropdown({
  className,
  dataRole,
  color,
  label = 'Change color',
  toggle,
  presetColors,
  onChange,
  clearable,
  onAddPresetColor,
}: ColorPickerDropdownProps) {
  const originalColorRef = React.useRef(color);
  const currentColorRef = React.useRef(color);

  const handleExpand = React.useCallback(() => {
    originalColorRef.current = color;
  }, [color]);

  const handleCollapse = React.useCallback(() => {
    if (currentColorRef.current && currentColorRef.current !== originalColorRef.current) {
      onAddPresetColor?.(currentColorRef.current);
    }
  }, [onAddPresetColor]);

  const restoreColor = React.useCallback(() => {
    if (clearable) {
      onChange?.(originalColorRef.current);
    } else {
      if (!originalColorRef.current) {
        return;
      }

      currentColorRef.current = originalColorRef.current;

      onChange?.(originalColorRef.current);
    }
  }, [clearable, onChange]);

  const handleOnChange = React.useCallback(
    (newColor: string) => {
      currentColorRef.current = newColor;
      onChange?.(newColor);
    },
    [onChange],
  );

  const renderToggle = () => {
    if (toggle) {
      return toggle;
    }

    return (
      <Dropdown.SubmenuToggle
        icon="color"
        label={label}
        disableChevron
        component="label"
        data-role={dataRole}
      />
    );
  };

  return (
    <Dropdown
      toggle={renderToggle()}
      onExpand={handleExpand}
      onCollapse={handleCollapse}
      attachment="top left"
      targetAttachment="top right"
      constraints={Dropdown.CONSTRAINT_TO_WINDOW}
    >
      {({ collapse }) => (
        <Dropdown.Menu withPadding="none">
          <ColorPicker
            className={className}
            defaultValue={color}
            onChange={handleOnChange}
            onCancel={restoreColor}
            presetColors={presetColors}
          />
          {clearable && onChange && (
            <>
              <Dropdown.Separator />
              <Button
                className={block('reset-button')}
                variant="transparent"
                onClick={() => {
                  onChange(undefined);
                  collapse();
                }}
                children="Reset to defaults"
              />
            </>
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
}

// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { Layout } from '../layout/Layout';

// Module
import './ButtonGroup.less';

const block = bemBlock('n-ButtonGroup');

export const ButtonGroup: React.FC<React.ComponentProps<typeof Layout.Row>> = ({
  className,
  ...restProps
}) => {
  return <Layout.Row className={block({ extra: className })} {...restProps} />;
};

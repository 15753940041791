import React, { FocusEvent } from 'react';

import { Textarea } from './Textarea';

type InlineTextAreaProps = Omit<
  React.ComponentProps<typeof Textarea>,
  'value' | 'onChange' | 'onSubmit'
> & {
  value: string;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
};

export const InlineTextArea = React.forwardRef<HTMLTextAreaElement, InlineTextAreaProps>(
  ({ value, onChange, onFocus, onBlur, onKeyDown, onSubmit, ...restProps }, ref) => {
    const skipChangeEvent = React.useRef(false);
    const [newValue, setNewValue] = React.useState(value);

    const handleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        setNewValue(newValue);
        onChange?.(newValue);
      },
      [onChange],
    );

    const handleBlur = React.useCallback(
      (e: FocusEvent<HTMLTextAreaElement>) => {
        if (skipChangeEvent.current) {
          setNewValue(value);
        } else {
          onSubmit?.(newValue);
        }

        onBlur?.(e);
      },
      [newValue, onBlur, onSubmit, value],
    );

    const handleFocus = React.useCallback(
      (e: FocusEvent<HTMLTextAreaElement>) => {
        skipChangeEvent.current = false;
        onFocus?.(e);
      },
      [onFocus],
    );

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const target = event.currentTarget;

        if (event.key === 'Escape') {
          skipChangeEvent.current = true;
          target.blur();
        }

        if (event.key === 'Enter') {
          skipChangeEvent.current = false;
          target.blur();
        }

        onKeyDown?.(event);
      },
      [onKeyDown],
    );

    return (
      <Textarea
        {...restProps}
        ref={ref}
        value={newValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    );
  },
);
